import { createAsyncThunk } from '@reduxjs/toolkit';
import { round } from 'lodash';

import { BasketRateAttributes, IPrice, IStore2, PaymentSource, PayoutMethods } from 'types';
import { PayoutsApi } from './PayoutsApi';

const api = new PayoutsApi();

export const getStripePayoutDetails = createAsyncThunk(
  'async/getStripePaymentSessionDetails',
  async (
    input: { orderId: string; provider: string; couponCode: string | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId, provider, couponCode } = input;

    return api.getStripePayoutDetails(provider, orderId, token, couponCode);
  },
);

export const getVisionStripePayoutDetails = createAsyncThunk(
  'async/getVisionStripePayoutDetails',
  async (input: { shippingRateId: string; offerId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shippingRateId, offerId } = input;

    return api.getVisionStripePayoutDetails(shippingRateId, offerId, token);
  },
);

export const getBasketCheckoutStripeDetails = createAsyncThunk(
  'async/getBasketCheckoutStripeDetails',
  async (
    input: { checkoutId: string; shippingRateId: string | null; couponCode: string | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId, shippingRateId, couponCode } = input;

    return api.getBasketStripeCheckoutStripePayoutDetails(
      checkoutId,
      shippingRateId,
      couponCode,
      token,
    );
  },
);

export const getPaypalPayoutDetails = createAsyncThunk(
  'async/getPaypalPaymentSessionDetails',
  async (
    input: { orderId: string; provider: string; couponCode: string | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId, provider, couponCode } = input;

    return api.getPaypalPayoutDetails(provider, orderId, token, couponCode);
  },
);

export const getBasketPaypalPayoutLink = createAsyncThunk(
  'async/getBasketPaypalPayoutLink',
  async (
    input: { checkoutId: string; shippingRateId: string | null; couponCode: string | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId, shippingRateId, couponCode } = input;

    return api.getBasketPaypalPayoutLink(checkoutId, shippingRateId, couponCode, token);
  },
);

export const getVisionPaypalPayoutLink = createAsyncThunk(
  'async/getVisionPaypalPayoutLink',
  async (input: { shippingRateId: string; offerId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { offerId, shippingRateId } = input;

    return api.getVisionPaypalPayoutLink(offerId, shippingRateId, token);
  },
);

export const getBasketCheckoutDetails = createAsyncThunk(
  'async/getBasketCheckoutDetails',
  async (input: { checkoutId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId } = input;

    return api.getBasketCheckoutPayoutMethods(token, checkoutId);
  },
);

export const checkCouponForItem = createAsyncThunk(
  'async/checkCouponForItem',
  async (input: { couponCode: string; cartPrice: number; shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const modifiedCartPrice = input.cartPrice * 100;

    return api.checkCoupon(token, input.couponCode, input.shopId, modifiedCartPrice);
  },
);

export const checkCouponForCart = createAsyncThunk(
  'async/checkCouponForCart',
  async (input: { couponCode: string; cartPrice: number; shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const modifiedCartPrice = round(input.cartPrice * 100);

    return api.checkCoupon(token, input.couponCode, input.shopId, modifiedCartPrice);
  },
);

export const getBasketPaymentsDetails = createAsyncThunk(
  'async/getBasketPaymentsDetails',
  async (input: { checkoutId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { checkoutId } = input;

    return api.getBasketCheckoutPaymentsDetails(token, checkoutId);
  },
);

export const getStripePaymentConfirmation = createAsyncThunk(
  'async/getStripePaymentConfirmation',
  async (
    _input: {
      avatar: string;
      username: string;
      hasDigitalFiles: boolean;
      source: PaymentSource;
      id: string;
      price: IPrice;
      selectedRateAttribute: BasketRateAttributes;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const paymentId = state.payouts.stripePaymentSessionDetails?.id || '';

    return api.getStripePaymentConfirmation(paymentId, token);
  },
);

export const getStripeVisionPaymentConfirmation = createAsyncThunk(
  'async/getStripeVisionPaymentConfirmation',
  async (
    _input: { offerId: string; price: IPrice; shippingRateAttribute: BasketRateAttributes | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const paymentId = state.payouts.stripePaymentSessionDetails?.id || '';

    return api.getStripePaymentConfirmation(paymentId, token);
  },
);

export const getPaypalPaymentConfirmation = createAsyncThunk(
  'async/getPaypalPaymentConfirmation',
  async (input: { transactionId: string }) => {
    const { transactionId } = input;

    return api.getPaypalPaymentConfirmation(transactionId);
  },
);

export const linkPayout = createAsyncThunk(
  'async/linkPayout',
  async (input: { payout: PayoutMethods }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { payout } = input;

    if (payout === PayoutMethods.Stripe) {
      return api.onStripeLink(token);
    }

    return api.onPaypalLink(token);
  },
);

export const refreshPaypal = createAsyncThunk(
  'async/refreshPaypal',
  async (
    input: { merchantId: string; merchantIdInPayPal: string; id: string },
    { rejectWithValue }: any,
  ) => {
    const { merchantId, merchantIdInPayPal, id } = input;

    try {
      return await api.refreshPaypal(merchantId, merchantIdInPayPal, id);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deactivatePayout = createAsyncThunk(
  'async/deactivatePayout',
  async (input: { payout: PayoutMethods }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { payout } = input;

    if (payout === PayoutMethods.Stripe) {
      return api.deactivateStripe(token);
    }

    return api.deactivatePaypal(token);
  },
);
