import { IProductsInSearchResults } from 'types';
import { PaginatedItemsResult } from 'graphql/generated/graphql';

export interface IProductsState {
  list: IProductsInSearchResults[] | null;
  offset: number;
  totalCount: number;
  paginationToken: string;
  productIndexToScroll: number;
  isLoading: boolean;
}

export interface IProductsApi {
  getAllProducts(
    listId: DiscoverListsId,
    paginationToken?: string,
    token?: string,
  ): Promise<PaginatedItemsResult>;
}

export enum LikeProductSource {
  Discover = 'discover',
  SearchResults = 'search result',
  Products = 'products',
  Shop = 'shop',
  Basket = 'basket',
}

export enum DiscoverListsId {
  EditorsPick = 'EDITORS_PICK',
  Favorites = 'RECENTLY_SAVED',
  ShopFavorites = 'SHOP_SAVED_PRODUCTS',
  MainFeed = 'MAIN_FEED',
  UserFeed = 'USER_FEED',
  MyVisions = 'MY_VISIONS',
}
