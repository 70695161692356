import { FC } from 'react';
import classNames from 'classnames';

interface IButton {
  className?: string;
  text: string;

  onClick(e: any): void;

  disabled?: boolean;
}

export const Button: FC<IButton> = ({ className, text, onClick, disabled }) => (
  <input
    type="button"
    disabled={disabled}
    className={classNames('button', [className])}
    value={text}
    onClick={onClick}
  />
);
