export interface IConfigState {
  tips: ITip[];
  introPopupKey: IntroPopupKeys | null;
  shouldDisplayVisionsBuyerRequests: boolean;
  featureFlags: FeatureFlags[];
  introPopupDetails: {
    savedItemsImages?: string[];
    visionRequestOffersDetails?: IVisionRequestOffersDetails;
  };
}

export interface IAppConfigResponse {
  tips: ITipResponse[];
  shouldDisplayBuyerRequests?: boolean;
  showDrawer?: IntroPopupKeys;
  didLinkShippo?: boolean;
  popupDetails?: IPopupDetailsResponse;
  featureFlags?: FeatureFlags[];
}

export interface ITip {
  type: TipType;
  displayedRoutes: TipScreenTypes[];
  items: ITipDisplayItem[];
  link: string | null;
}

export interface ITag {
  text: string;
  variant: TagVariants;
}

export interface IPopupDetailsResponse {
  key: IntroPopupKeys;
  details?: {
    savedItemsImages?: string[];
    IVisionRequestOffersDetails?: IVisionRequestOffersDetails;
  };
}

export interface IVisionRequestOffersDetails {
  visionImage: string;
  totalCount: number;
  requestId: string;
}

export interface ITipResponse {
  type: TipType;
  screens: TipScreenTypes[];
  items: ITipDisplayItemResponse[];
  key: string;
  button?: ITipButtonResponse;
  link?: string;
}

export enum TipType {
  Neutral,
  Error,
  Warning,
  Info,
  Success,
  Purple,
}

export enum TipScreenTypes {
  Feed = 'feed',
  Shop = 'me',
  Orders = 'offers',
  Profile = 'me',
  Product = 'product',
  SearchResult = 'searchResult',
}

export interface ITipDisplayItemResponse {
  textItem?: ITipTextItemResponse;
  imageItem?: ITipImageItemResponse;
}

export interface ITipButtonResponse {
  link: string;
  text: string;
  variant: number;
}

export interface ITipDisplayItem {
  textItem?: ITipTextItem;
  imageItem?: ITipImageItem;
}

export interface ITipTextItemResponse {
  text: string;
  textColor?: string;
  backgroundColor?: string;
  font?: string;
  underline?: boolean;
  strikethrough?: boolean;
  link?: string;
}

export interface ITipTextItem {
  text: string;
  font: string;
  underline: boolean;
  strikethrough: boolean;
  link: string | null;
}

export interface ITipImageItem {
  name: string;
  width: number;
  height: number;
  link: string | null;
}

export interface ITipImageItemResponse {
  name: string;
  width: number;
  height: number;
  color: string;
  link?: string;
}

export enum FontResponses {
  Body14 = 'body14',
  Body16 = 'body16',
  ButtonMedium = 'buttonMedium',
  ButtonSmall = 'buttonSmall',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading5 = 'heading5',
  Subheading = 'subheading',
  SubHeadingLarge = 'subheading14',
}

export enum IntroPopupKeys {
  Payment = 'payment',
  Shipping = 'shipping',
  Favorites = 'favorites',
  Visions = 'visions',
  VisionOffers = 'visionRequestNewOffers',
  ImportCSV = 'uploadCsv',
}

export enum TagVariants {
  Information = 'INFO',
  Warning = 'WARNING',
  Danger = 'DANGER',
  Purple = 'PURPLE',
  Success = 'SUCCESS',
}

export enum FeatureFlags {
  Visions = 'VISIONS',
  PremiumUi = 'PREMIUM_UI_V1',
}
