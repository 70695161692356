import { lazy } from 'react';

import { PopupDeepLinkOptions, popupIdsOptions } from 'types';

export const mapPopupDeepLinkToId: { [key in PopupDeepLinkOptions]: popupIdsOptions } = {
  [PopupDeepLinkOptions.VacationMode]: popupIdsOptions.vacationModePopup,
  [PopupDeepLinkOptions.BuyVisionsCredits]: popupIdsOptions.buyVisionCreditsPopup,
  [PopupDeepLinkOptions.Premium]: popupIdsOptions.upgradeToProPopup,
};

export const mapIdToPopup: { [key in popupIdsOptions]: any } = {
  [popupIdsOptions.createPostPopup]: lazy(
    () => import('pages/feed/createPost/createPostPopup/CreatePostPopup'),
  ),
  [popupIdsOptions.uploadCsvPopup]: lazy(
    () => import('pages/createShop/popups/importCsvPopup/ImportCsvPopupVariantWrapper'),
  ),
  [popupIdsOptions.linksNotAllowedPopup]: lazy(
    () => import('pages/chatv2/popups/linksNotAllowedPopup/LinksNotAllowedPopup'),
  ),
  [popupIdsOptions.feedReactionsPopup]: lazy(
    () => import('pages/feed/popups/reactionPopup/ReactionPopupWrapper'),
  ),
  [popupIdsOptions.mojoReactionsPopup]: lazy(
    () => import('pages/feed/popups/reactionPopup/MojoReactionPopupWrapper'),
  ),
  [popupIdsOptions.reportUserPopup]: lazy(
    () => import('pages/chatv2/popups/reportUserPopup/ReportUserPopup'),
  ),
  [popupIdsOptions.signInPopup]: lazy(() => import('components/signInPopup/SignInPopup')),
  [popupIdsOptions.reportUserConfirmationPopup]: lazy(
    () => import('pages/chatv2/popups/reportConfirmPopup/ReportConfirmPopup'),
  ),
  [popupIdsOptions.blockUser]: lazy(
    () => import('pages/chatv2/popups/blockProfile/BlockProfilePopup'),
  ),
  [popupIdsOptions.deleteCommentPopup]: lazy(
    () => import('pages/specificPost/popups/DeleteCommentPopupWrapper'),
  ),
  [popupIdsOptions.mojoDeleteCommentPopup]: lazy(
    () => import('pages/feed/popups/deleteCommentPopup/DeleteCommentMojoPopupWrapper'),
  ),
  [popupIdsOptions.deletePostPopup]: lazy(
    () => import('pages/feed/popups/deletePostPopup/DeletePostPopupWrapper'),
  ),
  [popupIdsOptions.deleteMojoPopup]: lazy(
    () => import('pages/feed/popups/deletePostPopup/DeleteMojoPopupWrapper'),
  ),
  [popupIdsOptions.editProfilePopup]: lazy(
    () => import('pages/profilePage/popups/editProfilePopup/EditProfilePopup'),
  ),
  [popupIdsOptions.profileViewsPopup]: lazy(
    () => import('pages/profilePage/popups/profileViewsPopup/ProfileViewsPopup'),
  ),
  [popupIdsOptions.postImagesPopup]: lazy(
    () => import('components/post/imagePopup/SpecificPostImagePopup'),
  ),
  [popupIdsOptions.feedImagesPopup]: lazy(
    () => import('components/post/imagePopup/FeedImagePopup'),
  ),
  [popupIdsOptions.feedDeleteCommentPopup]: lazy(
    () => import('pages/feed/popups/deleteCommentPopup/DeleteCommentPopupWrapper'),
  ),
  [popupIdsOptions.postReactionsPopup]: lazy(
    () => import('pages/specificPost/popups/ReactionPopupWrapper'),
  ),

  [popupIdsOptions.blockedUsersPopup]: lazy(
    () => import('pages/settings/popups/blockedPopup/BlockedPopup'),
  ),
  [popupIdsOptions.changePasswordPopup]: lazy(
    () => import('pages/settings/popups/passworrdPopup/PasswordPopup'),
  ),
  [popupIdsOptions.forgetPasswordSettingsPopup]: lazy(
    () => import('pages/settings/popups/forgotPassword/ForgetPassword'),
  ),
  [popupIdsOptions.editFullNamePopup]: lazy(
    () => import('pages/settings/popups/editFullNamePopup/EditFullNamePopup'),
  ),
  [popupIdsOptions.editUsernamePopup]: lazy(
    () => import('pages/settings/popups/editUsernamePopup/EditUsernamePopup'),
  ),
  [popupIdsOptions.editEmailPopup]: lazy(
    () => import('pages/settings/popups/editEmailPopup/EditEmailPopup'),
  ),
  [popupIdsOptions.editBirthdayPopup]: lazy(
    () => import('pages/settings/popups/editBirthdayPopup/EditBirthdayPopup'),
  ),
  [popupIdsOptions.shippingAddressesPopup]: lazy(
    () => import('pages/settings/popups/shippingAddressesPopup/ShippingAddressesPopup'),
  ),
  [popupIdsOptions.loggedInViaThirdPartyPopup]: lazy(
    () => import('pages/settings/popups/loggedInPopup/LoggedInPopup'),
  ),
  [popupIdsOptions.getTheAppPopup]: lazy(
    () => import('pages/getTheApp/getTheAppPopup/GetTheAppPopup'),
  ),
  [popupIdsOptions.createShopPopup]: lazy(
    () => import('pages/createShop/createShopPopup/CreateShopPopup'),
  ),
  [popupIdsOptions.listingKeywordPopup]: lazy(
    () => import('pages/createShop/popups/listingKeywordsPopup/ListingKeywordPopup'),
  ),
  [popupIdsOptions.editShopExitPopup]: lazy(
    () => import('pages/createShop/exitPopup/ExitEditShopPopup'),
  ),
  [popupIdsOptions.createShopExitPopup]: lazy(
    () => import('pages/createShop/exitPopup/ExitCreateShopPopup'),
  ),
  [popupIdsOptions.addListingPopupCreate]: lazy(
    () => import('pages/createShop/addListingPopup/AddListingCreateShopPopupWrapper'),
  ),
  [popupIdsOptions.addListingPopupShop]: lazy(
    () => import('pages/createShop/addListingPopup/AddListingShopPopupWrapper'),
  ),
  [popupIdsOptions.addListingChooseDuplicatePopupCreate]: lazy(
    () => import('pages/createShop/chooseDuplicatePopup/ChooseDuplicateCreateShopPopupWrapper'),
  ),
  [popupIdsOptions.addListingChooseDuplicatePopupShop]: lazy(
    () => import('pages/createShop/chooseDuplicatePopup/ChooseDuplicateShopPopupWrapper'),
  ),
  [popupIdsOptions.deleteDraftPopup]: lazy(
    () => import('pages/createShop/exitPopup/deleteDraftPopup/DeleteDraftPopup'),
  ),
  [popupIdsOptions.followersPopup]: lazy(
    () => import('pages/shopPage/popups/followersPopup/FollowersPopup'),
  ),
  [popupIdsOptions.searchFiltersPopup]: lazy(
    () => import('pages/searchPage/searchFilterPopup/SearchFilterPopup'),
  ),
  [popupIdsOptions.reportPostPopup]: lazy(
    () => import('pages/feed/popups/reportPostPopup/ReportPostPopupWrapper'),
  ),
  [popupIdsOptions.reportMojoPopup]: lazy(
    () => import('pages/feed/popups/reportPostPopup/ReportMojoPopupWrapper'),
  ),
  [popupIdsOptions.sharePostPopup]: lazy(
    () => import('pages/feed/popups/sharePostPopup/SharePostPopupWrapper'),
  ),
  [popupIdsOptions.shareMojoPopup]: lazy(
    () => import('pages/feed/popups/sharePostPopup/ShareMojoPopupWrapper'),
  ),
  [popupIdsOptions.paymentMethodHelperPopup]: lazy(
    () => import('pages/createShop/popups/paymentHelperPopup/PaymentHelperPopup'),
  ),
  [popupIdsOptions.legacyPaymentMethodHelperPopup]: lazy(
    () =>
      import(
        'pages/createShop/stepForms/legacyPaymentMethodsForm/legacyPaymentHelperPopup/LegacyPaymentHelperPopup'
      ),
  ),
  [popupIdsOptions.howToGetMyLinkPopup]: lazy(
    () =>
      import(
        'pages/createShop/stepForms/legacyPaymentMethodsForm/howToGetMyLinkPopup/HowToGetMyLinkPopup'
      ),
  ),
  [popupIdsOptions.deleteItemPopup]: lazy(
    () => import('pages/specificProductPage/popups/deleteItemPopup/DeleteItemPopup'),
  ),
  [popupIdsOptions.shareItemPopup]: lazy(
    () => import('pages/specificProductPage/popups/shareItemPopup/ShareItemPopup'),
  ),
  [popupIdsOptions.chatImagePreviewPopup]: lazy(
    () => import('pages/chatv2/messages/imagePreviewPopup/ImagePreviewPopup'),
  ),
  [popupIdsOptions.deleteMessagePopup]: lazy(
    () => import('pages/chatv2/messages/imagePreviewPopup/deleteMessagePopup/DeleteMessagePopup'),
  ),
  [popupIdsOptions.forwardMessagesPopup]: lazy(
    () => import('pages/chatv2/messages/forwardMessagesPopup/ForwardMessagesPopup'),
  ),
  [popupIdsOptions.muteChatPopup]: lazy(
    () => import('pages/chatv2/popups/muteChatPopup/MuteChatPopup'),
  ),
  [popupIdsOptions.leaveChatPopup]: lazy(
    () => import('pages/chatv2/popups/leaveChatPopup/LeaveChatPopup'),
  ),
  [popupIdsOptions.shareGroupPopup]: lazy(
    () => import('pages/chatv2/popups/shareGroupPopup/ShareGroupPopup'),
  ),
  [popupIdsOptions.sharePostInChatPopup]: lazy(
    () => import('pages/feed/popups/sharePostInChat/SharePostInChatPopup'),
  ),
  [popupIdsOptions.editNotificationsPopup]: lazy(
    () => import('pages/settings/popups/notificationsPopup/NotificationsPopup'),
  ),
  [popupIdsOptions.deleteAccountPopup]: lazy(
    () => import('pages/settings/popups/deleteAccountPopup/DeleteAccountPopup'),
  ),
  [popupIdsOptions.feedRepostOptionsPopup]: lazy(
    () => import('pages/feed/popups/RepostOptionsPopupWrapper'),
  ),
  [popupIdsOptions.repostOptionsPopup]: lazy(
    () => import('pages/specificPost/popups/RepostOptionsPopupWrapper'),
  ),
  [popupIdsOptions.feedRepostsPopup]: lazy(() => import('pages/feed/popups/RepostsPopupWrapper')),
  [popupIdsOptions.repostsPopup]: lazy(
    () => import('pages/specificPost/popups/RepostsPopupWrapper'),
  ),
  [popupIdsOptions.editCurrencyPopup]: lazy(
    () => import('pages/settings/popups/currencyPopup/CurrencyPopup'),
  ),
  [popupIdsOptions.vacationModePopup]: lazy(
    () => import('pages/settings/popups/vacationModePopup/VacationModePopup'),
  ),
  [popupIdsOptions.viewTotalActiveOrdersPopup]: lazy(
    () =>
      import('pages/orders/popups/totalOrdersPopups/totalActiveOrdersPopup/TotalActiveOrdersPopup'),
  ),
  [popupIdsOptions.viewPastOrdersPopup]: lazy(
    () => import('pages/orders/popups/totalOrdersPopups/pastOrdersPopup/PastOrdersPopup'),
  ),
  [popupIdsOptions.viewOnTheWayPopups]: lazy(
    () => import('pages/orders/popups/onTheWayPopup/OnTheWayPopup'),
  ),

  [popupIdsOptions.contactSellerPopup]: lazy(
    () => import('pages/orders/popups/contactTheSellerPopup/ContactTheSellerPopup'),
  ),
  [popupIdsOptions.viewShopViewsPopup]: lazy(
    () => import('pages/orders/popups/shopViewsPopup/ShopViewsPopup'),
  ),
  [popupIdsOptions.withdrawOrderPopup]: lazy(
    () => import('pages/orders/popups/withdrawOrderPopup/WithdrawOrderPopup'),
  ),
  [popupIdsOptions.declineOrderPopup]: lazy(
    () => import('pages/orders/popups/declineOrderPopup/DeclineOrderPopupWrapper'),
  ),
  [popupIdsOptions.declineVisionOfferPopup]: lazy(
    () => import('pages/specificVisionRequestPage/buyerVisionOffer/DeclineOfferPopupWrapper'),
  ),
  [popupIdsOptions.deleteVisionRequestPopup]: lazy(
    () => import('pages/visionsRequestsPage/visionRequest/deleteRequestPopup/DeleteRequestPopup'),
  ),
  [popupIdsOptions.withdrawVisionOfferPopup]: lazy(
    () =>
      import(
        'pages/visionsOffersPage/sellerVisionOffer/withdrawVisionOfferPopup/WithdrawVisionOfferPopup'
      ),
  ),
  [popupIdsOptions.payOrderPopup]: lazy(
    () => import('pages/orders/popups/payNowPopup/PayNowOrderPopupWrapper'),
  ),
  [popupIdsOptions.payVisionOfferPopup]: lazy(
    () => import('pages/specificVisionRequestPage/payVisionOfferPopup/PayVisionOfferPopup'),
  ),
  [popupIdsOptions.orderDigitalFilesPopup]: lazy(
    () => import('pages/orders/popups/digitalFilesPopup/DigitalFilesPopup'),
  ),
  [popupIdsOptions.didOrderArrivedPopup]: lazy(
    () => import('pages/orders/popups/didOrderArrivePopup/DidOrderArrivePopup'),
  ),
  [popupIdsOptions.orderReviewPopup]: lazy(
    () => import('pages/orders/popups/orderReviewPopup/OrderReviewPopup'),
  ),
  [popupIdsOptions.paymentFailedPopup]: lazy(
    () => import('pages/orders/popups/paymentStatusPopup/PaymentFailedPopup'),
  ),
  [popupIdsOptions.paymentSuccessPopup]: lazy(
    () => import('pages/orders/popups/paymentStatusPopup/PaymentSuccessfulPopup'),
  ),
  [popupIdsOptions.deactivatePayoutPopup]: lazy(
    () => import('pages/createShop/popups/deactivatePayoutPopup/DeactivatePayoutPopup'),
  ),
  [popupIdsOptions.onePayoutPopup]: lazy(
    () => import('pages/createShop/stepForms/payoutsForm/onePayoutPopup/OnePayoutPopup'),
  ),
  [popupIdsOptions.payoutIntroPopup]: lazy(
    () => import('components/introPopups/payoutIntroPopup/PayoutIntroPopup'),
  ),
  [popupIdsOptions.importCsvIntroPopup]: lazy(
    () => import('components/introPopups/importCsvIntroductionPopup/ImportCsvIntroductionPopup'),
  ),
  [popupIdsOptions.redirectPopup]: lazy(
    () => import('pages/createShop/popups/redirectPopup/RedirectPopup'),
  ),
  [popupIdsOptions.paypalErrorPopup]: lazy(
    () => import('pages/createShop/popups/paypalErrorPopup/PaypalErrorPopup'),
  ),
  [popupIdsOptions.addTrackingNumberPopup]: lazy(
    () => import('pages/orders/popups/addTrackingNumberPopup/AddTrackingNumberPopup'),
  ),
  [popupIdsOptions.orderArrivedPopup]: lazy(
    () => import('pages/orders/popups/orderArrivedPopup/OrderArrivedPopup'),
  ),
  [popupIdsOptions.waitForSellerPopup]: lazy(
    () => import('pages/orders/popups/waitForSellerPopup/WaitForSellerPopup'),
  ),
  [popupIdsOptions.newOrderPopup]: lazy(
    () => import('pages/orders/popups/newOrderPopup/NewOrderPopup'),
  ),
  [popupIdsOptions.paymentReceivedPopup]: lazy(
    () => import('pages/orders/popups/paymentReceivedPopup/PaymentReceivedPopup'),
  ),
  [popupIdsOptions.paymentIssuePopup]: lazy(
    () => import('pages/orders/popups/paymentIssuePopup/PaymentIssuePopup'),
  ),
  [popupIdsOptions.trackOrderPopup]: lazy(
    () => import('pages/orders/popups/trackOrderPopup/TrackOrderPopup'),
  ),
  [popupIdsOptions.itemAddedToBasketPopup]: lazy(
    () => import('pages/shopPage/popups/itemAddedToBasketPopup/ItemAddedToBasketPopup'),
  ),
  [popupIdsOptions.requestToBuyPopup]: lazy(
    () => import('pages/shopPage/popups/requestToBuyPopup/RequestToBuyPopup'),
  ),
  [popupIdsOptions.sendFinalPricePopup]: lazy(
    () => import('pages/orders/popups/sendFinalPricePopup/SendFinalPricePopup'),
  ),
  [popupIdsOptions.orderDetailsPopup]: lazy(
    () => import('pages/orders/popups/orderDetailsPopup/OrdersDetailsPopup'),
  ),
  [popupIdsOptions.confirmPaymentPopup]: lazy(
    () => import('pages/orders/popups/confirmPaymentPopup/ConfirmPaymentPopup'),
  ),
  [popupIdsOptions.createShippingLabelPopup]: lazy(
    () => import('pages/orders/popups/createShippingLabelPopup/CreateShippingLabelPopup'),
  ),
  [popupIdsOptions.purchasedLabelPopup]: lazy(
    () => import('pages/orders/popups/purchasedLabelPopup/PurchasedLabelPopup'),
  ),
  [popupIdsOptions.connectShippoPopup]: lazy(
    () => import('pages/createShop/stepForms/shippoForm/connectToShippoPopup/ConnectToShippoPopup'),
  ),
  [popupIdsOptions.listingCategoryPopup]: lazy(
    () => import('pages/createShop/popups/listingCategoryPopup/ListingCategoryPopup'),
  ),
  [popupIdsOptions.returnsPolicyPopup]: lazy(
    () => import('pages/createShop/popups/returnsPolicyPopup/ReturnsPolicyPopup'),
  ),
  [popupIdsOptions.createShippingProfilePopup]: lazy(
    () => import('pages/createShop/popups/createShippingProfilePopup/CreateShippingProfilePopup'),
  ),
  [popupIdsOptions.shippingProfileConfirmAddressPopup]: lazy(
    () => import('pages/createShop/popups/confirmShippingAddressPopup/ConfirmAddressPopupWrapper'),
  ),
  [popupIdsOptions.confirmShippingAddressPopup]: lazy(
    () => import('pages/settings/popups/shippingAddressesPopup/ConfirmAddressPopupWrapper'),
  ),
  [popupIdsOptions.shippingProfileValidateAddressPopup]: lazy(
    () => import('pages/createShop/popups/validateAddressPopup/ValidateAddressPopupWrapper'),
  ),
  [popupIdsOptions.validateShippingAddressPopup]: lazy(
    () => import('pages/settings/popups/shippingAddressesPopup/ValidateAddressPopupWrapper'),
  ),
  [popupIdsOptions.validateBuyerShippingAddressPopup]: lazy(
    () =>
      import('pages/orders/popups/editBuyerShippingAddressPopup/ValidateBuyerAddressPopupWrapper'),
  ),
  [popupIdsOptions.confirmBuyerShippingAddressPopup]: lazy(
    () =>
      import(
        'pages/orders/popups/editBuyerShippingAddressPopup/confirmBuyerAddressPopupWrapper/ConfirmBuyerAddressPopupWrapper'
      ),
  ),
  [popupIdsOptions.invalidShippingAddressPopup]: lazy(
    () => import('pages/createShop/popups/invalidShippingAddressPopup/InvalidShippingAddressPopup'),
  ),
  [popupIdsOptions.selectListingShippingProfilePopup]: lazy(
    () => import('pages/createShop/popups/selectShippingProfilePopup/SelectShippingProfileWrapper'),
  ),
  [popupIdsOptions.selectVisionOfferShippingProfilePopup]: lazy(
    () => import('pages/visionsOffersPage/sendVisionOfferPopup/SelectShippingProfilePopupWrapper'),
  ),
  [popupIdsOptions.editWillAffectListingsPopup]: lazy(
    () => import('pages/createShop/popups/editWillAffectListingsPopup/EditWillAffectListingsPopup'),
  ),
  [popupIdsOptions.deleteShippingProfilePopup]: lazy(
    () => import('pages/settings/popups/deleteShippingProfilePopup/DeleteShippingProfilePopup'),
  ),
  [popupIdsOptions.shippingProfilesPopup]: lazy(
    () => import('pages/settings/popups/shippingProfilesPopup/ShippingProfilesPopup'),
  ),
  [popupIdsOptions.shippingIntroPopup]: lazy(
    () => import('components/introPopups/shippingIntroPopup/ShippingIntroPopup'),
  ),
  [popupIdsOptions.editBuyerShippingAddressPopup]: lazy(
    () => import('pages/orders/popups/editBuyerShippingAddressPopup/EditBuyerShippingAddressPopup'),
  ),
  [popupIdsOptions.updateShippingInformationPopup]: lazy(
    () =>
      import('pages/orders/popups/updateShippingInformationPopup/UpdateShippingInformationPopup'),
  ),
  [popupIdsOptions.upgradeToProPopup]: lazy(
    () => import('components/tedoooPro/upgradeToProPopup/UpgradeToProPopup'),
  ),
  [popupIdsOptions.subscribeSuccessPopup]: lazy(
    () => import('components/tedoooPro/subscribeSuccessPopup/SubscribeSuccessPopup'),
  ),
  [popupIdsOptions.favoritesIntroPopup]: lazy(
    () => import('components/introPopups/favoritesIntroPopup/FavoritesIntroPopup'),
  ),
  [popupIdsOptions.newSavedItemPopup]: lazy(
    () => import('components/introPopups/newSavedItemPopup/NewSavedItemPopup'),
  ),
  [popupIdsOptions.discoverCategoriesPopup]: lazy(
    () =>
      import(
        'pages/discoverPage/staticComponents/categoriesSection/categoriesPopup/DiscoverCategoriesPopup'
      ),
  ),
  [popupIdsOptions.mojosPopup]: lazy(() => import('pages/feed/popups/mojosPopup/MojosPopup')),
  [popupIdsOptions.patternExpiredPopup]: lazy(
    () => import('pages/chatv2/popups/patternExpiredPopup/PatternExpiredPopup'),
  ),
  [popupIdsOptions.buyVisionCreditsPopup]: lazy(
    () => import('pages/visionsPage/popups/buyVisionCreditsPopup/BuyVisionCreditsPopup'),
  ),
  [popupIdsOptions.buyVisionCreditsSuccessPopup]: lazy(
    () =>
      import('pages/visionsPage/popups/buyVisionCreditsSuccessPopup/BuyVisionCreditsSuccessPopup'),
  ),
  [popupIdsOptions.regenerateVisionPopup]: lazy(
    () => import('pages/generateVisionPage/regenerateVisionPopup/RegenerateVisionPopup'),
  ),
  [popupIdsOptions.visionsGuidePopup]: lazy(
    () => import('pages/visionsPage/popups/guidePopup/VisionGuidePopup'),
  ),
  [popupIdsOptions.requestToBuyVisionPopup]: lazy(
    () => import('pages/specificVisionPage/popups/requestToBuyVisionPopup/RequestToBuyVisionPopup'),
  ),
  [popupIdsOptions.selectShippingAddressPopup]: lazy(
    () =>
      import(
        'pages/specificVisionPage/popups/selectShippingAddressPopup/SelectShippingAddressPopup'
      ),
  ),
  [popupIdsOptions.sendVisionOfferPopup]: lazy(
    () => import('pages/visionsOffersPage/sendVisionOfferPopup/SendVisionOfferPopup'),
  ),
  [popupIdsOptions.hidePotentialVisionRequestPopup]: lazy(
    () => import('pages/visionsOffersPage/popups/hidePotentialOfferPopup/HidePotentialOfferPopup'),
  ),
  [popupIdsOptions.newVisionOffersPopup]: lazy(
    () => import('pages/specificVisionRequestPage/newOffersPopup/NewOffersPopup'),
  ),
  [popupIdsOptions.visionOfferApprovedPopup]: lazy(
    () => import('pages/specificVisionRequestPage/offerApprovedPopup/OfferApprovedPopup'),
  ),
  [popupIdsOptions.payBasketPopup]: lazy(
    () => import('pages/basketPage/payNowBasketPopup/PayNowBasketPopup'),
  ),
  [popupIdsOptions.saveItemsForLaterPopup]: lazy(
    () => import('pages/basketPage/saveItemsForLaterPopup/SaveItemsForLaterPopup'),
  ),
  [popupIdsOptions.goToCheckoutPopup]: lazy(
    () => import('components/goToCheckoutPopup/GoToCheckoutPopup'),
  ),
  [popupIdsOptions.fileSharingRulesPopup]: lazy(
    () => import('pages/chatv2/popups/fileSharingRulesPopup/FileSharingRulesPopup'),
  ),
  [popupIdsOptions.reportFileMessagePopup]: lazy(
    () => import('pages/chatv2/popups/reportFilePopup/ReportFilePopup'),
  ),
  [popupIdsOptions.reportFileMessageSuccessPopup]: lazy(
    () => import('pages/chatv2/popups/reportFileMessageSuccessPopup/ReportFileMessageSuccessPopup'),
  ),
};
