import { gql } from '@apollo/client';

export const GET_VISIONS_QUERY = gql`
  query GetDiscoverPageDataWeb($listId: ListItem!) {
    getDiscoverPageDataWeb(listId: $listId) {
      staticItems {
        id
        sectionDetails {
          order
          topSpacing
        }
      }
      visions {
        id
        sectionDetails {
          order
          topSpacing
        }
        title {
          text
          color
          font
          hasArrow
        }
        listInfo {
          listId
          title
          isFilterable
          initialPaginationToken
          screen
        }
        visions {
          id
          imageUrl
          prompt
          title
          user {
            id
            username
            fullName
            avatar
          }
        }
        sectionType
      }
    }
  }
`;

export const GET_VISIONS_BY_LIST_ID_QUERY = gql`
  query GetVisionsByListId($listId: ListItem!, $paginationToken: String, $limit: Int) {
    getVisionsByListId(listId: $listId, paginationToken: $paginationToken, limit: $limit) {
      visions {
        id
        imageUrl
        prompt
        user {
          id
          username
          fullName
          avatar
        }
      }
    }
  }
`;

export const GET_VISIONS_INFORMATION_QUERY = gql`
  query GetVisionInformationForUser {
    getVisionInformationForUser {
      visionsLeft
      myRequestCount
      first3Requests
      first3Seller
    }
    getSellerRequestsCounts {
      pendingCount
      potentialCount
    }
  }
`;

export const GET_CREDIT_OPTIONS_QUERY = gql`
  query GetVisionCreditOffers {
    getVisionCreditOffers {
      web {
        id
        currencySymbol
        currencyCode
        credits
        price
        tag {
          text
          variant
        }
      }
    }
  }
`;

export const GET_STRIPE_CHECKOUT_URL_MUTATION = gql`
  mutation StartVisionCheckoutSession($offerId: String!) {
    startVisionCheckoutSession(offerId: $offerId) {
      url
    }
  }
`;

export const GET_MY_VISION_REQUESTS_QUERY = gql`
  query GetMyRequests($paginationToken: String) {
    getMyRequests(paginationToken: $paginationToken) {
      paginationToken
      requests {
        id
        prompt
        user {
          username
          fullName
        }
        category
        date
        orderId
        notes
        totalOffers
        visionImageUrl
        shippingAddress {
          shippingAddress {
            city
            country
            address
            postalCode
            state
          }
        }
      }
    }
  }
`;

export const GET_POTENTIAL_VISION_OFFERS_QUERY = gql`
  query GetSellerRequests($paginationToken: String) {
    getSellerRequests(paginationToken: $paginationToken) {
      paginationToken
      requests {
        id
        visionImageUrl
        user {
          username
          fullName
        }
        notes
        category
        orderId
        prompt
        shippingAddress {
          shippingAddress {
            city
            country
            address
            state
            postalCode
          }
        }
      }
    }
  }
`;

export const GET_MY_VISION_OFFERS_QUERY = gql`
  query GetVisionOffers($listId: SellerTab!, $paginationToken: String) {
    getVisionOffers(listId: $listId, paginationToken: $paginationToken) {
      offers {
        orderId
        offerId
        partnerInfo {
          id
          avatar
          username
          fullName
        }
        returnPolicy {
          policy
          title
        }
        price {
          price
          currencyCode
          currency
        }
        shippingPrice {
          price
          currencyCode
          currency
        }
        deliveryTime {
          min
          max
        }
        shippingProfileId
        tariff
        dimensions {
          width
          height
          length
          weightBig
          weightBig
          metric
        }
        imageUrl
        buyerNotes
        category
        orderId
        shippingAddress {
          shippingAddress {
            city
            country
            address
            state
            postalCode
          }
        }
      }
    }
  }
`;

export const GET_VISIONS_OFFERS_TOTAL_COUNTS_QUERY = gql`
  query GetSellerRequestsCounts {
    getSellerRequestsCounts {
      potentialCount
      pendingCount
      expiredCount
    }
  }
`;

export const SEND_VISION_OFFER_MUTATION = gql`
  mutation SendOffer(
    $requestId: String!
    $price: Int!
    $tariff: String
    $returnsPolicy: ReturnsPolicyOptions!
    $shippingProfileId: String!
    $dimensions: DimensionsInput!
    $note: String
  ) {
    sendVisionOffer(
      request: {
        requestId: $requestId
        price: $price
        tariff: $tariff
        returnsPolicy: $returnsPolicy
        shippingProfileId: $shippingProfileId
        dimensions: $dimensions
        note: $note
      }
    ) {
      offerId
    }
  }
`;

export const EDIT_VISION_OFFER_MUTATION = gql`
  mutation EditVisionOffer(
    $offerId: String!
    $requestId: String!
    $price: Int!
    $tariff: String
    $returnsPolicy: ReturnsPolicyOptions!
    $shippingProfileId: String!
    $dimensions: DimensionsInput!
    $note: String
  ) {
    editVisionOffer(
      offerId: $offerId
      request: {
        requestId: $requestId
        price: $price
        tariff: $tariff
        returnsPolicy: $returnsPolicy
        shippingProfileId: $shippingProfileId
        dimensions: $dimensions
        note: $note
      }
    ) {
      success
    }
  }
`;

export const GET_SHIPPING_PRICE_BY_DIMENSIONS_QUERY = gql`
  query GetShippingPriceByDimensions(
    $dimensions: DimensionsInput!
    $requestId: String!
    $shippingProfileId: String!
  ) {
    getRecommendedShippingPriceForDimensions(
      dimensions: $dimensions
      requestId: $requestId
      shippingProfileId: $shippingProfileId
    ) {
      price {
        price
        currency
        currencyCode
      }
    }
  }
`;

export const GET_REQUEST_FOR_OFFER_QUERY = gql`
  query GetVisionRequestForOffer($id: String!) {
    getVisionRequestForOffer(id: $id) {
      id
      visionImageUrl
      user {
        username
        fullName
      }
      notes
      category
      prompt
      orderId
      shippingAddress {
        shippingAddress {
          city
          country
          address
          state
          postalCode
        }
      }
    }
  }
`;

export const GET_VISION_CURRENCY_CODE_QUERY = gql`
  query GetVisionCurrency {
    getVisionCurrency {
      currencyCode
      currency
    }
  }
`;
