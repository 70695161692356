import { IContactUsApi, Method } from 'types';
import { fetcher } from '../../api';

export class ContactUsReducer implements IContactUsApi {
  onSubmit(text: string, name: string, email: string, token?: string): Promise<any> {
    const textToSend = `${text}\n--name: ${name}\n--replyEmail: ${email}\n--send from web`;

    return fetcher('messages', Method.POST, token, { text: textToSend });
  }
}
