import {
  MenuOptions,
  VisionOffersTab,
  IVisionRequest,
  IShippingProfile,
  IAddressPreview,
  IPrice,
  ReturnPolicyType,
  IProductDimensions,
  ISendVisionOfferState,
} from 'types';

export interface IVisionOffer {
  id: string;
  address: IAddressPreview;
  buyerNote: string;
  sellerNote: string;
  deliveryTime: { min: number; max: number };
  category: string;
  imageUrl: string;
  date: number;
  type: VisionOfferType;
  price: IPrice;
  shippingPrice: IPrice;
  dimensions: IProductDimensions;
  tariffNumber: string | null;
  shippingProfileId: string;
  isBestMatch: boolean;
  returnPolicy: {
    type: ReturnPolicyType;
    text: string;
  };
  partnerDetails: {
    id: string;
    username: string;
    avatar: string;
  };
  orderId: string | null;
}

export interface ISellerVisionOffer {
  id: string;
  address: string;
  notes: string;
  username: string;
  category: string;
  imageUrl: string;
  date: number;
  type: VisionOfferType;
  onClick(): void;
  onDropdownChange(option: MenuOptions): void;
}

export enum VisionOfferType {
  Potential,
  Pending,
  Approved,
  Expired,
}

export interface IVisionOffersPage {
  activeTab: VisionOffersTab;
  list: IVisionOffer[] | IVisionRequest[] | null;
  hasMore: boolean;
  mapActiveTabToTotalCount: { [key in VisionOffersTab]: number };
  shouldBlockPage: boolean;
  isLoading: boolean;
  onEditOffer(input: { offer: IVisionOffer }): void;
  getPotentialOffers(): void;
  showPopup(input: { id: string; requestId?: string; offerId?: string }): void;
  getTotalCounts(): void;
  onTabChange(input: { tab: VisionOffersTab }): void;
  getOffers(): void;
}

export interface ISendVisionOfferPopup {
  offerId: string | null;
  request: IVisionRequest;
  sendOfferForm: ISendVisionOfferState;
  shippingProfile: IShippingProfile | null;
  getOffer(input: { offerId: string }): void;
  onEditOffer(input: { offerId: string }): void;
  getRequest(input: { id: string }): void;
  getVisionCurrencyCode(): void;
  reset(): void;
  getShippingPrice(input: { requestId: string }): void;
  hidePopup(): void;
  showPopup(input: { id: string; requestId: string }): void;
  onPriceChange(input: { price: number }): void;
  onTariffNumberChange(input: { text: string }): void;
  onAdditionalCommentsChange(input: { text: string }): void;
  onSendOffer(input: { requestId: string }): void;
}

export interface IHidePotentialOfferPopup {
  requestId: string;
  hidePopup(): void;
  hidePotentialOffer(input: { requestId: string }): void;
}
