import {
  BasketPageSources,
  DrawerIdsOptions,
  ICartItem,
  INotificationItem,
  INotificationsButton,
  INotificationsImage,
  INotificationsImageGroup,
  INotificationText,
  IPrice,
  PremiumStatus,
} from 'types';

export enum MenuButtonOptions {
  Home,
  Messaging,
  Notification,
  Discover,
  Orders,
  Mojos,
}

export enum ProfileGroups {
  Premium,
  My,
  Create,
  Company,
}

export enum ProfileMenuOptions {
  UpgradeToPro,
  Visions,
  GetVisionsCredits,
  Offers,
  Favorites,
  CreatePost,
  CreateShop,
  MyShops,
  Following,
  Help,
  Posts,
  ContactUs,
  AboutUs,
  Settings,
  RenewPro,
}

export interface IMenu {
  token: string | null;
  avatar: string;
  userName: string | null;
  isAvatarLoading: boolean;
  userId: string | null;
  totalUnreadMessages: number;
  totalOrders: number;
  totalBasketItems: number;
  totalCompletedDraftSteps: number;
  totalNewNotifications: number;
  updateBasketSource(input: { source: BasketPageSources }): void;
  showPopup(options: { id: string }): void;
  onSignOut(): void;
  onAvatarLoading(): void;
  onShowMenuDrawer(options: { id: DrawerIdsOptions }): void;
  onAvatarUpdate(input: { avatar: string }): void;
}

export interface IProfileDropMenu {
  userName: string;
  isAvatarLoading: boolean;
  avatar: string;
  totalCompletedDraftSteps: number;
  onFavoritesClick(): void;
  onMyPostsClick(): void;
  onNavigate(route: string): void;
  onAvatarChange(avatar: File): void;
  onSignOut(): void;
  onViewProfile(): void;
  showPopup(input: { id: string }): void;
}

export interface IMenuItem {
  isSelected: boolean;
  text: string;
  Icon: any;
  totalNotifications: number;
  onClick(): void;
}

export interface IMenuDrawer {
  avatar: string | null;
  userName: string | null;
  userId: string | null;
  onHide(): void;
  onSignOut(): void;
  showPopup(options: { id: string }): void;
}

export interface IMenuDropdown {
  avatar: string;
  userName: string;
  isAvatarLoading: boolean;
  totalCompletedDraftSteps: number;
  onOptionClick(optionType: ProfileMenuOptions): void;
  onAvatarChange(src: File): void;
  onViewProfile(): void;
  onSignOut(): void;
}

export interface IProfileContent {
  onOptionClick(type: ProfileMenuOptions): void;
  onSignOut(): void;
  shouldShowVisions: boolean;
  premiumStatus: PremiumStatus;
  isDraftSaved: boolean;
  isSubscribed: boolean;
  isShopOwner: boolean;
  loggedInUserShopId?: string;
}

export enum NotificationsTabs {
  All = 'All',
  Orders = 'Orders',
}

export interface INotifications {
  list: INotificationItem[] | null;
  isLoading: boolean;
  hasMore: boolean;
  activeTab: NotificationsTabs;
  groupCounts: { total: number; text: string }[];
  onCreatePost(input: { id: string }): void;
  onFollowToggle(input: { notificationId: string; userId: string; isFollowing: boolean }): void;
  reset(): void;
  onTabChange(input: { tab: NotificationsTabs }): void;
  onCloseClick(): void;
  getOrderNotifications(): void;
  getNotifications(): void;
}

export enum NotificationActionTypes {
  CreatePost = 'CreatePost',
  Follow = 'Follow',
  Navigate = 'Navigate',
  Unfollow = 'Unfollow',
}

export interface INotificationRow {
  id: string;
  isNew: boolean;
  titles: INotificationText[];
  text: INotificationText[];
  buttonDetails: INotificationsButton | null;
  date: number;
  link: string | null;
  prefixImage: INotificationsImageGroup | null;
  suffixImage: INotificationsImageGroup | null;
  imageGrid: INotificationsImage[] | null;
  onRowClick(id: string, link: string): void;
  onButtonClick(id: string, link: string): void;
}

export interface IBasketPopover {
  totalItems: number;
  items: ICartItem[] | null;
  subTotalPrice: IPrice;
  onRemoveItem(input: { item: ICartItem; checkoutId: string; shouldFavorite: boolean }): void;
  updateSource(input: { source: BasketPageSources }): void;
  getCart(): void;
  onHide(): void;
}
