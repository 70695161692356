import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { MenuSlicer, onSearch as getSearchOptions } from 'store/menu/MenuActions';
import Search from 'components/search/Search';
import { ROUTES } from 'constant/routes';
import { useSkipFirstEffect } from 'hooks/useSkipFirstEffect';
import './menuSearch.scss';
import { IStore2 } from 'types';

const SEARCH_DEBOUNCE = 500;

export interface IMenuSearch {
  recentSearches: Array<string>;
  searchOptions: Array<string>;
  search: string;
  onSearch(options: { text: string }): void;
  onSubmit(options: { selectedOption: string }): void;
}

const MenuSearch: FC<IMenuSearch> = ({
  search,
  searchOptions,
  onSubmit,
  recentSearches,
  onSearch,
}) => {
  const [text, setText] = useState(search);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  useSkipFirstEffect(() => {
    const textParam = searchParams.get('text') || '';
    setText(textParam);
  }, [searchParams]);

  const getOptions = useCallback(
    debounce((value: string) => {
      onSearch({ text: value });
    }, SEARCH_DEBOUNCE),
    [],
  );
  useEffect(() => {
    if (text) {
      getOptions(text);
    }
  }, [text]);

  const onOptionClick = (option: string) => {
    const searchText = `?text=${option.toLowerCase()}&tab=top`;
    navigate(`/${ROUTES.SEARCH}${searchText}`);
    onSubmit({ selectedOption: option });
  };

  const shouldDisplayRecentSearches = !text;

  const options = shouldDisplayRecentSearches ? recentSearches : searchOptions;

  const parsedOption = options.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <div className="search-container-menu">
      <Search
        placeholder="Search"
        value={text}
        options={parsedOption}
        defaultValue={search}
        onChange={setText}
        onOptionClick={onOptionClick}
      />
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { search, searchOptions, recentSearches } = state.menu;

  return { search, searchOptions, recentSearches };
};

const mapDispatchToProps = {
  onSearch: getSearchOptions,
  onSubmit: MenuSlicer.actions.onSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSearch);
