import { GetDigitalFileUrlsResponse, SuccessResponse } from 'graphql/generated/graphql';
import {
  OrderStatuses,
  IPriceResponse,
  PayoutMethods,
  IOrderItem,
  IOrderPartnerDetails,
  IOrderResponse,
  ITrackingActivity,
  IPrice,
  IPayoutOptions,
  IOriginAddress,
  IShippingLabelDetails,
  ISelectedShippingService,
  IRecipientAddress,
  IProductDimensions,
  ILinkedPaymentOption,
  IDiscountDetails,
  IOrderDetailsState,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';

export interface ISpecificOrderState2 {
  id: string;
  orderNumber: string;
  shippingAddressName: string;
  totalDiscount: number;
  subTotalPrice: number;
  shippingPrice: number | null;
  totalPrice: number;
  status: OrderStatuses;
  currencyCode: CurrencyCode;
  items: IOrderItem[];
  hasDigitalFiles: boolean;
  isInstantCheckout: boolean;
  creationDate: number;
  isSeller: boolean;
  packingSlipUrl: string | null;
  shippingLabelUrl: string | null;
  shippingProviderImage: string | null;
  partnerDetails: IOrderPartnerDetails;
  lastActivityDate: number;
  paidPaymentMethod: PayoutMethods | null;
  shopName: string;
  title: string;
  trackingNumber: string | null;
  shopId: string;
  isShopReviewed: boolean;
  linkedPaymentOptions: ILinkedPaymentOption[];
  originalPrice: IPrice;
  trackingNumberMessage: string | null;
  carrierOptions: ICourier[] | null;
  trackingInformation: {
    activities: ITrackingActivity[];
    shippingProviderImage: string | null;
  };
  activities: IOrderActivity[];
  expiryDate: number | null;
  initialPopupOption: OrderInitialPopupOptions | null;
  payoutOptions: IPayoutOptions[];
  originAddress: IOriginAddress | null;
  shippingLabelDetails: IShippingLabelDetails | null;
  selectedShippingService: ISelectedShippingService | null;
  recipientAddress: IRecipientAddress | null;
  dimensions: IProductDimensions | null;
  digitalFiles: IOrderDigitalFile[] | null;
  email: string | null;
  isAnonymous: boolean;
  discountDetails: IDiscountDetails | null;
}

export interface ISpecificOrderState {
  id: string | null;
  orderDetails: IOrderDetailsState | null;
  activities: IOrderActivity[];
  initialPopupOption: OrderInitialPopupOptions | null;
  trackingNumberMessage: string | null;
  carrierOptions: ICourier[] | null;
  trackingInformation: {
    activities: ITrackingActivity[];
    shippingProviderImage: string | null;
  };
  digitalFiles: IOrderDigitalFile[] | null;
  email: string | null;
}

export interface IOrderActivity {
  id: string;
  date: number;
  status: OrderStatuses;
  isUserActivity: boolean;
  username: string;
  avatar: string;
  isCollapsible: boolean;
}

export enum OrderInitialPopupOptions {
  DigitalFiles = 'DigitalFiles',
  WaitForSeller = 'WaitForSeller',
}

export interface ISpecificOrderApi {
  getSpecificOrder(token: string, orderId: string): Promise<ISpecificOrderResponse>;
  requestOrder(
    token: string,
    items: any[],
    shopId: string,
    city: string,
    address: string,
    country: Countries,
    postalCode: string,
    name: string,
    phoneNumber: string,
    selectedDeliveryId: string | null,
    couponCode: string | null,
  ): Promise<{ id: string }>;
  updateOrderStatus(
    token: string,
    orderId: string,
    body: IUpdateOrderStatusBody,
  ): Promise<IUpdateOrderStatusResponse>;
  getOrderDigitalFiles(token: string, orderId: string): Promise<GetDigitalFileUrlsResponse>;
  sendEmailDigitalFiles(token: string, orderId: string, email: string): Promise<SuccessResponse>;
  checkTrackingNumber(token: string, trackingNumber: string): Promise<ICourier[]>;
  getTrackingInformation(token: string, orderId: string): Promise<IGetTrackingInformationResponse>;
}

export interface ISpecificOrderResponse {
  order: IOrderResponse;
  activities: IOrderActivityResponse[];
}

export interface ILinkedPaymentOptionsResponse {
  id: string;
  title: string;
  description: string;
  buttonText: string;
  imageUrls: string[];
}

export interface IOrderItemResponse {
  id: string;
  title: string;
  image: string;
  price: IPriceResponse;
  isDigital: boolean;
  originalPrice?: IPriceResponse;
  quantity: number;
  customization?: {
    note?: string;
    images?: string[];
  };
}

export interface IOrderPartnerInfoResponse {
  _id: string;
  username: string;
  fullname: string;
  email: string;
  coverPhoto?: string;
  avatar?: string;
  lastViewedOrder: string;
  currencyCode: CurrencyCode;
}

export interface IOrderActivityResponse {
  id: string;
  selfMade: boolean;
  status: OrderStatuses;
  date: string;
  text?: string;
  image?: string;
}

export interface IOrderItemUpdateStatusRequest {
  id: string;
  title: string;
  image: string;
  price: number;
  currency: {
    code: string;
    symbol: string;
  };
  quantity: number;
  customization?: { note: string | null; images: string[] };
  sale: number;
}

export interface IUpdateOrderStatusBody {
  status: OrderStatuses;
  shipping?: string;
  trackingNumber?: string;
  courierCode?: string;
  fullName?: string;
  additionalDetails?: string;
  shippingCost?: number;
  declineReason?: string;
  items?: IOrderItemUpdateStatusRequest[];
}

export interface IUpdateOrderStatusResponse {
  couriers?: ICourier[];
}

export interface ICourier {
  code: string;
  name: string;
  image: string | null;
}

export interface IGetTrackingInformationResponse {
  trackingNumber: string;
  companyImage: string;
  activities: ITrackingActivity[];
}

export interface ISelectedShippingServiceResponse {
  name: string;
  image: string | null;
  price: IPriceResponse;
  arrivalDate: number;
  canCreateShippingLabel: boolean;
}

export interface IOrderDigitalFile {
  url: string;
  name: string;
}
