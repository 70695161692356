import { ProfileGroups, ProfileMenuOptions } from 'types';
import { translations } from 'translations/en';
import PremiumIcon from 'photos/premium.png';

export const menuGroupsOrder = [
  ProfileGroups.Premium,
  ProfileGroups.My,
  ProfileGroups.Create,
  ProfileGroups.Company,
];

export const mapProfileOptionToText = {
  [ProfileMenuOptions.Help]: translations.PAGES.HELP,
  [ProfileMenuOptions.CreateShop]: translations.PAGES.CREATE_SHOP,
  [ProfileMenuOptions.CreatePost]: translations.FEED.CREATE_POST,
  [ProfileMenuOptions.MyShops]: translations.PAGES.MY_SHOPS,
  [ProfileMenuOptions.Following]: translations.PAGES.FOLLOWING,
  [ProfileMenuOptions.Posts]: translations.PAGES.POSTS_ACTIVITY,
  [ProfileMenuOptions.ContactUs]: translations.PAGES.CONTACT_US,
  [ProfileMenuOptions.AboutUs]: translations.PAGES.ABOUT_US,
  [ProfileMenuOptions.Settings]: translations.PAGES.SETTINGS,
  [ProfileMenuOptions.Offers]: translations.PAGES.OFFERS,
  [ProfileMenuOptions.UpgradeToPro]: translations.PAGES.BECOME_A_MEMBER,
  [ProfileMenuOptions.RenewPro]: translations.PAGES.RENEW_PRO,
  [ProfileMenuOptions.Favorites]: translations.PAGES.FAVORITES,
  [ProfileMenuOptions.Visions]: translations.PAGES.VISIONS,
  [ProfileMenuOptions.GetVisionsCredits]:
    translations.GENERATE_VISION_PAGE.BUY_CREDITS_POPUP.DROPDOWN_TEXT,
};

// Group content
export const mapProfileGroupToOptions = {
  [ProfileGroups.Premium]: [ProfileMenuOptions.UpgradeToPro, ProfileMenuOptions.RenewPro],
  [ProfileGroups.My]: [
    ProfileMenuOptions.Visions,
    ProfileMenuOptions.GetVisionsCredits,
    ProfileMenuOptions.MyShops,
    ProfileMenuOptions.Offers,
    ProfileMenuOptions.Favorites,
    ProfileMenuOptions.Settings,
    ProfileMenuOptions.Following,
    ProfileMenuOptions.Posts,
  ],
  [ProfileGroups.Create]: [ProfileMenuOptions.CreateShop, ProfileMenuOptions.CreatePost],
  [ProfileGroups.Company]: [ProfileMenuOptions.ContactUs, ProfileMenuOptions.AboutUs],
};

export const mapProfileOptionToPrefixIcon = {
  [ProfileMenuOptions.UpgradeToPro]: PremiumIcon,
  [ProfileMenuOptions.RenewPro]: PremiumIcon,
};
