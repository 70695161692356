import { Middleware } from 'redux';

import { PopupSlicer } from '../popup/PopupActions';
import { FeedSlicer } from './FeedSlicer';
import {
  createPost,
  deleteComment,
  onHidePostFromActivity,
  onPostDelete,
  onPostHide,
  onPostReport,
  toggleRepost,
} from './PostActions';
import { checkIsFeedPopup } from '../post/helper';
import { FeedTypes, IStore2, MessageIds, popupIdsOptions } from 'types';
import { MessageSlicer } from '../message/MessageActions';
import { getUserShops } from './FeedActions';
import { isEmptyArray, refreshPage } from 'utils';
import { getProfileInformation, getUserPage } from '../profile/ProfileActions';
import { getShop } from '../shop/ShopActions';
import { onMojoDeleteComment } from '../mojos/mojo/MojoActions';
import { UserSlicer } from '../user/UserActions';

export const feedMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case onMojoDeleteComment.fulfilled.type:
      case deleteComment.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case toggleRepost.rejected.type:
      case toggleRepost.fulfilled.type:
        const { isReposted } = action.meta.arg;

        if (isReposted) {
          dispatch(MessageSlicer.actions.show({ id: MessageIds.repost }));
        } else {
          dispatch(MessageSlicer.actions.show({ id: MessageIds.undoRepost }));
        }
        break;
      case onPostHide.pending.type:
      case onPostReport.pending.type:
      case onHidePostFromActivity.pending.type:
      case onPostDelete.pending.type: {
        const state: IStore2 = getState();

        const { ids } = state.popup;

        if (ids.length > 0) {
          dispatch(PopupSlicer.actions.hide());
        }
        break;
      }
      case onHidePostFromActivity.rejected.type:
      case onHidePostFromActivity.fulfilled.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.hideFromActivity }));
        break;

      case onPostDelete.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        dispatch(MessageSlicer.actions.show({ id: MessageIds.deletePost }));
        break;
      case createPost.fulfilled.type: {
        const { repostId } = action.meta.arg;

        if (repostId) {
          dispatch(MessageSlicer.actions.show({ id: MessageIds.repost }));
        }
        break;
      }
      case getProfileInformation.fulfilled.type:
      case getUserPage.fulfilled.type:
      case getShop.fulfilled.type:
        dispatch(FeedSlicer.actions.updateSelectedFeedType({ type: FeedTypes.User }));
        break;

      case PopupSlicer.actions.hide.type: {
        const state: IStore2 = getState();

        const { ids, commentId, replyId } = state.popup;

        const isFeedPopup = checkIsFeedPopup(ids);

        const isReactionPopup = ids.find((id) => id === popupIdsOptions.feedReactionsPopup);

        if (isReactionPopup) {
          dispatch(FeedSlicer.actions.onLikesClose({ commentId, replyId }));
        }

        const isOnlyOnePopup = ids.length === 1;

        if (isOnlyOnePopup && isFeedPopup) {
          dispatch(FeedSlicer.actions.onPostUnselect());
        }

        const isRepostPopup = ids.find(
          (id) => id === popupIdsOptions.repostsPopup || id === popupIdsOptions.feedRepostsPopup,
        );
        if (isRepostPopup && isOnlyOnePopup) {
          dispatch(FeedSlicer.actions.resetReposts());
        }
        break;
      }

      case UserSlicer.actions.signOut.type:
        refreshPage();
        break;

      case PopupSlicer.actions.show.type: {
        const state: IStore2 = getState();

        const { ids } = state.popup;

        const { postId, subPostId, commentId, replyId, id: newPopupId } = action.payload;

        const isFeedPopup = checkIsFeedPopup([newPopupId]);

        if (
          newPopupId === popupIdsOptions.feedDeleteCommentPopup ||
          newPopupId === popupIdsOptions.mojoDeleteCommentPopup
        ) {
          dispatch(
            FeedSlicer.actions.onSelectedCommentChange({
              commentId,
              replyId,
              postId,
            }),
          );
        } else if (isFeedPopup && ids.length === 0) {
          dispatch(
            FeedSlicer.actions.onPostSelect({
              postId,
              subPostId,
            }),
          );
        }
        if (newPopupId === popupIdsOptions.createPostPopup) {
          const { shops } = state.feed.createPostDetails;

          if (isEmptyArray(shops)) {
            dispatch(getUserShops());
          }
        }
        break;
      }
      case FeedSlicer.actions.onFeedBubbleSelected.type:
        dispatch(FeedSlicer.actions.resetMainFeed());
        break;

      default:
        break;
    }

    return next(action);
  };
