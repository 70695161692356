import { isValidPhoneNumber } from 'libphonenumber-js';

export const validatePhoneNumber = (text: string, countryCode?: any) => {
  if (countryCode) {
    return isValidPhoneNumber(text, countryCode);
  }

  const REG = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  return REG.test(text);
};

export const validateEmail = (text: string) => {
  const REG = /^\S+@\S+\.\S+$/;

  return REG.test(text);
};

export const validateUrl = (text?: string) => {
  if (!text) {
    return false;
  }

  const REG1 =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

  const REG2 =
    /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

  return REG1.test(text) || REG2.test(text);
};
