import {
  DiscoverListsId,
  IFeedApi,
  IFeedResponseApi,
  IShopBaseInfoResponse,
  IShopShortInfoResponse,
  IUserInfoResponse,
  Method,
} from 'types';
import { fetcher } from 'api';
import { GET_FEED_QUERY } from './FeedQueries';
import { DiscoverPageResult, Query } from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';

export class FeedReducer implements IFeedApi {
  async getPosts(
    listId: DiscoverListsId,
    paginationToken: string | null,
    token?: string,
  ): Promise<DiscoverPageResult> {
    const variables = {
      paginationToken,
      listId,
    };

    const res = await graphQlRequest(token).query<Query>({
      query: GET_FEED_QUERY,
      variables,
    });

    return res.data.getDiscoverPageData;
  }

  getStories(token: string): Promise<any> {
    return fetcher('feed/extended', Method.GET, token);
  }

  getShops(token: string): Promise<IShopShortInfoResponse[]> {
    return fetcher('myshops/me', Method.GET, token);
  }

  getShopPosts(shopId: string, token: string, maxId?: string): Promise<IFeedResponseApi> {
    let query: any;

    if (maxId) {
      query = { id: maxId };
    }

    return fetcher(`posts/v2/shop/${shopId}`, Method.GET, token, undefined, query);
  }

  getUserPosts(userId: string, token: string, maxId?: string): Promise<IFeedResponseApi> {
    let query: any;

    if (maxId) {
      query = { id: maxId };
    }

    return fetcher(`posts/v2/user/${userId}`, Method.GET, token, undefined, query);
  }

  getShopProfile(shopId: string, token: string): Promise<IShopBaseInfoResponse> {
    return fetcher(`v2/shops/base/${shopId}`, Method.GET, token);
  }

  getUserProfile(userId: string, token: string): Promise<IUserInfoResponse> {
    return fetcher(`v2/userprofile/${userId}`, Method.GET, token);
  }
}
