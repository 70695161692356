import { gql } from '@apollo/client';

export const GET_SHIPPING_PROFILES_QUERY = gql`
  query GetShippingProfiles {
    getShippingProfiles {
      profiles {
        id
        isFixed
        name
        workingDays {
          max
          min
        }
        origin {
          city
          country
          postalCode
          state
          address
        }
      }
    }
  }
`;
