import './menu.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useGoogleLogout } from 'react-google-login';
import { FC, useState } from 'react';
import {
  BasketIcon,
  BellIcon,
  CreatePostIcon,
  MenuIcon,
  TedoooLogo,
} from 'tedooo-web-design-system/icons';
import { Button, CircleButton, ClickableImage, Popover } from 'tedooo-web-design-system/ui';
import classNames from 'classnames';

import MenuSearch from 'components/menu/search/MenuSearch';
import {
  BasketPageSources,
  DiscoverListsId,
  DrawerIdsOptions,
  IMenu,
  IStore2,
  popupIdsOptions,
} from 'types';
import DropMenu from './profile/dropMenu/DropMenu';
import { API_KEYS, ROUTES } from 'constant';
import { updateAvatar, UserSlicer } from 'store/user/UserActions';
import { UserSelector } from 'store/user/UserSelector';
import { uploadImage } from 'api';
import { LargeScreen } from '../helpers';
import MenuButtons from './menuButtons/MenuButtons';
import { DrawerSlicer } from 'store/drawers/DrawerActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import StickyGetTheAppButton from 'pages/getTheApp/stickyGetTheAppButton/StickyGetTheAppButton';
import { ChatSelector } from 'store/chat/ChatSelector';
import { CreateShopSelector } from 'store/createShop/CreateShopSelector';
import { buildDiscoverPageQuery, checkSmallScreen } from 'utils';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { translations } from 'translations/en';
import Notifications from './notifications/Notifications';
import NumberBubble from 'pages/chatv2/sideBar/sidebarList/numberBubble/NumberBubble';
import BasketPopover from './basketPopover/BasketPopover';
import { BasketSlicer } from 'store/basket/BasketSlicer';
import { menuOptionsOrder, mobileMenuOptionsOrder } from './menuButtons/helper';

const isSmallScreen = checkSmallScreen();

const Menu: FC<IMenu> = ({
  onSignOut,
  onShowMenuDrawer,
  userId,
  onAvatarUpdate,
  avatar,
  userName,
  isAvatarLoading,
  onAvatarLoading,
  totalUnreadMessages,
  totalNewNotifications,
  totalOrders,
  totalCompletedDraftSteps,
  totalBasketItems,
  updateBasketSource,
  showPopup,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const [isMobileNotificationsOpen, setIsMobileNotificationsOpen] = useState(false);

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const [isBasketVisible, setIsBasketVisible] = useState(false);

  const selectedRoute = location.pathname.split('/')[1];

  const onButtonClick = (route: string) => {
    if (route === ROUTES.GET_THE_APP) {
      showPopup({ id: popupIdsOptions.upgradeToProPopup });
    } else {
      if (route === ROUTES.DISCOVER) {
        AnalyticsEvents.onDiscoverSectionClick();
      }
      navigate(`/${route}`);
    }
  };

  const onLogoClick = () => navigate(`/${ROUTES.HOME}`);

  const onMyPostsClick = () => navigate(`/${ROUTES.HOME}?user=${userId}`);

  const onMobileNotificationVisibleChange = (isVisible: boolean) =>
    setIsMobileNotificationsOpen(isVisible);

  const onNotificationVisibleChange = (isVisible: boolean) => setIsNotificationsOpen(isVisible);

  const onBasketVisibleChange = (isVisible: boolean) => setIsBasketVisible(isVisible);

  const onBasketHide = () => setIsBasketVisible(false);

  const onFavoritesClick = () => {
    AnalyticsEvents.onEnterFavoritesPage('more');

    const query = buildDiscoverPageQuery({
      id: DiscoverListsId.Favorites,
      title: translations.PRODUCTS_PAGE.FAVORITES_TITLE,
      paginationToken: null,
    });
    navigate(`/${ROUTES.PRODUCTS}${query}`);
  };

  const onAvatarChange = async (file: File) => {
    onAvatarLoading();

    const image = await uploadImage({ file });
    onAvatarUpdate({ avatar: image });
  };

  const { signOut } = useGoogleLogout({
    clientId: API_KEYS.GOOGLE_CLIENT_ID,
    onLogoutSuccess: () => {},
    onFailure: () => {},
  });

  const onSignOutClick = () => {
    navigate(`/${ROUTES.DISCOVER}`);
    onSignOut();
    signOut();
  };

  const onCreatePostClick = () => {
    showPopup({ id: popupIdsOptions.createPostPopup });
  };

  const isMojosPage = selectedRoute === ROUTES.MOJOS;

  const onViewProfile = () => {
    navigate(`/${ROUTES.USER}/${userId}`);
  };

  const onMenuDrawerOpen = () => onShowMenuDrawer({ id: DrawerIdsOptions.Menu });

  const onBasketClick = () => {
    updateBasketSource({ source: BasketPageSources.BasketIcon });
    navigate(`/${ROUTES.BASKET}`);
  };

  const onMobileCloseNotifications = () => {
    setIsMobileNotificationsOpen(false);
  };

  const onCloseNotifications = () => {
    setIsNotificationsOpen(false);
  };

  const options = isSmallScreen ? mobileMenuOptionsOrder : menuOptionsOrder;

  return (
    <div className={classNames('menu-container', { 'dark-theme': isMojosPage })}>
      <div className="menu-content">
        <div className="menu-search">
          <MenuIcon className="menu-icon" onClick={onMenuDrawerOpen} />
          <TedoooLogo className="tedooo-logo" onClick={onLogoClick} />
          <MenuSearch />
          <Popover
            placement="top"
            content={<Notifications onCloseClick={onMobileCloseNotifications} />}
            onVisibleChange={onMobileNotificationVisibleChange}
            trigger={['click']}
            visible={isMobileNotificationsOpen}
            showArrow={false}
            overlayClassName="notifications-popover"
          >
            <div className="notifications-button">
              {totalNewNotifications > 0 && <NumberBubble value={totalNewNotifications} />}
              <ClickableImage icon={<BellIcon />} />
            </div>
          </Popover>
          <div className="basket-button-container">
            {totalBasketItems > 0 && <NumberBubble value={totalBasketItems} />}
            <ClickableImage icon={<BasketIcon />} onClick={onBasketClick} />
          </div>
        </div>
        <LargeScreen>
          <MenuButtons
            options={options}
            onClick={onButtonClick}
            selectedRoute={selectedRoute}
            totalUnreadMessages={totalUnreadMessages}
            totalOrders={totalOrders}
          />
          <div className="profile-section">
            <Button
              type="primary"
              size="medium"
              borderRadius="large"
              prefixIcon={<CreatePostIcon />}
              className="create-post-button"
              onClick={onCreatePostClick}
            />
            <Popover
              visible={isBasketVisible}
              placement="bottomRight"
              trigger={['click']}
              onVisibleChange={onBasketVisibleChange}
              content={<BasketPopover onHide={onBasketHide} />}
              overlayClassName="basket-popover-container"
            >
              <div className="basket-button-container">
                {totalBasketItems > 0 && <NumberBubble value={totalBasketItems} />}
                <CircleButton type="secondary-gray" size="medium" icon={<BasketIcon />} />
              </div>
            </Popover>
            <Popover
              placement="bottomRight"
              onVisibleChange={onNotificationVisibleChange}
              content={<Notifications onCloseClick={onCloseNotifications} />}
              visible={isNotificationsOpen}
              trigger={['click']}
              overlayClassName="notifications-popover"
            >
              <div className="notifications-button">
                {totalNewNotifications > 0 && <NumberBubble value={totalNewNotifications} />}
                <CircleButton
                  type="secondary-gray"
                  size="medium"
                  icon={<BellIcon />}
                  isActive={isNotificationsOpen}
                />
              </div>
            </Popover>
            <DropMenu
              onViewProfile={onViewProfile}
              isAvatarLoading={isAvatarLoading}
              onAvatarChange={onAvatarChange}
              onMyPostsClick={onMyPostsClick}
              onSignOut={onSignOutClick}
              onFavoritesClick={onFavoritesClick}
              avatar={avatar}
              totalCompletedDraftSteps={totalCompletedDraftSteps}
              userName={userName || ''}
              onNavigate={onButtonClick}
              showPopup={showPopup}
            />
          </div>
        </LargeScreen>
      </div>
      {selectedRoute !== ROUTES.CHAT && <StickyGetTheAppButton />}
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const shouldDisplayLottie = !!state.user.avatar;

  const { totalNewNotifications } = state.notifications;

  const totalCompletedDraftSteps = CreateShopSelector.totalDraftStepsCompletedSelector(state);

  const userName = UserSelector.loggedUserNameSelector(state);

  const isAvatarLoading = UserSelector.isAvatarLoading(state);

  const userId = UserSelector.loggedUserIdSelector(state);

  const token = UserSelector.tokenSelector(state);

  const avatar = UserSelector.defaultAvatarSelector(state);

  const totalUnreadMessages = ChatSelector.totalUnreadMessagesSelector(state);

  const totalOrders = UserSelector.totalOrdersSelector(state);

  const totalBasketItems = state.basket.totalItems;

  return {
    avatar,
    userId,
    userName,
    isAvatarLoading,
    shouldDisplayLottie,
    totalUnreadMessages,
    totalBasketItems,
    totalOrders,
    totalCompletedDraftSteps,
    totalNewNotifications,
    token,
  };
};

const mapDispatchToProps = {
  onSignOut: UserSlicer.actions.signOut,
  onAvatarUpdate: updateAvatar,
  onAvatarLoading: UserSlicer.actions.onAvatarLoading,
  updateBasketSource: BasketSlicer.actions.updateSource,
  onShowMenuDrawer: DrawerSlicer.actions.show,
  showPopup: PopupSlicer.actions.show,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
