import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  parseProfileInformationResponse,
  parseProfileViewsResponse,
  parseSuggestedShops,
} from '../apiParser';
import {
  getProfileInformation,
  getProfileViews,
  getSuggestedShops,
  onFollowUserToggle,
} from './ProfileActions';
import { IProfilePageState } from './types';
import { updateUserDetails } from '../user/UserActions';
import { ServerStatus } from '../shop/types';

const initialState: IProfilePageState = {
  shops: null,
  avatar: null,
  isFollowing: false,
  totalFollowers: 0,
  totalFollowing: 0,
  name: '',
  username: '',
  followSuggestions: [],
  bio: null,
  profileViews: null,
  posts: [],
  groups: [],
  isPremium: false,
  userId: '',
  suggestedShops: null,
  status: null,
};

export const ProfileSlicer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: (state) => {
      state.shops = [];
      state.avatar = null;
      state.isFollowing = false;
      state.totalFollowers = 0;
      state.totalFollowing = 0;
      state.name = '';
      state.username = '';
      state.userId = '';
      state.followSuggestions = [];
      state.bio = null;
      state.profileViews = null;
      state.posts = [];
      state.groups = [];
      state.isPremium = false;
      state.status = null;
    },
    resetFeed: (state) => {
      state.posts = [];
    },
    onEditReset: (state) => {
      state.status = null;
    },
    updateAvatar: (state, action) => {
      state.avatar = action.payload.avatar;
    },
    updateProfileDetails: (state, action) => {
      const { avatar, followers, following, followingCount, fullName, id, premium, username } =
        action.payload.user;
      state.shops = [];
      state.avatar = avatar;
      state.totalFollowers = followers;
      state.isFollowing = following;
      state.totalFollowing = followingCount;
      state.name = fullName;
      state.username = username;
      state.userId = id;
      state.followSuggestions = [];
      state.isPremium = premium;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileInformation.fulfilled, (state, action) => {
      const data = parseProfileInformationResponse(action.payload);

      const userId = action.meta.arg;
      state.name = data.name;
      state.username = data.username;
      state.bio = data.bio;
      state.userId = userId;
      state.isFollowing = data.isFollowing;
      state.posts = data.posts;
      state.totalFollowers = data.totalFollowers;
      state.shops = data.shops;
      state.followSuggestions = data.followSuggestions;
      state.totalFollowing = data.totalFollowing;
      state.groups = data.groups;
      state.isPremium = data.isPremium;
      state.avatar = data.avatar;
      state.status = ServerStatus.SUCCESS;
    });

    builder.addCase(getSuggestedShops.fulfilled, (state, action) => {
      state.suggestedShops = parseSuggestedShops(action.payload.followSuggestions);
    });
    builder.addCase(getProfileViews.fulfilled, (state, action) => {
      const { payload } = action;

      const parsedList = parseProfileViewsResponse(payload);

      const prevList = state.profileViews || [];
      state.profileViews = [...prevList, ...parsedList];
    });

    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      const { bio, name, avatar } = action.meta.arg;
      state.status = ServerStatus.SUCCESS;
      if (bio !== undefined) {
        state.bio = bio;
      }
      if (name) {
        state.name = name;
      }
      if (avatar) {
        state.avatar = avatar;
      }
    });

    builder.addMatcher(
      isAnyOf(updateUserDetails.pending, getProfileInformation.pending),
      (state) => {
        state.status = ServerStatus.PENDING;
      },
    );
    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state, action) => {
        const { userId } = action.meta.arg;

        const { userId: profileUserId } = state;

        const updatedIsFollow = !state.isFollowing;

        if (profileUserId !== userId) {
          const followSuggestion = state.suggestedShops?.find((item) => item.userId === userId);

          if (followSuggestion) {
            followSuggestion.isFollowing = !followSuggestion.isFollowing;
          }
        } else {
          const addValue = updatedIsFollow ? 1 : -1;
          state.totalFollowers += addValue;

          state.isFollowing = updatedIsFollow;
        }
      },
    );
  },
});
