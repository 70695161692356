import { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { Logout } from 'tedooo-web-design-system/icons';
import { Tag } from 'tedooo-web-design-system/ui';

import { IProfileContent, IStore2, PremiumStatus, ProfileGroups, ProfileMenuOptions } from 'types';
import {
  mapProfileGroupToOptions,
  mapProfileOptionToPrefixIcon,
  mapProfileOptionToText,
  menuGroupsOrder,
} from './helper';
import { Image, Span } from 'components/helpers';
import './profileContent.scss';
import { translations } from 'translations/en';
import { UserSelector } from 'store/user/UserSelector';
import { ConfigSelector } from 'store/config/ConfigSelector';

const ProfileContent: FC<IProfileContent> = ({
  onOptionClick,
  onSignOut,
  isSubscribed,
  isShopOwner,
  loggedInUserShopId,
  shouldShowVisions,
  premiumStatus,
  isDraftSaved,
}) => {
  const renderOption = useCallback((type: ProfileMenuOptions) => {
    let text = mapProfileOptionToText[type];
    if (type === ProfileMenuOptions.CreateShop && isDraftSaved) {
      text = translations.PAGES.COMPLETE_YOUR_SHOP;
    }

    const prefixIcon = mapProfileOptionToPrefixIcon[type];

    if (
      (type === ProfileMenuOptions.RenewPro && premiumStatus !== PremiumStatus.Expired) ||
      (type === ProfileMenuOptions.UpgradeToPro && premiumStatus !== PremiumStatus.TryFreeMonth) ||
      (!loggedInUserShopId && type === ProfileMenuOptions.MyShops) ||
      (type === ProfileMenuOptions.CreateShop && isShopOwner) ||
      (!shouldShowVisions &&
        (type === ProfileMenuOptions.Visions || type === ProfileMenuOptions.GetVisionsCredits))
    ) {
      return null;
    }

    const isNew = type === ProfileMenuOptions.Visions;

    return (
      <div key={type} onClick={() => onOptionClick(type)} className="option-item">
        {prefixIcon && <Image src={prefixIcon} className="prefix-icon" />}
        <Span text={text} className="text" />
        {isNew && <Tag color="purple" size="small" text="NEW!" />}
      </div>
    );
  }, []);

  const renderGroup = (groupType: ProfileGroups) => {
    const items = mapProfileGroupToOptions[groupType];

    if (groupType === ProfileGroups.Premium && isSubscribed) {
      return null;
    }

    return (
      <div key={`group-${groupType}`} className="option-item-group">
        {items.map((item) => renderOption(item))}
      </div>
    );
  };

  return (
    <>
      {menuGroupsOrder.map(renderGroup)}
      <div className="option-item sign-out-container" onClick={onSignOut}>
        <Logout />
        <Span text={translations.COMMON.SIGN_OUT} className="text" />
      </div>
    </>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { isPremium: isSubscribed, loggedInUserShopId } = state.user;

  const premiumDetails = UserSelector.getPremiumDetailsSelector(state);

  const shouldShowVisions = ConfigSelector.shouldShowVisionsSelector(state);

  const { premiumStatus } = premiumDetails;

  const isShopOwner = !!loggedInUserShopId;

  return { isSubscribed, isShopOwner, premiumStatus, shouldShowVisions };
};

export default connect(mapStateToProps)(ProfileContent);
