import { ReactElement, ReactNode } from 'react';

import {
  IAccountPreferences,
  IBlockedUserState,
  IForgetPassword,
  ForgetPasswordPhasesOptions,
  DrawerIdsOptions,
  ServerStatus,
  IShippingAddress,
  IShippingProfile,
  PayoutMethods,
  MenuOptions,
  PremiumPlanOptions,
  PremiumStatus,
  IVacationModeDetails,
} from 'types';

export enum SettingPages {
  Account,
  Preferences,
  PayoutsAndShipping,
  SignIn,
}

export enum NotificationsTypes {
  NewPost = 'posts',
  NewOrder = 'orderUpdates',
  GroupMessage = 'groupMessages',
  PrivateMessage = 'privateMessages',
}

export enum EmailNotificationsTypes {
  NewOrder = 'orderUpdates',
  PrivateMessage = 'privateMessages',
}

export interface ISideMenu {
  activeTab: number;
  onClick(page: number): void;
  onShowMenuDrawer(options: { id: DrawerIdsOptions }): void;
}

export interface ISettingsContainer {
  title: string;
  children: ReactNode;
}

export interface ISettingsRow {
  title: string;
  description?: string;
  extraDescriptionText?: string;
  descriptionIcon?: ReactNode;
  button: ReactElement;
  danger?: boolean;
  isClickable: boolean;
  prefixImage?: string;
  onClick(): void;
}

export interface ISideMenuItem {
  title: string;
  isActive: boolean;
  onClick(): void;
}

export interface IBlockedUser extends IBlockedUserState {
  isBlocked: boolean;
}

export interface IBlockedPopup {
  blockedUsers: IBlockedUser[] | null;
  unBlockUser(input: { id: string }): void;
  updateBlockedUsers(input: { blockedUsers: IBlockedUser[] }): void;
  hide(): void;
}

export interface IVacationModePopup {
  initialIsActive: boolean;
  initialShopMessage: string;
  initialAutoReplyMessage: string;
  isLoading: boolean;
  getVacationDetails(): void;
  onSave(input: { details: IVacationModeDetails | null }): void;
  hide(): void;
}

export interface IEditFullNamePopup {
  username: string;
  errorMessage: string | null;
  resetErrorMessage(): void;
  checkUsername(input: { username: string }): void;
  onSave(input: { username: string }): void;
  hidePopup(): void;
}

export interface IShippingAddressesPopup {
  shippingAddresses: IShippingAddress[] | null;
  hasDefaultAddress: boolean;
  saveAddressForm(input: { shippingAddress: IShippingAddress }): void;
  validateAddress(): void;
  getAddresses(): void;
  onDelete(input: { shippingId: string }): void;
  hidePopup(): void;
}

export interface IShippingAddressContainer {
  address: IShippingAddress;
  onOptionClick(key: MenuOptions, address: IShippingAddress): void;
}

export interface IChangePasswordPopup {
  hidePopup(): void;
  hideMessage(): void;
  onReset(): void;
  showPopup(options: any): void;
  serverStatus: ServerStatus | null;
  onPasswordUpdate(input: { oldPassword: string; password: string }): void;
}

export interface ILoggedInPopup {
  hide(): void;
  thirdParty: 'Google' | 'Facebook' | null;
}

export interface IBlockedUserProps extends IBlockedUser {
  onToggle(id: string): void;
}

export interface INotificationsPopup {
  notificationType: NotificationsTypes;
  pushNotificationsDefaultValue: boolean;
  emailNotificationsDefaultValue: boolean | null;
  isEmailDisabled: boolean;
  shouldDisplayEmail: boolean;
  onHide(): void;
  onSave(input: {
    type: NotificationsTypes;
    pushNotifications: boolean;
    emailNotifications: boolean | null;
  }): void;
}

export interface ILanguagePopup {
  hide(): void;
  accountPreferences: IAccountPreferences | null;
  saveLanguage(input: { selectedLanguage: string }): void;
}

export interface IForgetPasswordPopup extends IForgetPassword {
  onPhaseChange(phase: ForgetPasswordPhasesOptions): void;
  hidePopup: () => void;
  hideMessage(): void;
}

export interface IDeleteAccountPopup {
  avatar: string;
  thirdParty: string | null;
  username: string;
  deleteAccountErrorMessage: string | null;
  onHide(): void;
  showPopup(input: { id: string }): void;
  onDeleteAccount(input: {
    password: string;
    username: string;
    reason: string;
    text: string | null;
  }): void;
  onDeleteAccountThirdParty(input: { reason: string; text: string | null }): void;
}

export enum DeleteAccountSteps {
  Intro,
  Reasons,
  ConfirmDelete,
}

export enum DeleteAccountReasons {
  NoSales = 'No sales',
  NothingToBuy = 'Nothing to buy',
  SecondAccount = 'Have another account',
  Uninteresting = 'No relevant',
  Notifications = 'Too many notifications',
  Other = 'Other',
}

export enum ShippingAddressesSteps {
  Edit,
  Add,
  Main,
}

export interface ISettings {
  getProfileInformation(userId: string): void;
  getAccountPreferences(): void;
  getBlockedUsers(): void;
  getPostNotifications(): void;
  getShopInfo(): void;
  onShowPayouts(): void;
  onShippoConnect(): void;
  getVacationModeDetails(): void;
  showPopup(input: { id: string }): void;
  getPremiumDetails(): void;
  loggedInUserId: string | null;
  defaultShippingAddress: IShippingAddress | null;
  shippingProfiles: IShippingProfile[];
  birthday: number | null;
  payouts: PayoutMethods[];
  email: string | null;
  fullName: string;
  username: string;
  vacationModeDetails: IVacationModeDetails | null;
  accountPreferences: IAccountPreferences | null;
  totalBlockedUsers: number | null;
  userCurrency: string;
  isShippoConnected: boolean;
  pushNotifications: { [key in NotificationsTypes]: boolean } | null;
  emailNotifications: { [key in EmailNotificationsTypes]: boolean } | null;
  premiumPlan: PremiumPlanOptions;
  premiumStatus: PremiumStatus;
}

export interface ISubscriptions {
  premiumStatus: PremiumStatus;
  title: string;
  plan: PremiumPlanOptions;
  showPopup: (input: { id: string }) => void;
}
