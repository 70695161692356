import { useNavigate } from 'react-router-dom';

import { FooterOrder, mapFooterOptionToLabel, mapFooterOptionToRoute } from './helper';
import { FooterOptions } from './types';
import { Span } from 'components/helpers';
import './footer.scss';
import { getYear } from 'utils';

const Footer = () => {
  const navigate = useNavigate();

  const onItemClick = (option: FooterOptions) => {
    const route = mapFooterOptionToRoute[option];
    navigate(`/${route}`);
  };

  const year = getYear();

  const tedoooText = `@${year} Tedooo, inc.`;

  return (
    <div className="sign-footer">
      {FooterOrder.map((option, key) => (
        <Span
          key={key}
          className="footer-option clickable"
          text={mapFooterOptionToLabel[option]}
          onClick={() => onItemClick(option)}
        />
      ))}
      <Span className="footer-option" text={tedoooText} />
    </div>
  );
};

export default Footer;
