import { Middleware } from 'redux';

import { addComment, addReply, onLikeToggle } from '../specificPost/SpecificPostActions';
import {
  addComment as addCommentToPost,
  addReply as replyToComment,
  createPost,
  onHidePostFromActivity,
  onLikePostToggle as likePost,
  onPostDelete,
  onPostHide,
  onPostReport,
  toggleRepost,
} from '../feed/PostActions';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import {
  facebookLogin,
  finishSignUp,
  googleLogin,
  signUp,
  submitVerifyCode,
  UserSlicer,
} from '../user/UserActions';
import {
  ILegacyPayoutMethod,
  IStore2,
  PaymentSource,
  PayoutMethods,
  ShippingCountriesType,
} from '../types';
import { createShop, updateLegacyPaymentSection } from '../createShop/CreateShopActions';
import { deleteAccount, updatePassword, updateUserCurrency } from '../settings/SettingsActions';
import { getSelectedInterestsIds } from '../onboarding/helper';
import { UserProfile } from 'pages/onboarding/types';
import { onOnboardingFinish } from '../onboarding/OnboardingActions';
import {
  addShippingCost,
  declineOrder,
  markOrderAsArrived,
  markPaymentAsReceived,
  markPaymentAsSent,
  requestOrder,
} from '../specificOrder/SpecificOrderActions';
import { isEmptyArrayOrUndefined, toCurrency } from 'utils';
import { NonSignInPopupSources, OrderStatuses, SignPageOptions } from 'types';
import { withdrawOrder } from '../orders/OrdersActions';
import {
  deactivatePayout,
  getStripePaymentConfirmation,
  getStripeVisionPaymentConfirmation,
} from '../payouts/PayoutsActions';
import {
  addShippingProfile,
  buyShippingLabel,
  deleteShippingProfile,
  sendShippingLabelToEmail,
  unlinkShippo,
  updateShippingProfile,
} from '../shipping/ShippingActions';
import { FeedSlicer } from '../feed/FeedSlicer';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { onMojoDelete, onMojoFollowUserToggle, onMojoLikeToggle } from '../mojos/mojo/MojoActions';
import { generateVision } from '../generateVision/GenerateVisionActions';
import { requestToBuyVision } from '../specificVision/SpecificVisionActions';
import {
  declineVisionOffer,
  deleteVisionRequest,
  hideVisionRequest,
  withdrawVisionOffer,
} from '../specificVisionRequest/SpecificVisionRequestActions';
import { sendVisionOffer } from '../sendVisionOffer/SendVisionOfferActions';
import { onAddItemToBasket, onRemoveItemFromBasket } from '../basket/BasketActions';
import { BasketSlicer } from '../basket/BasketSlicer';
import { OrdersSlicer } from '../orders/OrdersSlicer';
import { mapItemStatusToEvent } from '../basket/helper';
import { PopupSlicer } from '../popup/PopupActions';

export const amplitudeMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case onLikeToggle.fulfilled.type: {
        const state: IStore2 = getState();

        const { isLiked: prevIsLiked } = state.specificPost;

        const isLiked = !prevIsLiked;

        if (isLiked) {
          AnalyticsEvents.likePost();
        } else {
          AnalyticsEvents.unlikePost();
        }
        break;
      }
      case likePost.fulfilled.type: {
        const { isLiked: prevIsLiked } = action.meta.arg;

        const isLiked = !prevIsLiked;

        if (isLiked) {
          AnalyticsEvents.likePost();
        } else {
          AnalyticsEvents.unlikePost();
        }
        break;
      }
      case onPostDelete.fulfilled.type:
        AnalyticsEvents.deletePost();
        break;

      case onMojoDelete.fulfilled.type:
        const { actionId } = action.meta.arg;
        AnalyticsEvents.deleteMojo(actionId);
        break;

      case createPost.fulfilled.type: {
        const { id, repostId, taggedUsers } = action.meta.arg;

        const isRepost = !!repostId;

        if (id) {
          AnalyticsEvents.editPost();
        } else {
          AnalyticsEvents.createPost(isRepost, taggedUsers.length);
        }
        break;
      }

      case toggleRepost.fulfilled.type:
        const { isReposted } = action.meta.arg;

        if (isReposted) {
          AnalyticsEvents.repost();
        }
        break;

      case FeedSlicer.actions.onPostImpression.type: {
        const { id } = action.payload;

        const state: IStore2 = getState();

        const { impressedPosts } = state.feed;

        if (!impressedPosts[id]) {
          AnalyticsEvents.postImpression(id);
        }

        break;
      }

      case addComment.fulfilled.type:
      case addCommentToPost.fulfilled.type:
        const totalTaggedUsers = action.meta.arg.taggedUsers.length;
        AnalyticsEvents.onComment(totalTaggedUsers);
        break;

      case onHidePostFromActivity.fulfilled.type:
        AnalyticsEvents.hidePostFromActivity();
        break;

      case addReply.fulfilled.type:
      case replyToComment.fulfilled.type:
        const totalTagged = action.meta.arg.taggedUsers.length;
        AnalyticsEvents.onReply(totalTagged);
        break;

      case onPostReport.fulfilled.type:
        AnalyticsEvents.reportPost();
        break;

      case onPostHide.fulfilled.type:
        AnalyticsEvents.hidePost();
        break;

      case signUp.fulfilled.type:
        AnalyticsEvents.signUpSendSms();
        break;

      case googleLogin.rejected.type:
        AnalyticsEvents.startSignUp('google');
        break;
      case facebookLogin.rejected.type:
        AnalyticsEvents.startSignUp('facebook');
        break;

      case submitVerifyCode.fulfilled.type:
      case finishSignUp.fulfilled.type: {
        const state: IStore2 = getState();

        const socialType = state.user.finishSignUpInformation?.type || 'tedooo';
        AnalyticsEvents.completeSignUp(socialType);

        break;
      }

      case onOnboardingFinish.fulfilled.type: {
        const state: IStore2 = getState();

        const { interests, mainGoal, shops, groups } = state.onboarding;

        const parsedInterests = interests ? getSelectedInterestsIds(interests).length : 0;

        AnalyticsEvents.onboardingStarted(mainGoal === UserProfile.Seller);

        AnalyticsEvents.onboardingInterest(parsedInterests);

        AnalyticsEvents.onboardingShopSuggestions(
          shops.filter((shop) => shop.isSelected).map((shop) => shop.shopName),
        );

        AnalyticsEvents.onboardingGroupSuggestions(
          groups.filter((group) => group.isSelected).map((group) => group.name),
        );
        AnalyticsEvents.onboardingFinished();

        break;
      }
      case createShop.fulfilled.type: {
        const store: IStore2 = getState();

        const { isFromOnboarding, shopId, legacyPayoutMethods, draftDetails } = store.createShop;

        const isEdit = !!shopId;

        const fromDraft = !!draftDetails;

        const paymentMethods = legacyPayoutMethods.map((method) => method.type);
        AnalyticsEvents.createShopFinished(isFromOnboarding, paymentMethods, isEdit, fromDraft);
        break;
      }

      case deleteAccount.fulfilled.type:
        AnalyticsEvents.deleteAccount();
        break;

      case updatePassword.fulfilled.type:
        AnalyticsEvents.changePassword();
        break;

      case updateUserCurrency.fulfilled.type:
        AnalyticsEvents.updateCurrency();
        break;

      case requestOrder.fulfilled.type:
        {
          const state: IStore2 = getState();

          const { shopBaskets } = state.basket;

          const { orderId } = action.meta.arg;

          const source = isEmptyArrayOrUndefined(shopBaskets) ? 'buy this item' : 'basket';
          AnalyticsEvents.onOrderStart(orderId, source);
        }
        break;

      case withdrawOrder.fulfilled.type:
        const { orderId: withdrawId } = action.meta.arg;
        AnalyticsEvents.onOrderEnd(withdrawId, 'cancelled');
        break;

      case addShippingCost.fulfilled.type:
        const { orderId: shippingCostId } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.Offered, shippingCostId);
        break;

      case markPaymentAsSent.fulfilled.type:
        const { orderId: paymentSentId } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.PaymentSent, paymentSentId);
        break;

      case markPaymentAsReceived.fulfilled.type:
        const { id: paymentReceivedId } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.PaymentReceived, paymentReceivedId);
        break;

      case declineOrder.fulfilled.type:
        const { orderId: declineId } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.Decline, declineId);
        AnalyticsEvents.onOrderEnd(declineId, 'declined');
        break;

      case markOrderAsArrived.fulfilled.type:
        const { orderId: orderArrivedIdId } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.Complete, orderArrivedIdId);
        AnalyticsEvents.onOrderEnd(orderArrivedIdId, 'completed');
        break;

      case deactivatePayout.fulfilled.type:
        const { payout } = action.meta.arg;
        AnalyticsEvents.onDisconnectPayout(payout);
        break;

      case getStripePaymentConfirmation.fulfilled.type: {
        const { source, id, price, selectedRateAttribute } = action.meta.arg;

        const orderId = source === PaymentSource.Order ? id : undefined;

        const parsedPrice = toCurrency(price.value, price.currencyCode, 2, price.currencySymbol);

        const store: IStore2 = getState();

        const isGuest = !!store.user.token;

        const checkoutId =
          source === PaymentSource.BuyNow || source === PaymentSource.Basket ? id : undefined;
        AnalyticsEvents.onOrderPaymentComplete(
          source,
          PayoutMethods.Stripe,
          parsedPrice,
          isGuest,
          selectedRateAttribute,
          checkoutId,
          orderId,
        );
        break;
      }
      case getStripeVisionPaymentConfirmation.fulfilled.type: {
        const { offerId, price, shippingRateAttribute } = action.meta.arg;

        const store: IStore2 = getState();

        const isGuest = !!store.user.token;

        const parsedPrice = toCurrency(price.value, price.currencyCode, 2, price.currencySymbol);
        AnalyticsEvents.onOrderPaymentComplete(
          PaymentSource.Vision,
          PayoutMethods.Stripe,
          parsedPrice,
          isGuest,
          shippingRateAttribute,
          undefined,
          offerId,
        );
        AnalyticsEvents.onVisionPaymentSuccess(offerId);
        break;
      }

      case OrdersSlicer.actions.updatePaidPaymentMethod.type: {
        const { id, source, price, shippingRateAttribute } = action.payload;

        const store: IStore2 = getState();

        const isGuest = !!store.user.token;

        const offerId = source === PaymentSource.Order ? id : undefined;

        const visionId = source === PaymentSource.Vision ? id : undefined;

        const checkoutId =
          source === PaymentSource.BuyNow || source === PaymentSource.Basket ? id : undefined;

        const parsedPrice = toCurrency(price.value, price.currencyCode, 2, price.currencySymbol);

        AnalyticsEvents.onOrderPaymentComplete(
          source,
          PayoutMethods.Paypal,
          parsedPrice,
          isGuest,
          shippingRateAttribute,
          checkoutId,
          offerId,
          visionId,
        );
        break;
      }

      case updateLegacyPaymentSection.fulfilled.type: {
        const state: IStore2 = getState();

        const { legacyPayoutMethods: legacyPayouts } = state.createShop;

        const parsedPayouts = legacyPayouts.map(
          (legacyPayout: ILegacyPayoutMethod) => legacyPayout.type,
        );
        AnalyticsEvents.onUpdateManualPayments(parsedPayouts);
        break;
      }
      case unlinkShippo.fulfilled.type:
        AnalyticsEvents.onDeactivateShippo();
        break;

      case UserSlicer.actions.updateShippoConnection.type:
        const isShippoConnected = action.payload?.isShippoConnected;

        if (isShippoConnected) {
          AnalyticsEvents.onConnectShippo();
        }
        break;

      case deleteShippingProfile.fulfilled.type:
        AnalyticsEvents.onDeleteShippingProfile();
        break;

      case updateShippingProfile.fulfilled.type: {
        const state: IStore2 = getState();

        const { shippingPriceOption, shippingDestinations } = state.shipping.shippingProfileForm;

        const { source = '' } = state.popup;

        const isShippingToSpecificCountries = !!shippingDestinations.find(
          (destination) => destination.countries.type === ShippingCountriesType.Specific,
        );

        AnalyticsEvents.onEditShippingProfile(
          shippingPriceOption,
          isShippingToSpecificCountries,
          source,
        );
        break;
      }

      case addShippingProfile.fulfilled.type: {
        const store: IStore2 = getState();

        const { isDuplicate, source = '' } = store.popup;

        if (isDuplicate) {
          AnalyticsEvents.onDuplicateShippingProfile();
        } else {
          const { shippingPriceOption, shippingDestinations } = store.shipping.shippingProfileForm;

          const isShippingToSpecificCountries = !!shippingDestinations.find(
            (destination) => destination.countries.type === ShippingCountriesType.Specific,
          );

          AnalyticsEvents.onAddShippingProfile(
            shippingPriceOption,
            isShippingToSpecificCountries,
            source,
          );
        }
        break;
      }

      case sendShippingLabelToEmail.fulfilled.type:
        AnalyticsEvents.onSendShippingLabelToEmail();
        break;

      case buyShippingLabel.fulfilled.type: {
        const { orderId, packageId, isAddressEdited, hasInsurance, hasSignature } = action.meta.arg;
        AnalyticsEvents.onOrderStatusChange(OrderStatuses.Shipped, orderId);

        AnalyticsEvents.onCreateShippingLabel(
          packageId,
          isAddressEdited,
          hasInsurance,
          hasSignature,
          orderId,
        );
        break;
      }

      case onProductLikeToggle.fulfilled.type: {
        const { isLiked: prevIsLiked, source, listId } = action.meta.arg;

        if (prevIsLiked) {
          AnalyticsEvents.onUnLikeProduct();
        } else {
          AnalyticsEvents.onLikeProduct(source, listId);
        }
        break;
      }

      case onMojoLikeToggle.fulfilled.type: {
        const state: IStore2 = getState();

        const { isLiked: prevIsLiked, source, mojoId, originSource } = action.meta.arg;

        const { sessionId } = state.mojos;

        const originType = originSource ? 'specific' : undefined;

        if (!prevIsLiked) {
          AnalyticsEvents.onMojoLike(mojoId, source, sessionId, originSource, originType);
        }
        break;
      }

      case onMojoFollowUserToggle.fulfilled.type: {
        const state: IStore2 = getState();

        const { isFollowing: prevIsFollowing, source, mojoId, originSource } = action.meta.arg;

        const { sessionId } = state.mojos;

        const originType = originSource ? 'specific' : undefined;

        if (!prevIsFollowing) {
          AnalyticsEvents.onMojoFollowToggle(mojoId, source, originSource, sessionId, originType);
        }
        break;
      }

      case generateVision.fulfilled.type: {
        const state: IStore2 = getState();

        const { hasUsedSmartTags } = state.visions;

        const { generationId } = action.payload;

        const hasImage = !!state.visions.promptDetails.image;

        AnalyticsEvents.onVisionGenerate(hasUsedSmartTags, generationId, hasImage);

        break;
      }

      case requestToBuyVision.fulfilled.type: {
        const { id, isOwner } = action.meta.arg;
        AnalyticsEvents.onSubmitVisionRequest(isOwner, id);

        break;
      }

      case deleteVisionRequest.fulfilled.type: {
        const { id } = action.meta.arg;
        AnalyticsEvents.onDeleteVisionRequest(id);

        break;
      }

      case declineVisionOffer.fulfilled.type: {
        const { offerId, reason } = action.meta.arg;
        AnalyticsEvents.onDeclineVisionOffer(offerId, reason);

        break;
      }
      case sendVisionOffer.fulfilled.type: {
        const { requestId } = action.meta.arg;

        const { offerId } = action.payload;
        AnalyticsEvents.onSubmitVisionOffer(requestId, offerId);

        break;
      }

      case hideVisionRequest.fulfilled.type: {
        const { requestId } = action.meta.arg;

        AnalyticsEvents.onHideVisionRequest(requestId);

        break;
      }

      case withdrawVisionOffer.fulfilled.type: {
        const { id } = action.meta.arg;

        AnalyticsEvents.onDeleteVisionOffer(id);

        break;
      }

      case onRemoveItemFromBasket.fulfilled.type: {
        const { item } = action.meta.arg;

        const { cartItemId, status } = item;

        const itemStatus = mapItemStatusToEvent[status];
        AnalyticsEvents.onRemoveFromBasket(cartItemId, itemStatus);
        break;
      }

      case BasketSlicer.actions.updateSource.type: {
        const { source } = action.payload;
        AnalyticsEvents.onEnterBasketPage(source);
        break;
      }

      case BasketSlicer.actions.updateBuyNowItem.type: {
        const state: IStore2 = getState();

        const { isBuyWithPaypal } = action.payload;

        const { quantity, bannerDetails } = state.specificProduct;

        const actionType = isBuyWithPaypal ? 'paypal' : 'buy_now';

        AnalyticsEvents.onBuyThisItemClick(quantity, actionType, false, bannerDetails?.id);
        break;
      }

      case onAddItemToBasket.fulfilled.type: {
        const state: IStore2 = getState();

        const { quantity, bannerDetails, source, id } = state.specificProduct;

        if (source) {
          AnalyticsEvents.onAddToBasketClick(source, quantity, id, bannerDetails?.id);
        }
        break;
      }

      case PopupSlicer.actions.show.type: {
        const { source } = action.payload;

        if (source) {
          AnalyticsEvents.onOpenSignupPopup(source);
        }

        break;
      }

      case UserSlicer.actions.changeSignPhase.type: {
        if (action.payload === SignPageOptions.SignUp) {
          AnalyticsEvents.onOpenSignupPopup(NonSignInPopupSources.JoinNow);
        }

        break;
      }

      default:
        break;
    }

    return next(action);
  };
