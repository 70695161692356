import { useNavigate } from 'react-router-dom';

import { translations } from 'translations/en';
import './sideBarFooter.scss';
import { ROUTES, TEDOOO_SUPPORT_ROUTE } from 'constant';

const SideBarFooter = () => {
  const navigate = useNavigate();

  const onPrivacyClick = () => {
    navigate(`/${ROUTES.PRIVACY}`);
  };

  const onTermsClick = () => {
    navigate(`/${ROUTES.TERMS}`);
  };

  const onHelpClick = () => {
    navigate(`/${TEDOOO_SUPPORT_ROUTE}`);
  };

  return (
    <div className="footer-container">
      <span onClick={onHelpClick}>{translations.PAGES.HELP}</span>
      <div className="dot" />
      <span onClick={onPrivacyClick}>{translations.PAGES.PRIVACY_POLICY}</span>
      <div className="dot" />
      <span onClick={onTermsClick}>{translations.PAGES.TERMS}</span>
    </div>
  );
};

export default SideBarFooter;
