import { Middleware } from 'redux';

import { PopupSlicer } from '../popup/PopupActions';
import { IStore2, MessageIds, popupIdsOptions, VisionOffersTab } from 'types';
import { isEmptyArray, openNewWindow } from 'utils';
import { startVisionsCreditsCheckout } from './VisionsActions';
import { VisionsSlicer } from './VisionsSlicer';
import { getShippingProfiles } from '../shipping/ShippingActions';
import { editVisionOffer, sendVisionOffer } from '../sendVisionOffer/SendVisionOfferActions';
import {
  declineVisionOffer,
  withdrawVisionOffer,
} from '../specificVisionRequest/SpecificVisionRequestActions';
import { generateVision } from '../generateVision/GenerateVisionActions';
import { MessageSlicer } from '../message/MessageActions';
import { SpecificVisionRequestSlicer } from '../specificVisionRequest/SpecificVisionRequestSlicer';

export const visionsMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case startVisionsCreditsCheckout.fulfilled.type:
        const { url } = action.payload;

        const window = openNewWindow(url);

        // Can be undefined when there is a popup blocker and no window is opened
        if (!window) {
          dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.redirectPopup, link: url }));
        }
        break;

      case VisionsSlicer.actions.updateTotalCredits.type: {
        const state: IStore2 = getState();

        const { totalCredits: prevTotalCredits } = state.visions;

        const { totalCredits } = action.payload;

        // checks if the user has more credits than before when socket updated
        const hasBoughtCredits = prevTotalCredits !== null && totalCredits > prevTotalCredits;

        const totalPurchasedCredits = totalCredits - (prevTotalCredits || 0);

        if (hasBoughtCredits) {
          dispatch(PopupSlicer.actions.hideAll());
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.buyVisionCreditsSuccessPopup,
              totalCredits: totalPurchasedCredits,
            }),
          );
        }
        break;
      }

      case withdrawVisionOffer.fulfilled.type:
      case declineVisionOffer.fulfilled.type:
        dispatch(PopupSlicer.actions.hideAll());
        break;

      case PopupSlicer.actions.show.type: {
        const state: IStore2 = getState();

        const { shippingProfiles } = state.shipping;

        const popupId = action.payload.id;

        if (popupId === popupIdsOptions.sendVisionOfferPopup && isEmptyArray(shippingProfiles)) {
          dispatch(getShippingProfiles());
        }
        break;
      }

      case sendVisionOffer.fulfilled.type:
      case editVisionOffer.fulfilled.type: {
        dispatch(PopupSlicer.actions.hideAll());
        dispatch(VisionsSlicer.actions.onTabChange({ tab: VisionOffersTab.Pending }));
        break;
      }

      case VisionsSlicer.actions.onOfferApprove.type: {
        const { orderId, visionImage } = action.payload;
        dispatch(
          PopupSlicer.actions.show({
            id: popupIdsOptions.visionOfferApprovedPopup,
            orderId,
            image: visionImage,
          }),
        );
        break;
      }

      case SpecificVisionRequestSlicer.actions.onOfferChange.type: {
        const { offer } = action.payload;
        dispatch(VisionsSlicer.actions.onEditOffer({ offer }));
        break;
      }

      case generateVision.rejected.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.generateVisionError }));
        break;

      default:
        break;
    }

    return next(action);
  };
