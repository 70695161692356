import { Languages } from 'store/settings/types';

export const languageLabel: { [key in Languages]: string } = {
  [Languages.en]: 'English',
  [Languages.zhhans]: 'Chinese',
  [Languages.ar]: 'Arabic',
  [Languages.si]: 'Sinhala',
  [Languages.th]: 'Thai',
  [Languages.ko]: 'Korean',
  [Languages.es]: 'Spanish',
  [Languages.hi]: 'Hindi',
  [Languages.id]: 'Indonesian',
  [Languages.sw]: 'Swahili',
  [Languages.tr]: 'Turkish',
  [Languages.ja]: 'Japanese',
  [Languages.ur]: 'Urdu',
  [Languages.vi]: 'Vietnamese',
  [Languages.it]: 'Italian',
  [Languages.ptbr]: 'Brazilian Portuguese',
};

export const g = '026-adab-4';
