import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeedReducer } from './FeedReducer';
import { DiscoverListsId, IStore2 } from 'types';
import { getSelectedFeed } from 'store/post/helper';
import { ALL } from 'pages/feed/feedBubblesCarousel/helper';

const reducer = new FeedReducer();

export const getFeed = createAsyncThunk(
  'async/getFeed',
  async (
    input: {
      listId: DiscoverListsId;
      token?: string;
      userId?: string;
      selectedFeedBubble?: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { listId, token, userId, selectedFeedBubble } = input;

    let { paginationToken } = getSelectedFeed(state.feed);

    if (!paginationToken) {
      if (selectedFeedBubble && selectedFeedBubble !== ALL) {
        paginationToken = `1:${selectedFeedBubble}`;
      } else if (userId) {
        paginationToken = `1:${userId}`;
      }
    }

    return reducer.getPosts(listId, paginationToken, token);
  },
);

export const getStories = createAsyncThunk('async/loadStories', async (input: { token: string }) =>
  reducer.getStories(input.token),
);

export const getUserShops = createAsyncThunk('async/getUserShops', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token } = state.user;

  return reducer.getShops(token || '');
});

export const getUserProfile = createAsyncThunk(
  'async/getUserProfile',
  async (input: { userId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { userId } = input;

    return reducer.getUserProfile(userId, token || '');
  },
);

export const getShopProfile = createAsyncThunk(
  'async/getShopProfile',
  async (input: { shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { shopId } = input;

    return reducer.getShopProfile(shopId, token || '');
  },
);
