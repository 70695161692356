import crypto from 'crypto-js';

import { Method } from 'types';
import { BASE_URL, ROUTES } from 'constant';
import { detectMob } from 'utils';
import { StorageSrv } from 'services/StorageSrv';
import { a } from './aws';
import { d } from '../constant/colors';
import { g } from '../constant/languages';

const HASH_KEY = a + d + g + d;

export const signKey = (msg: string, nonce: string) =>
  crypto.HmacSHA256(msg + nonce, HASH_KEY).toString();

export const Fetch = (
  endpoint: string,
  method?: Method,
  dataBody?: any,
  authorization?: string,
  noStart?: boolean,
  headers?: any,
) =>
  fetch(noStart ? '' : `${BASE_URL}/${endpoint}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization || '', // "mode": "no-cors",
      ...headers,
    },
    method: method || Method.GET,
    body: JSON.stringify(dataBody),
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response.errors && !response.code) {
        return response;
      }
      throw JSON.stringify(response);
    })
    .catch((err) => {
      throw new Error(err);
    });

export const isRequireToken = (location?: string) => {
  const path = location || window.location.pathname.substring(1).toLocaleLowerCase();

  return !unStopPages.find((item) => path.startsWith(item));
};

export const shouldShowGetTheAppPage = () => {
  const path = window.location.pathname.substring(1).toLocaleLowerCase();

  const isMobile = detectMob();

  const isUnStoppablePage = !isRequireToken(path);

  if (isMobile && !isUnStoppablePage) {
    return StorageSrv.getTheAppPage.getPageStatus();
  }

  return false;
};

const unStopPages = [
  ROUTES.PRIVACY,
  ROUTES.APP_CN,
  ROUTES.TERMS,
  ROUTES.FINISH_SIGN_UP,
  ROUTES.POST,
  ROUTES.GET_THE_APP,
  ROUTES.APP,
  ROUTES.REDIRECT_URL,
  ROUTES.DISCOVER,
  ROUTES.SEARCH,
  ROUTES.PRODUCTS,
  ROUTES.ITEMS,
  `${ROUTES.PRODUCT}/`,
  `${ROUTES.SHOP}/`,
];

const isValidToken = (token: any) => {
  if (token && typeof token === 'string') {
    const firstDot = token.indexOf('.');

    if (firstDot !== -1) {
      const secondDot = token.substring(firstDot + 1).indexOf('.');
      if (secondDot !== -1) return true;
    }
  }

  return false;
};

export const saveTokenInStorage = (token: string, saveInLocale?: boolean) => {
  if (isValidToken(token)) {
    if (saveInLocale) {
      StorageSrv.token.setInLocal(token);
    } else {
      StorageSrv.token.setInSession(token);
    }
  }
};
