import { createSlice } from '@reduxjs/toolkit';

import { ISendVisionOfferState, IVisionOffer, ReturnPolicyType, VisionOffersTab } from 'types';
import { CurrencyCode } from 'constant/currencies';
import {
  getCurrencyCode,
  getRequestForOffer,
  getVisionOfferShippingPrice,
} from './SendVisionOfferActions';
import { onEditOffer } from './helper';
import { parseVisionOffer, parseVisionRequest } from '../apiParser';
import { getVisionOffer } from '../specificVisionRequest/SpecificVisionRequestActions';

const initialState: ISendVisionOfferState = {
  id: null,
  tariffNumber: '',
  comments: '',
  shippingProfileId: '',
  shippingPrice: 0,
  price: {
    value: 0,
    currencyCode: CurrencyCode.USD,
    currencySymbol: '$',
  },
  returnPolicy: ReturnPolicyType.ReturnAndExchange30,
  dimensions: {
    width: 0,
    length: 0,
    height: 0,
    weightSmall: 0,
    weightBig: 0,
    isMetric: true,
  },
  request: null,
  shouldShowShippingPriceError: false,
};

export const SendVisionOfferSlicer = createSlice({
  name: 'sendVisionOffer',
  initialState,
  reducers: {
    reset: () => initialState,
    onTariffNumberChange: (state, action) => {
      const { text } = action.payload;
      state.tariffNumber = text;
    },
    onPriceChange: (state, action) => {
      const { price } = action.payload;
      state.price.value = price;
    },
    onNoteChange: (state, action) => {
      const { text } = action.payload;
      state.comments = text;
    },
    onShippingProfileSelect: (state, action) => {
      const { id } = action.payload;
      state.shippingProfileId = id;
    },
    onMetricChange: (state, action) => {
      const { isMetric } = action.payload;
      state.dimensions.isMetric = isMetric;
    },
    onLengthChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.length = value;
    },
    onHeightChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.height = value;
    },
    onWidthChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.width = value;
    },
    onWeightBigChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.weightBig = value;
    },
    onWeightSmallChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.weightSmall = value;
    },
    onReturnPolicyChange: (state, action) => {
      const { type } = action.payload;
      state.returnPolicy = type;
    },
    onEditOffer: (_, action: { payload: { offer: IVisionOffer } }) =>
      onEditOffer(action.payload.offer),
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionOfferShippingPrice.rejected, (state) => {
      state.shouldShowShippingPriceError = true;
    });
    builder.addCase(getVisionOfferShippingPrice.fulfilled, (state, action) => {
      const { price } = action.payload;
      state.shippingPrice = price.price / 100;
    });
    builder.addCase(getRequestForOffer.fulfilled, (state, action) => {
      state.request = parseVisionRequest(action.payload);
    });
    builder.addCase(getCurrencyCode.fulfilled, (state, action) => {
      const { currencyCode, currency } = action.payload;
      state.price.currencyCode = currencyCode as CurrencyCode;
      state.price.currencySymbol = currency;
    });
    builder.addCase(getVisionOffer.fulfilled, (state, action) => {
      const offer = parseVisionOffer(action.payload, VisionOffersTab.Pending);
      state.dimensions = offer.dimensions;
      state.price = offer.price;
      state.shippingPrice = offer.shippingPrice.value;
      state.returnPolicy = offer.returnPolicy.type;
      state.comments = offer.sellerNote;
      state.shippingProfileId = offer.shippingProfileId;
      state.tariffNumber = offer.tariffNumber || '';
      state.id = offer.id;
    });
  },
});
