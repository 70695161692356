import { ReactNode } from 'react';

import {
  CreateShopSteps,
  DrawerIdsOptions,
  IProductShippingInformation,
  IShippingAddress,
  IShopState,
  ISimilarShop,
  IStoreDetails,
  IStories,
  ISuggestedShop,
  ITip,
  IBasketShippingRate,
  ShippingAddressesSteps,
  LikeProductSource,
  DiscoverListsId,
  IMojo,
  ICheckoutItem,
  IPrice,
  IAddToBasketDetails,
  BasketPageSources,
  ProductPageSources,
  IDiscountDetails,
} from 'types';
import { CurrencyCode } from 'constant/currencies';

export * from './content/home/activitiesSection/types';

export * from './content/reviews/types';

export * from 'components/emptyState/types';

export * from './content/home/types';

export interface IShopHeader {
  userName: string;
  image: string | null;
  shopName: string;
  rate: number;
  isPremium: boolean;
  isShopOwner: boolean;
  isReviewEnabled: boolean;
  location: string;
  totalReviews: number;
  originCountry: string;
  shippingCountries: string[];
  isFollowing: boolean;
  totalFollowers: number;
  totalFollowing: number;
  selectedTab: ShopTabsOptions;
  stories: IStories[];

  onFollowToggle(): void;

  onTabClick(index: string): void;

  onMessage(): void;

  onReviewClick(): void;

  onAddItemToCatalogClick(): void;

  onEditProfileClick(): void;

  onMoreClick(): void;

  editCoverPhoto(input: { coverPhoto: string }): void;

  editAvatar(input: { avatar: string }): void;

  onFollowersClick(): void;
}

export interface IShopInformation {
  categories: string[];
  description: string;
  isExpended: boolean;
}

export enum ContactOptions {
  Number,
  Email,
  Text,
  Website,
}

export enum ContactInformationTypeResponse {
  Email = '100',
  Phone = '0',
  Website = '1',
}

export interface IContactInformationOption {
  type: ContactOptions;
  text: string;
}

export interface IProduct {
  id: string;
  images: string[];
  title: string;
  description: string;
  isDigital: boolean;
  price: number;
  originalPrice: number;
  convertedFromPrice: IPrice | null;
  currencySymbol: string;
  currencyCode: CurrencyCode;
  hasInstantCheckout: boolean;
  shippingInformation: IProductShippingInformation | null;
  tariffNumber: string | null;
  quantity: number;
  category: string[] | null;
  isLiked: boolean;
  totalLikes: number;
  labels: { text: string; backgroundColor: string; textColor: string }[];
}

export interface ICatalogItemProps {
  index?: number;
  id: string;
  image: string;
  price: number | null;
  originalPrice: number | null;
  currencyCode: string | null;
  name: string | null;
  isDigital: boolean;
  isShopOwner: boolean;
  quantity: number | null;
  shippingInformation: IProductShippingInformation | null;
  totalLikes: number;
  isLiked: boolean;
  isSuperAdmin: boolean;
  isDraggable: boolean;
  onLikeToggle(): void;
  onAdminDeleteClick(input: { productId: string }): void;
  onEditClick(): void;
  onDuplicateClick(): void;
  onDeleteClick(): void;
  onClick(index: number): void;
}

export interface IShopPage {
  details: IStoreDetails | null;
  token?: string | null;
  loggedUserId: string | null;
  shop: IShopState;
  suggestedShops: ISuggestedShop[];
  tip: ITip | null;
  isSuperAdmin: boolean;
  reset(): void;
  resetUserFeed(): void;
  getUserMojos(input: { id: string }): void;
  getUserFeed(input: { listId: DiscoverListsId; token: string; userId: string }): void;
  editShop(input: { shop: IShopState; step: CreateShopSteps }): void;
  addItemToCatalog(input: { shopId: string }): void;
  editCoverPhoto(input: { coverPhoto: string }): void;
  editAvatar(input: { avatar: string }): void;
  getShopSuggestions(userId: string): void;
  onFollow(input: { userId: string; isFollowing: boolean }): void;
  getSpecificShop(input: { id: string }): void;
  getSpecificShopReviews(input: { id: string }): void;
  onFollowToggle(input: { userId: string; isFollowing: boolean; shopId: string }): void;
  showPopup(options: { id: string }): void;
  showDrawer(options: { id: DrawerIdsOptions }): void;
  adminMarkShop(input: { shopId: string }): void;
}

export enum ShopAuthorizationStatus {
  Pending,
  Approved,
  Rejected,
}

export const enum ShopTabsOptions {
  Home = '0',
  Catalog = '1',
  Mojos = '2',
  Activity = '3',
  Reviews = '4',
}

export enum Sections {
  Catalog,
  Activity,
  Reviews,
  ChatGroups,
}

export interface IShopSection {
  type: Sections;
  list: any[];
  tag?: ReactNode;
  className?: string;
  emptyStateButton?: ReactNode;
  button?: ReactNode;

  itemContent(item: any, index: number): ReactNode;

  onButtonClick(): void;
}

export interface ICatalogSection {
  shopId: string;
  list: IProduct[];
  hasNewItems: boolean;
  isShopOwner: boolean;
  onEmptyStateButtonClick(): void;
  onPreviewClick(index: number): void;
  onSeeAllCatalogClick(): void;
  onSaveItemClick(id: string, isLiked: boolean): void;
  onEditCatalogItemClick(id: string): void;
  onDuplicateCatalogItemClick(id: string): void;
  onDeleteCatalogItemClick(id: string): void;
}

export interface IMojosTab {
  mojos: IMojo[] | null;
  userId: string;
  hasMore: boolean;
  shopId: string;
  getUserMojos(input: { userId: string }): void;
  showPopup(id: { id: string; userId: string; index: number }): void;
}

export interface IShopCatalog {
  shopId: string;
  products: IProduct[] | null;
  isShopOwner: boolean;
  updateProductPageSource(input: { source: ProductPageSources }): void;
  onSaveToggle(input: { productId: string; isLiked: boolean; source: LikeProductSource }): void;
  reorderStateCatalog(input: { oldIndex: number; newIndex: number }): void;
  onProductMove(input: { productId: string; newIndex: number }): void;
  getCatalog(input: { shopId: string }): void;
  showPopup(options: { id: string; productId: string }): void;
  editListing(input: { id: string }): void;
  duplicateListing(input: { id: string; shopId: string }): void;
}

export interface ISimilarShopsSection {
  shops: ISimilarShop[];
  onShopClick(id: string): void;
  onSeeAllSimilarShops(): void;
  onFollowToggle(input: { userId: string; isFollowing: boolean }): void;
}

export interface IItemAddedToBasketPopup {
  item: IAddToBasketDetails | null;
  updateBasketSource(input: { source: BasketPageSources }): void;
  hidePopup(): void;
}

export interface IContent {
  selectedTab: ShopTabsOptions;
  isShopOwner: boolean;
  suggestedShops: ISuggestedShop[];
  tip: ITip | null;
  userId: string;
  vacationDetails: { text: string; date: number } | null;
  onActivitiesClick(): void;
  onReviewsClick(): void;
  onSeeAllCatalogClick(): void;
  onAddItemToCatalogClick(): void;
  onFollow(input: { userId: string; isFollowing: boolean }): void;
  getUserFeed(): void;
  getUserMojos(input: { userId: string }): void;
}

export interface IBasketPopup {
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  discountDetails: IDiscountDetails | null;
  shopId: string;
  shippingAddresses: IShippingAddress[] | null;
  basketItems: ICheckoutItem[];
  currencyCode: CurrencyCode;
  isLoading: boolean;
  isShippingLoading: boolean;
  isRatesLoading: boolean;
  orderIdToNavigate: string | null;
  basketShippingRates: IBasketShippingRate[] | null;
  defaultShippingAddress: IShippingAddress | null;
  getSupportedItems(input: { ids: string[] }): void;
  getBasketRates(input: { items: ICheckoutItem[] }): void;
  updateDefaultAddress(input: {
    shippingAddress: IShippingAddress;
    previousAddressId: string;
  }): void;
  onRequestOrder(input: {
    shippingAddress: IShippingAddress;
    items: ICheckoutItem[];
    selectedDeliveryId: string | null;
    couponCode: string | null;
  }): void;
  validateAddress(): void;
  submitAddressForm(input: { shippingAddress: IShippingAddress }): void;
  getShippingAddresses(): void;
  removeShippingAddress(input: { shippingId: string }): void;
  hidePopup(): void;
  onRemoveCouponCode(input: { shopId: string }): void;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  resetDiscountData(): void;
  onRemoveCouponCodeError(input: { shopId: string }): void;
}

export interface IBasketItemsContainer {
  currencyCode: CurrencyCode;
  items: ICheckoutItem[];
  rates: IBasketShippingRate[];
  selectedRateId: string | null;
  recipientCountry: string;
  isRatesLoading: boolean;
  selectedAddressId: string | null;
  selectedAddress: IShippingAddress | null;
  discountDetails: IDiscountDetails | null;
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  shopId: string;
  onMobileAddressClick(): void;
  onCountryChangeClick(): void;
  onRequestClick(): void;
  onRateClick(id: string): void;
  onRemoveCouponCode(): void;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  onRemoveCouponCodeError(): void;
}

export interface IBasketShippingContainer {
  shippingAddresses: IShippingAddress[];
  selectedAddressIndex: number;
  isLoading: boolean;
  step: ShippingAddressesSteps;
  addressToEditIndex: number;
  shippingRates: IBasketShippingRate[];
  onMobileBackClick(): void;
  onAddressToEditChange(index: number): void;
  onStepChange(step: ShippingAddressesSteps): void;
  onAddressClick(index: number): void;
  onSaveAddress(shippingAddress: IShippingAddress): void;
  onRemoveAddress(id: string): void;
}

export interface ISelectShippingAddressPopup {
  shippingAddresses: IShippingAddress[];
  step: ShippingAddressesSteps;
  selectedAddressIndex: number;
  isLoading: boolean;
  addressToEditIndex: number;
  onAddressToEditChange(index: number): void;
  onStepChange(step: ShippingAddressesSteps): void;
  onSaveAddressClick(shippingAddress: IShippingAddress): void;
  onAddressClick(index: number): void;
  onRemoveAddress(id: string): void;
}
