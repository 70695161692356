import {
  IGetPaypalCheckoutUrl,
  IPayoutApi,
  ILinkPayoutResponse,
  Method,
  IGetPaymentSessionDetailsResponse,
} from 'types';
import { fetcher } from 'api';
import {
  CheckoutCouponCodeResponse,
  CheckoutResponse,
  CreatePaymentIntentResult,
  PaypalOption,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  GET_VISION_STRIPE_PAYOUT_DETAILS_QUERY,
  GET_VISION_PAYPAL_LINK_QUERY,
  STRIPE_BASKET_CHECKOUT_DETAILS_MUTATION,
  GET_BASKET_CHECKOUT_DETAILS_QUERY,
  GET_BASKET_PAYPAL_LINK_QUERY,
  GET_BASKET_PAYMENTS_DETAILS_QUERY,
  CHECK_COUPON_CODE,
} from './PayoutQueries';

export class PayoutsApi implements IPayoutApi {
  getStripePayoutDetails(
    provider: string,
    orderId: string,
    token: string,
    couponCode: string | null,
  ): Promise<IGetPaymentSessionDetailsResponse> {
    const body = {
      provider,
      orderId,
      couponCode,
    };

    return fetcher(`payouts/checkout/mobile`, Method.POST, token, body);
  }

  async getVisionStripePayoutDetails(
    shippingRateId: string,
    offerId: string,
    token: string,
  ): Promise<CreatePaymentIntentResult> {
    const variables = {
      shippingRateId,
      offerId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GET_VISION_STRIPE_PAYOUT_DETAILS_QUERY,
      variables,
    });

    return res.data.createStripeCheckoutForVisionOffer;
  }

  async getBasketStripeCheckoutStripePayoutDetails(
    checkoutId: string,
    shippingRateId: string | null,
    couponCode: string | null,
    token: string,
  ): Promise<CreatePaymentIntentResult> {
    const variables = {
      checkoutId,
      shippingRateId,
      couponCode,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: STRIPE_BASKET_CHECKOUT_DETAILS_MUTATION,
      variables,
    });

    return res.data.createPaymentIntentStripe;
  }

  getStripePaymentConfirmation(paymentId: string, token: string): Promise<any> {
    const body = {
      paymentId,
    };

    return fetcher(`stripe/payment/confirm`, Method.POST, token, body);
  }

  getPaypalPaymentConfirmation(transactionId: string): Promise<any> {
    return fetcher(`paypal/capture/${transactionId}`, Method.POST);
  }

  getPaypalPayoutDetails(
    provider: string,
    orderId: string,
    token: string,
    couponCode: string | null,
  ): Promise<IGetPaypalCheckoutUrl> {
    const body = {
      provider,
      orderId,
      couponCode,
    };

    return fetcher(`payouts/checkout/mobile`, Method.POST, token, body);
  }

  async getVisionPaypalPayoutLink(
    offerId: string,
    shippingRateId: string | null,
    token: string,
  ): Promise<PaypalOption> {
    const variables = {
      offerId,
      shippingRateId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GET_VISION_PAYPAL_LINK_QUERY,
      variables,
    });

    return res.data.createPaypalCheckoutForVisionOffer;
  }

  async getBasketPaypalPayoutLink(
    checkoutId: string,
    shippingRateId: string | null,
    couponCode: string | null,
    token: string,
  ): Promise<PaypalOption> {
    const variables = {
      checkoutId,
      shippingRateId,
      couponCode,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GET_BASKET_PAYPAL_LINK_QUERY,
      variables,
    });

    return res.data.createPaypalCheckout;
  }

  onStripeLink(token: string): Promise<ILinkPayoutResponse> {
    return fetcher(`payouts/stripe/link`, Method.POST, token);
  }

  onPaypalLink(token: string): Promise<ILinkPayoutResponse> {
    return fetcher(`payouts/paypal/link`, Method.POST, token);
  }

  deactivateStripe(token: string): Promise<void> {
    return fetcher(`payouts/stripe/link`, Method.DELETE, token);
  }

  deactivatePaypal(token: string): Promise<void> {
    return fetcher(`payouts/paypal/link`, Method.DELETE, token);
  }

  refreshPaypal(merchantId: string, merchantIdInPayPal: string, id: string): Promise<void> {
    const body = { merchantId, merchantIdInPayPal, id };

    return fetcher(`paypal/v2/refresh`, Method.POST, undefined, body);
  }

  async getBasketCheckoutPayoutMethods(
    token: string,
    checkoutId: string,
  ): Promise<CheckoutResponse> {
    const variables = {
      checkoutId,
    };

    const res = await graphQlRequest(token).query({
      query: GET_BASKET_CHECKOUT_DETAILS_QUERY,
      variables,
    });

    return res.data.getInstantCheckoutForCheckoutId;
  }

  async getBasketCheckoutPaymentsDetails(
    token: string,
    checkoutId: string,
  ): Promise<CheckoutResponse> {
    const variables = {
      checkoutId,
    };

    const res = await graphQlRequest(token).query({
      query: GET_BASKET_PAYMENTS_DETAILS_QUERY,
      variables,
    });

    return res.data.getInstantCheckoutForCheckoutId;
  }

  async checkCoupon(
    token: string,
    couponCode: string,
    shopId: string,
    cartPrice: number,
  ): Promise<CheckoutCouponCodeResponse> {
    const variables = {
      couponCode,
      shopId,
      cartPrice,
    };

    const res = await graphQlRequest(token).query({
      query: CHECK_COUPON_CODE,
      variables,
    });

    return res.data.checkCouponCode;
  }
}
