import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  AvailablePayoutsResponse,
  CreateShopSteps,
  ICreateShopCatalogItem,
  ICreateShopState,
  PayoutMethods,
  ServerStatus,
} from 'types';
import { addContactDetailsToState, PAYMENT_METHODS_ORDER, updateDraft } from './helper';
import {
  addItemToCatalog,
  createShop,
  editSpecificListing,
  getMyShop,
  getProductCategories,
  getProductsFromCsv,
  getShopInformation,
} from './CreateShopActions';
import {
  parseCreateShopLegacyPaymentsResponse,
  parseCreateShopPayoutsResponse,
  parseCreateShopProductsResponse,
  addShouldDisplayWarningToItemsFromCsv,
} from '../apiParser';
import { isEmptyArray } from 'utils';
import { deactivatePayout, linkPayout } from '../payouts/PayoutsActions';
import { CurrencyCode } from 'constant/currencies';
import { LegacyPaymentMethods } from 'constant/paymentMethods';
import { Countries } from 'constant/countries';
import { UserSlicer } from '../user/UserActions';
import { StorageSrv } from 'services/StorageSrv';

export const ADD_EXTRA_IMAGES_INDEX = 14;

const initialState: ICreateShopState = {
  csvUploadInformation: {
    total: 0,
    totalSuccess: 0,
    uploadPercentage: null,
    productFromCsv: [],
    requestId: '',
  },
  step: CreateShopSteps.Initial,
  shopName: '',
  shopDescription: '',
  socialLinks: [''],
  country: Countries.UnitedStates,
  currencyCode: CurrencyCode.USD,
  phoneNumber: '',
  email: '',
  catalog: {
    items: [],
    currentItemIndex: 0,
  },
  initialCatalogOrder: [],
  addedProductsIds: [],
  deletedProductsIds: [],
  coverPhoto: '',
  legacyPayoutMethods: [],
  payoutMethods: [],
  isPaypalEnabled: false,
  isStripeEnabled: false,
  serverStatus: null,
  shopId: null,
  isFromOnboarding: false,
  isLegacyPayoutsAvailable: false,
  billingCountry: Countries.UnitedStates,
  payoutLinkToNavigate: null,
  isPaypalLinkLoading: false,
  isStripeLinkLoading: false,
  productCategories: [],
  draftDetails: null,
};

export const CreateShopSlicer = createSlice({
  name: 'createShop',
  initialState,
  reducers: {
    onPercentageChange: (state, action) => {
      state.csvUploadInformation.uploadPercentage = action.payload;
    },
    onContinue: (state) => {
      const isPayoutsEnabled = state.isPaypalEnabled || state.isStripeEnabled;

      if (state.step === CreateShopSteps.Catalog) {
        if (isPayoutsEnabled) {
          state.step = CreateShopSteps.Payouts;
        } else {
          state.step = CreateShopSteps.LegacyPayments;
        }
      } else if (
        (state.step === CreateShopSteps.ContactDetails && state.catalog.items.length > 0) ||
        state.step === CreateShopSteps.EditListing ||
        (state.step === CreateShopSteps.AddListing && state.catalog.items.length > 1) ||
        state.step === CreateShopSteps.FirstListingToast
      ) {
        state.step = CreateShopSteps.Catalog;
      } else {
        state.step += 1;
      }
    },
    onBack: (state) => {
      const isPayoutsEnabled = state.isPaypalEnabled || state.isStripeEnabled;

      if (state.step === CreateShopSteps.LegacyPayments) {
        if (isPayoutsEnabled) {
          state.step = CreateShopSteps.Payouts;
        } else {
          state.step = CreateShopSteps.Catalog;
        }
      } else if (state.step === CreateShopSteps.Catalog) {
        state.step = CreateShopSteps.ContactDetails;
      } else if (
        (state.catalog.items.length > 0 && state.step === CreateShopSteps.AddListing) ||
        state.step === CreateShopSteps.EditListing
      ) {
        state.step = CreateShopSteps.Catalog;
      } else if (state.step === CreateShopSteps.Payouts) {
        state.step = CreateShopSteps.Catalog;
      } else {
        state.step -= 1;
      }
    },
    reset: () => initialState,
    resetErrorMessage: (state) => {
      state.serverStatus = null;
    },
    navigateToLegacyPayoutsForm: (state) => {
      state.step = CreateShopSteps.LegacyPayments;
    },
    updateUploadingFromCsvDetails: (state, action) => {
      const { products, requestId } = action.payload;

      state.csvUploadInformation.productFromCsv = products;
      state.csvUploadInformation.requestId = requestId;
    },
    updateIsFromOnboarding: (state, action) => {
      const { isFromOnboarding } = action.payload;
      state.isFromOnboarding = isFromOnboarding;
    },
    navigateToPayoutsForm: (state) => {
      state.step = CreateShopSteps.Payouts;
    },
    navigateToShippoForm: (state) => {
      state.step = CreateShopSteps.Shippo;
    },
    navigateToAddListingForm: (state, action) => {
      const { shopId } = action.payload;
      state.step = CreateShopSteps.AddListing;
      state.shopId = shopId;
    },
    updateShopIdAndStep: (state, action) => {
      const { shopId, step } = action.payload;

      if (shopId) {
        state.shopId = shopId;
        state.step = step;
      }
    },
    navigateToEditListingForm: (state, _action) => {
      state.step = CreateShopSteps.EditListing;
    },
    navigateToDuplicateListingForm: (state, action) => {
      const { shopId } = action.payload;
      state.shopId = shopId;
      state.step = CreateShopSteps.AddListing;
    },

    editShop: (state, action) => {
      const { contacts, country, coverImage, description, id, name, currencyCode } =
        action.payload.shop.details;

      state.shopName = name;
      state.shopDescription = description;
      state.coverPhoto = coverImage;
      state.country = country;
      state.shopId = id;
      state.currencyCode = currencyCode;

      addContactDetailsToState(state, contacts);
    },
    updateShopName: (state, action) => {
      const { value } = action.payload;
      state.shopName = value;
    },
    updateShopDescription: (state, action) => {
      const { value } = action.payload;
      state.shopDescription = value;
    },
    updateCurrencyCode: (state, action) => {
      const { value } = action.payload;
      state.currencyCode = value;
      state.catalog.items.forEach((item) => {
        item.currency = value;
      });
    },
    updateCountry: (state, action) => {
      const { value } = action.payload;
      state.country = value;
      state.billingCountry = value;
    },
    addSocialLink: (state) => {
      state.socialLinks.push('');
    },
    removeSocialLink: (state, action) => {
      const { index } = action.payload;
      state.socialLinks.splice(index);
    },
    updateSocialLink: (state, action) => {
      const { index, value } = action.payload;
      state.socialLinks[index] = value;
    },
    updateEmail: (state, action) => {
      const { value } = action.payload;
      state.email = value;
    },
    updatePhoneNumber: (state, action) => {
      const { value } = action.payload;
      state.phoneNumber = value;
    },
    addListing: (state) => {
      state.step = CreateShopSteps.AddListing;
      state.catalog.currentItemIndex = state.catalog.items.length;
    },
    editListing: (state, action) => {
      const { index } = action.payload;
      state.catalog.currentItemIndex = index;
      state.step = CreateShopSteps.EditListing;
    },

    duplicateListing: (state, _action) => {
      state.catalog.currentItemIndex = state.catalog.items.length;
      state.step = CreateShopSteps.AddListing;
    },

    onSaveListingsFromCsv: (state, action: { payload: ICreateShopCatalogItem[] }) => {
      state.step = CreateShopSteps.Catalog;

      const itemsBeforeUploadImages = state.csvUploadInformation.productFromCsv;

      const itemsAfterUploadImages = action.payload;

      const newItems = addShouldDisplayWarningToItemsFromCsv(
        itemsAfterUploadImages,
        itemsBeforeUploadImages,
      );

      const ids = newItems.map((item) => item.id);
      state.addedProductsIds.push(...ids);
      state.catalog.items.unshift(...newItems);
      state.csvUploadInformation.totalSuccess = newItems.length;
    },

    onSaveListing: (state, action) => {
      const { item, userId } = action.payload;

      const randomId = Math.random().toString();
      state.addedProductsIds.push(randomId);

      const { items, currentItemIndex } = state.catalog;

      if (items[currentItemIndex]) {
        items[currentItemIndex] = item;
      } else {
        items.push({ ...item, id: randomId });
      }

      if (userId) {
        StorageSrv.draft.set(state, userId);
      }
    },
    reorderCatalog: (state, action) => {
      const { oldIndex, newIndex: destinationIndex } = action.payload;
      let newIndex = destinationIndex;
      if (newIndex >= state.catalog.items.length) {
        newIndex = state.catalog.items.length - 1;
      }
      state.catalog.items.splice(newIndex, 0, state.catalog.items.splice(oldIndex, 1)[0]);
    },
    removeListingFromCatalog: (state, action) => {
      const { index } = action.payload;

      const deletedProductId = state.catalog.items[index].id;

      if (deletedProductId) {
        state.deletedProductsIds.push(deletedProductId);
      }
      state.catalog.items.splice(index, 1);
    },
    updateCoverPhoto: (state, action) => {
      const { coverPhoto } = action.payload;
      state.coverPhoto = coverPhoto;
    },
    updateLegacyPaymentMethods: (state, action) => {
      const { paymentMethods } = action.payload;
      state.legacyPayoutMethods = paymentMethods;
      if (state.legacyPayoutMethods.length === 1 && state.legacyPayoutMethods[0].link === '') {
        state.legacyPayoutMethods = [];
      }
    },
    addPaymentMethod: (state) => {
      if (
        isEmptyArray(state.legacyPayoutMethods) ||
        state.legacyPayoutMethods[state.legacyPayoutMethods.length - 1].link !== ''
      ) {
        const unusedMethod =
          PAYMENT_METHODS_ORDER.find(
            (option) => !state.legacyPayoutMethods.find((item) => item.type === option),
          ) || LegacyPaymentMethods.Paypal;
        state.legacyPayoutMethods = [
          ...state.legacyPayoutMethods,
          { link: '', type: unusedMethod },
        ];
      }
    },
    updateBillingCountry: (state, action) => {
      const { billingCountry } = action.payload;
      state.billingCountry = billingCountry;
    },
    resetPayoutLink: (state) => {
      state.payoutLinkToNavigate = null;
    },
    updateDraft: (_, action) => updateDraft(action.payload.draft),
    hideDraftFromFeed: (state, action) => {
      const { userId } = action.payload;

      if (state.draftDetails) {
        state.draftDetails.shouldDisplayInFeed = false;
        StorageSrv.draft.set(state, userId);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UserSlicer.actions.signOut, () => initialState);
    builder.addCase(createShop.fulfilled, (state, action) => {
      state.shopId = action.payload._id;
      state.serverStatus = ServerStatus.SUCCESS;
    });

    builder.addCase(getShopInformation.fulfilled, (state, action) => {
      const {
        products,
        payments,
        availablePayouts,
        legacyPayoutMethods,
        currencyCode,
        email,
        phoneNumber,
      } = action.payload;

      const parsedProducts = parseCreateShopProductsResponse(products || []);
      state.catalog.items = parsedProducts;
      state.payoutMethods = parseCreateShopPayoutsResponse(payments);
      state.legacyPayoutMethods = parseCreateShopLegacyPaymentsResponse(legacyPayoutMethods);
      state.isLegacyPayoutsAvailable = availablePayouts.includes(AvailablePayoutsResponse.Legacy);
      state.isPaypalEnabled = availablePayouts.includes(AvailablePayoutsResponse.Paypal);
      state.isStripeEnabled = availablePayouts.includes(AvailablePayoutsResponse.Stripe);
      state.currencyCode = currencyCode || state.currencyCode;
      state.email = email || '';
      state.phoneNumber = phoneNumber || '';
      state.initialCatalogOrder = parsedProducts.map((item) => item.id);
      if (state.step === CreateShopSteps.AddListing) {
        state.catalog.currentItemIndex = state.catalog.items.length;
      }
    });

    builder.addCase(linkPayout.pending, (state, action) => {
      const { payout } = action.meta.arg;

      if (payout === PayoutMethods.Paypal) {
        state.isPaypalLinkLoading = true;
      } else {
        state.isStripeLinkLoading = true;
      }
    });

    builder.addCase(linkPayout.fulfilled, (state, action) => {
      const { linkUrl } = action.payload;

      const { payout } = action.meta.arg;
      state.payoutLinkToNavigate = linkUrl;
      if (payout === PayoutMethods.Paypal) {
        state.isPaypalLinkLoading = false;
      } else {
        state.isStripeLinkLoading = false;
      }
    });

    builder.addCase(deactivatePayout.fulfilled, (state, action) => {
      const { payout } = action.meta.arg;
      state.payoutMethods = state.payoutMethods.filter((method) => method.type !== payout);
    });

    builder.addCase(getProductCategories.fulfilled, (state, action) => {
      const { categories } = action.payload;
      state.productCategories = categories;
    });
    builder.addCase(getMyShop.fulfilled, (state, action) => {
      const { name, description, coverPhoto, foundedCountry, currencyCode, _id } =
        action.payload.shop;

      state.shopName = name;
      state.shopDescription = description;
      state.shopId = _id;
      state.currencyCode = currencyCode;
      state.coverPhoto = coverPhoto || '';
      state.country = foundedCountry as Countries;
    });

    builder.addCase(getProductsFromCsv.pending, (state) => {
      state.csvUploadInformation.uploadPercentage = 1;
    });
    builder.addCase(getProductsFromCsv.rejected, (state) => {
      state.csvUploadInformation.uploadPercentage = 100;
    });

    builder.addCase(getProductsFromCsv.fulfilled, (state, action) => {
      const { products } = action.payload;
      state.csvUploadInformation.total = products.length;
    });

    builder.addMatcher(
      isAnyOf(createShop.pending, addItemToCatalog.pending, editSpecificListing.pending),
      (state) => {
        state.serverStatus = ServerStatus.PENDING;
      },
    );

    builder.addMatcher(
      isAnyOf(addItemToCatalog.fulfilled, editSpecificListing.fulfilled),
      (state) => {
        state.serverStatus = ServerStatus.SUCCESS;
      },
    );

    builder.addMatcher(
      isAnyOf(createShop.rejected, addItemToCatalog.rejected, editSpecificListing.rejected),
      (state) => {
        state.serverStatus = ServerStatus.ERROR;
      },
    );
  },
});
