export interface IUserPage {
  selectedPageOption: UserPageOptions;
  currentShopUserId: string | undefined;
  getUserProfile(input: { userId: string }): void;
}

export enum UserPageOptions {
  Profile,
  Shop,
  SearchResults,
  None,
}
