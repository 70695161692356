import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { VirtuosoHandle } from 'react-virtuoso';

import { List } from 'components/list';
import {
  getUserProfile as loadUserProfile,
  getUserShops as loadUserShops,
} from 'store/feed/FeedActions';
import { UserSelector } from 'store/user/UserSelector';
import { FeedSelector } from 'store/feed/FeedSelectors';
import PostWrapper from './PostWrapper';
import { getElementById } from 'utils';
import CreatePost from './createPost/CreatePost';
import './posts.scss';
import { ROUTES } from 'constant';
import LoadingPhoto from 'photos/create-post-loader.png';
import { Image } from 'components/helpers';
import MojoWrapper from './MojoWrapper';
import { FeedTypes, IPosts, IStore2, SignPageOptions } from 'types';
import PostSkeleton from '../../components/skeletons/postSkeleton/PostSkeleton';
import { useRequireToken } from 'hooks/useRequireToken';
import CreateAnAccountBox from 'components/createAnAccountBox/CreateAnAccountBox';
import { UserSlicer } from 'store/user/UserActions';
import { useSaveLocation } from 'hooks/useSaveLocation';
import FeedBubblesCarousel from './feedBubblesCarousel/FeedBubblesCarousel';
import { FeedSlicer } from 'store/feed/FeedSlicer';

const SKELETON_LENGTH = 3;

const Posts: FC<IPosts> = ({
  emptyState = null,
  mojoSource,
  shouldHideFollowButton,
  isCreatePostLoading,
  selectedChatId,
  getFeed,
  feedType,
  hasMore,
  selectedFeedBubble,
  postIndexToScroll,
  loggedInUserShopId,
  posts,
  token,
  feedBubbles,
  scrollerId,
  loggedInUserId,
  getUserShops,
  isLoading,
  getUserProfile,
  setSignPhase,
  onFeedBubbleSelected,
}) => {
  const ref = useRef<VirtuosoHandle | null>(null);

  const [isCreatePostAnimationDisplayed, setIsCreatePostAnimationDisplayed] = useState(false);

  const list = useMemo(() => {
    const parsedList = posts || [];

    if (isLoading) {
      return [...parsedList, ...new Array(SKELETON_LENGTH)];
    }

    return parsedList;
  }, [isLoading, posts]);

  const isSeller = !!loggedInUserShopId;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const params = useParams();

  const searchUserId = searchParams.get('user');

  const userId = searchUserId || params?.userId;

  const isProfilePage = !!params?.userId;

  const isChatPage = !!selectedChatId;

  const shopId = params?.shopId;

  const onLoadMore = useCallback(() => {
    if (token && !isLoading) {
      getFeed(selectedChatId || undefined);
    }
  }, [token, userId, shopId, isLoading]);

  const onSignUpClick = useSaveLocation(() => {
    setSignPhase(SignPageOptions.SignUp);
    navigate(`/${ROUTES.SIGN}`);
  });

  const onForgetPasswordClick = useSaveLocation(() => {
    setSignPhase(SignPageOptions.ForgetPassword);
    navigate(`/${ROUTES.SIGN}`);
  });

  useEffect(() => {
    if (postIndexToScroll !== 0) {
      ref.current?.scrollToIndex({ index: postIndexToScroll, align: 'start', behavior: 'auto' });
    }
  }, [userId, selectedChatId, shopId]);

  useEffect(() => {
    if (isCreatePostLoading) {
      setIsCreatePostAnimationDisplayed(true);
      setTimeout(() => {
        setIsCreatePostAnimationDisplayed(false);
      }, 1000);
    }
  }, [isCreatePostLoading]);

  useEffect(() => {
    if (token) {
      if (!userId && !shopId && !selectedChatId) {
        getUserShops();
      } else if ((shopId || isProfilePage || isChatPage) && !posts) {
        getFeed(selectedChatId || undefined);
      } else if (!isProfilePage && userId) {
        getUserProfile({ userId });
      }
    }
  }, [userId, shopId, selectedChatId, isProfilePage]);

  const totalCount = list?.length || 0;

  const itemContent = useCallback(
    (index: number) => {
      const item = list ? list[index] : null;

      if (!item) {
        return <PostSkeleton />;
      }

      if (item.mojoDetails) {
        return (
          <MojoWrapper
            index={index}
            token={token}
            shouldHideFollowButton={shouldHideFollowButton}
            post={item}
            mojoSource={mojoSource}
          />
        );
      }

      return (
        <PostWrapper
          shouldHideFollowButton={shouldHideFollowButton}
          shouldDisplayImages
          post={item}
          isClickable
          isSpecificPost={false}
          index={index}
        />
      );
    },
    [list],
  );

  const element = getElementById(scrollerId) || undefined;

  const onUserClick = useRequireToken(() => {
    if (loggedInUserShopId) {
      navigate(`/${ROUTES.SHOP}/${loggedInUserShopId}`);
    } else {
      navigate(`/${ROUTES.PROFILE}/${userId || loggedInUserId}`);
    }
  });

  const shouldDisplayCreatePost = !userId && !shopId;

  const shouldDisplayFeedBubbles = token && feedType === FeedTypes.Feed;

  return (
    <div className={classNames('posts-container', { 'with-create-post': shouldDisplayCreatePost })}>
      {shouldDisplayCreatePost && <CreatePost onUserClick={onUserClick} isSeller={isSeller} />}
      {isCreatePostAnimationDisplayed && (
        <div className="loader-container">
          <div className="loader">
            <div className="progress" />
            <Image src={LoadingPhoto} />
          </div>
        </div>
      )}
      {shouldDisplayFeedBubbles && (
        <FeedBubblesCarousel
          feedBubbles={feedBubbles}
          onFeedBubbleClick={onFeedBubbleSelected}
          selectedFeedBubble={selectedFeedBubble}
        />
      )}
      <div className="posts-list-container">
        <List
          scrollerContainer={element}
          hasMore={hasMore}
          totalCount={totalCount}
          data={list}
          emptyState={emptyState}
          loadMore={onLoadMore}
          itemContent={itemContent}
          ref={ref}
          overscan={1000}
        />
        {!token && posts && (
          <div className="non-sign-in-container">
            <CreateAnAccountBox
              onForgetPasswordClick={onForgetPasswordClick}
              onSignUpClick={onSignUpClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { hasMore, postIndexToScroll, isLoading, selectedFeedBubble } =
    FeedSelector.currentFeedSelector(state);

  const feedBubbles = FeedSelector.feedBubblesSelector(state);

  const posts = FeedSelector.listSelector(state);

  const token = UserSelector.tokenSelector(state);

  const loggedInUserId = UserSelector.loggedUserIdSelector(state);

  const loggedInUserShopId = UserSelector.loggedInUserShopIdSelector(state);

  const feedType = FeedSelector.selectedFeedTypeSelector(state);

  return {
    hasMore,
    posts,
    token,
    isLoading,
    postIndexToScroll,
    loggedInUserId,
    loggedInUserShopId,
    feedBubbles,
    selectedFeedBubble,
    feedType,
  };
};

const mapDispatchToProps = {
  getUserShops: loadUserShops,
  getUserProfile: loadUserProfile,
  setSignPhase: UserSlicer.actions.changeSignPhase,
  onFeedBubbleSelected: FeedSlicer.actions.onFeedBubbleSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
