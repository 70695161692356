import { Middleware } from 'redux';

import { PopupSlicer } from '../popup/PopupActions';
import {
  checkPassword,
  deleteAccount,
  setDefaultAddress,
  updateLanguage,
  updatePassword,
  updateUserCurrency,
  updateVacationMode,
} from './SettingsActions';
import { submitNewPassword } from '../forgetPassword/ForgetPasswordActions';
import { MessageSlicer } from '../message/MessageActions';
import { DiscoverListsId, IStore2, MessageIds } from 'types';
import { UserSlicer } from 'store/user/UserActions';
import { getFeed } from '../feed/FeedActions';
import { SpecificVisionSlicer } from '../specificVision/SpecificVisionSlicer';
import { getBasket } from '../basket/BasketActions';

export const settingsMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case updatePassword.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        dispatch(MessageSlicer.actions.show({ id: MessageIds.changePassword }));
        break;

      case updateVacationMode.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        dispatch(MessageSlicer.actions.show({ id: MessageIds.vacationModeSuccess }));
        break;

      case submitNewPassword.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        dispatch(MessageSlicer.actions.show({ id: MessageIds.changePassword }));
        break;

      case updateUserCurrency.fulfilled.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.changeCurrency }));

        const state: IStore2 = getState();

        const token = state.user.token || '';

        const options = {
          token,
          listId: DiscoverListsId.MainFeed,
        };
        dispatch(getFeed(options));
        dispatch(getBasket());
        break;

      case updateLanguage.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case checkPassword.fulfilled.type:
        const { reason, text } = action.meta.arg;
        dispatch(PopupSlicer.actions.hide());
        dispatch(deleteAccount({ reason, text }));
        break;

      case deleteAccount.fulfilled.type:
        dispatch(UserSlicer.actions.signOut());
        break;

      case setDefaultAddress.fulfilled.type:
        const { shippingAddress } = action.meta.arg;
        dispatch(SpecificVisionSlicer.actions.setSelectedShippingAddress({ shippingAddress }));
        break;

      default:
        break;
    }

    return next(action);
  };
