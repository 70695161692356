import {
  DiscountTypes,
  IGetShopInformationResponse,
  IListingState,
  ListingOption,
  ReturnPolicyType,
} from 'types';
import { parseCreateShopProductsResponse } from '../apiParser';
import { imageContainers } from '../createShop/helper';
import { CurrencyCode } from 'constant/currencies';
import { imagesToDuplicate } from 'pages/createShop/stepForms/listingForm/helper';

export const DEFAULT_LISTING = {
  listingIdToEdit: '',
  listingIdToDuplicate: '',
  listingOption: ListingOption.Create,
  id: '',
  images: imageContainers,
  category: [],
  keywords: [],
  quantity: null,
  isDigital: false,
  digitalFiles: [],
  title: '',
  description: '',
  price: 0,
  priceAfterDiscount: 0,
  saleAmount: 0,
  discountType: DiscountTypes.Fixed,
  currency: CurrencyCode.USD,
  tariffNumber: '',
  dimensions: { length: 0, width: 0, height: 0, weightBig: 0, weightSmall: 0, isMetric: false },
  shippingProfileId: null,
  returnPolicy: ReturnPolicyType.ReturnAndExchange30,
  shouldDisplayWarning: false,
};

export const resetForm = (data: { currency: CurrencyCode; shippingProfileId: string }) => {
  return {
    ...DEFAULT_LISTING,
    currency: data.currency,
    shippingProfileId: data.shippingProfileId,
  };
};

export const updateListingSlicer = (
  id: string,
  isDuplicate: boolean,
  data: IGetShopInformationResponse,
): IListingState => {
  const editId = isDuplicate ? '' : id;

  const duplicateId = isDuplicate ? id : '';

  const itemId = isDuplicate ? '' : id;

  const { products } = data;

  const items = parseCreateShopProductsResponse(products || []);

  const selectedItem = items.find((item) => item.id === id) || DEFAULT_LISTING;

  const title = isDuplicate ? `copy of ${selectedItem.title}` : selectedItem.title;

  const images = isDuplicate ? imagesToDuplicate(selectedItem.images) : selectedItem.images;

  return {
    ...selectedItem,
    title,
    images,
    listingOption: isDuplicate ? ListingOption.Duplicate : ListingOption.Edit,
    id: itemId,
    listingIdToEdit: editId,
    listingIdToDuplicate: duplicateId,
    shouldDisplayWarning: false,
  };
};
