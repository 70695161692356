import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2 } from 'types';
import { VisionsApi } from '../visions/VisionsApi';

const api = new VisionsApi();

export const getVisionOfferShippingPrice = createAsyncThunk(
  'async/getVisionOfferShippingPrice',
  async (input: { requestId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { requestId } = input;

    const { dimensions, shippingProfileId } = state.sendVisionOffer;

    return api.getShippingRate(token, dimensions, requestId, shippingProfileId);
  },
);

export const sendVisionOffer = createAsyncThunk(
  'async/sendVisionOffer',
  async (input: { requestId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { requestId } = input;

    const { price, tariffNumber, shippingProfileId, comments, returnPolicy, dimensions } =
      state.sendVisionOffer;

    return api.sendVisionOffer(
      token,
      requestId,
      price.value,
      tariffNumber,
      returnPolicy,
      shippingProfileId,
      comments,
      dimensions,
    );
  },
);

export const editVisionOffer = createAsyncThunk(
  'async/editVisionOffer',
  async (input: { offerId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { requestId = '' } = state.popup;

    const { offerId } = input;

    const { price, tariffNumber, shippingProfileId, comments, returnPolicy, dimensions } =
      state.sendVisionOffer;

    return api.editVisionOffer(
      token,
      offerId,
      requestId,
      price.value,
      tariffNumber,
      returnPolicy,
      shippingProfileId,
      comments,
      dimensions,
    );
  },
);

export const getRequestForOffer = createAsyncThunk(
  'async/getRequestForOffer',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { offerId = '' } = state.popup;

    return api.getRequestForOffer(token, offerId);
  },
);

export const getCurrencyCode = createAsyncThunk(
  'async/getCurrencyCode',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getCurrencyCode(token);
  },
);
