import {
  CreateShopSteps,
  IImageContainerTypes,
  MenuOptions,
  PayoutMethods,
  ReturnPolicyType,
  ShippingPriceOptions,
  ThirdPartyConnectionStatus,
  ShippingMenuOptions,
} from 'types';
import { translations } from 'translations/en';
import MainView from 'photos/createShop/imageMainView.png';
import SideView from 'photos/createShop/imageSideView.png';
import TopView from 'photos/createShop/imageTopView.png';
import UpsideView from 'photos/createShop/imageUpsideView.png';
import PayPalLogo from 'photos/paypal-logo.png';
import StripeLogo from 'photos/stripe-logo.png';
import PayPalPopupImage from 'photos/paypal-popup-image.png';
import StripePopupImage from 'photos/stripe-popup-image.png';
import { PAYPAL_SUPPORTED_COUNTRIES, STRIPE_SUPPORTED_COUNTRIES } from 'constant/paymentMethods';
import { StorageSrv } from 'services/StorageSrv';
import { closeWindow } from 'utils';
import { countries, Countries } from 'constant/countries';

const { PAYPAL_RULE_1, STRIPE_RULE_1, PAYOUT_RULE_2, PAYOUT_RULE_3 } =
  translations.CREATE_SHOP.PAYMENT_STEP;

export const mapStepToIndex = {
  [CreateShopSteps.Initial]: 1,
  [CreateShopSteps.ShopInfo]: 2,
  [CreateShopSteps.ContactDetails]: 3,
  [CreateShopSteps.AddListing]: 4,
  [CreateShopSteps.EditListing]: 4,
  [CreateShopSteps.FirstListingToast]: 4,
  [CreateShopSteps.Catalog]: 4,
  [CreateShopSteps.Payouts]: 5,
  [CreateShopSteps.LegacyPayments]: 5,
  [CreateShopSteps.UploadListingOptions]: 4,
};

export const mapStepToCreateShopTitle = {
  [CreateShopSteps.Initial]: translations.CREATE_SHOP.INITIAL_STEP.TITLE,
  [CreateShopSteps.ShopInfo]: translations.CREATE_SHOP.SHOP_INFO_STEP.TITLE,
  [CreateShopSteps.ContactDetails]: translations.CREATE_SHOP.CONTACT_DETAILS_STEP.TITLE,
  [CreateShopSteps.AddListing]: translations.CREATE_SHOP.LISTING_STEP.TITLE,
  [CreateShopSteps.EditListing]: translations.CREATE_SHOP.LISTING_STEP.EDIT_LISTING_TITLE,
  [CreateShopSteps.FirstListingToast]: translations.CREATE_SHOP.FIRST_LISTING_TOAST.TITLE,
  [CreateShopSteps.Catalog]: translations.CREATE_SHOP.COMPLETE_PROFILE_STEP.TITLE,
  [CreateShopSteps.Shippo]: translations.CREATE_SHOP.SHIPPO_STEP.TITLE,
  [CreateShopSteps.Payouts]: translations.CREATE_SHOP.PAYMENT_STEP.TITLE,
  [CreateShopSteps.LegacyPayments]: translations.CREATE_SHOP.LEGACY_PAYOUTS_STEP.TITLE,
};

export const mapStepToEditShopTitle = {
  [CreateShopSteps.Initial]: translations.CREATE_SHOP.INITIAL_STEP.EDIT_TITLE,
  [CreateShopSteps.ShopInfo]: translations.CREATE_SHOP.SHOP_INFO_STEP.TITLE,
  [CreateShopSteps.ContactDetails]: translations.CREATE_SHOP.CONTACT_DETAILS_STEP.TITLE,
  [CreateShopSteps.AddListing]: translations.CREATE_SHOP.LISTING_STEP.TITLE,
  [CreateShopSteps.EditListing]: translations.CREATE_SHOP.LISTING_STEP.EDIT_LISTING_TITLE,
  [CreateShopSteps.Catalog]: translations.CREATE_SHOP.COMPLETE_PROFILE_STEP.EDIT_TITLE,
  [CreateShopSteps.Shippo]: translations.CREATE_SHOP.SHIPPO_STEP.TITLE,
  [CreateShopSteps.Payouts]: translations.CREATE_SHOP.PAYMENT_STEP.TITLE,
  [CreateShopSteps.LegacyPayments]: translations.CREATE_SHOP.PAYMENT_STEP.TITLE,
};

export const mapStepToCreateShopDescription = {
  [CreateShopSteps.Initial]: translations.CREATE_SHOP.INITIAL_STEP.DESCRIPTION,
  [CreateShopSteps.ShopInfo]: translations.CREATE_SHOP.SHOP_INFO_STEP.DESCRIPTION,
  [CreateShopSteps.ContactDetails]: translations.CREATE_SHOP.CONTACT_DETAILS_STEP.DESCRIPTION,
  [CreateShopSteps.AddListing]: translations.CREATE_SHOP.LISTING_STEP.DESCRIPTION,
  [CreateShopSteps.EditListing]: translations.CREATE_SHOP.LISTING_STEP.EDIT_LISTING_DESCRIPTION,
  [CreateShopSteps.FirstListingToast]: translations.CREATE_SHOP.FIRST_LISTING_TOAST.DESCRIPTION,
  [CreateShopSteps.Catalog]: translations.CREATE_SHOP.COMPLETE_PROFILE_STEP.DESCRIPTION,
};

export const mapStepToEditShopDescription = {
  [CreateShopSteps.Initial]: translations.CREATE_SHOP.INITIAL_STEP.EDIT_DESCRIPTION,
  [CreateShopSteps.ShopInfo]: translations.CREATE_SHOP.SHOP_INFO_STEP.DESCRIPTION,
  [CreateShopSteps.ContactDetails]: translations.CREATE_SHOP.CONTACT_DETAILS_STEP.EDIT_DESCRIPTION,
  [CreateShopSteps.AddListing]: translations.CREATE_SHOP.LISTING_STEP.DESCRIPTION,
  [CreateShopSteps.EditListing]: translations.CREATE_SHOP.LISTING_STEP.EDIT_LISTING_DESCRIPTION,
  [CreateShopSteps.Catalog]: translations.CREATE_SHOP.COMPLETE_PROFILE_STEP.EDIT_DESCRIPTION,
};

export const mapImageContainerTypeToImage = {
  [IImageContainerTypes.Main]: MainView,
  [IImageContainerTypes.Side]: SideView,
  [IImageContainerTypes.Top]: TopView,
  [IImageContainerTypes.Upside]: UpsideView,
  [IImageContainerTypes.Extra]: '',
};

export const mapImageContainerTypeToText = {
  [IImageContainerTypes.Main]: 'Main view',
  [IImageContainerTypes.Side]: 'Side view',
  [IImageContainerTypes.Top]: 'Top view',
  [IImageContainerTypes.Upside]: 'Upside view',
  [IImageContainerTypes.Extra]: '',
};

export const mapStepToAmplitudeName = {
  [CreateShopSteps.Initial]: 'initial',
  [CreateShopSteps.ShopInfo]: 'shop info',
  [CreateShopSteps.ContactDetails]: 'contact details',
  [CreateShopSteps.AddListing]: 'add listing',
  [CreateShopSteps.Catalog]: 'catalog',
  [CreateShopSteps.Shippo]: 'shippo',
  [CreateShopSteps.Payouts]: 'payouts',
  [CreateShopSteps.LegacyPayments]: 'legacy payouts',
};

export const renderRightButtonText = (step: CreateShopSteps) => {
  let rightButtonText: string | undefined = translations.COMMON.CONTINUE;
  if (step === CreateShopSteps.LegacyPayments || step === CreateShopSteps.Payouts) {
    rightButtonText = translations.COMMON.PUBLISH;
  }
  if (step === CreateShopSteps.FirstListingToast) {
    rightButtonText = translations.CREATE_SHOP.COMPLETE_PROFILE_STEP.ADD_ANOTHER_ITEM;
  }
  if (step === CreateShopSteps.UploadListingOptions) {
    rightButtonText = undefined;
  }

  return rightButtonText;
};

export const mapPayoutTypeToTitle: { [key in PayoutMethods]: string } = {
  [PayoutMethods.Paypal]: 'PayPal',
  [PayoutMethods.Stripe]: 'Stripe',
};

export const mapPayoutTypeToIcon: { [key in PayoutMethods]: string } = {
  [PayoutMethods.Paypal]: PayPalLogo,
  [PayoutMethods.Stripe]: StripeLogo,
};

export const mapPayoutTypeToOnePayoutPopupImage: { [key in PayoutMethods]: string } = {
  [PayoutMethods.Paypal]: PayPalPopupImage,
  [PayoutMethods.Stripe]: StripePopupImage,
};

export const mapPayoutTypeToRules: { [key in PayoutMethods]: string[] } = {
  [PayoutMethods.Paypal]: [PAYPAL_RULE_1, PAYOUT_RULE_2, PAYOUT_RULE_3],
  [PayoutMethods.Stripe]: [STRIPE_RULE_1, PAYOUT_RULE_2, PAYOUT_RULE_3],
};

export const mapPayoutTypeToSupportedCountries: { [key in PayoutMethods]: Countries[] } = {
  [PayoutMethods.Stripe]: STRIPE_SUPPORTED_COUNTRIES,
  [PayoutMethods.Paypal]: PAYPAL_SUPPORTED_COUNTRIES,
};

export const returnPolicyOrder = [
  ReturnPolicyType.ReturnAndExchange30,
  ReturnPolicyType.ReturnAndExchange90,
  ReturnPolicyType.Return30,
  ReturnPolicyType.NoReturn,
];

export const mapReturnPolicyTypeToTitle = {
  [ReturnPolicyType.ReturnAndExchange30]: 'Returns and Exchanges · 30 Days',
  [ReturnPolicyType.ReturnAndExchange90]: 'Returns and Exchanges · 90 Days',
  [ReturnPolicyType.Return30]: 'Returns Only · 30 Days',
  [ReturnPolicyType.NoReturn]: 'No Returns or Exchanges',
};

export const mapReturnPolicyTypeToDescription = {
  [ReturnPolicyType.ReturnAndExchange30]:
    translations.CREATE_SHOP.LISTING_STEP.RETURN_POLICY_POPUP.RETURNS_AND_EXCHANGE_DESCRIPTION,
  [ReturnPolicyType.ReturnAndExchange90]:
    translations.CREATE_SHOP.LISTING_STEP.RETURN_POLICY_POPUP.RETURNS_AND_EXCHANGE_DESCRIPTION,
  [ReturnPolicyType.Return30]:
    translations.CREATE_SHOP.LISTING_STEP.RETURN_POLICY_POPUP.RETURNS_AND_EXCHANGE_DESCRIPTION,
  [ReturnPolicyType.NoReturn]:
    translations.CREATE_SHOP.LISTING_STEP.RETURN_POLICY_POPUP.NO_RETURNS_DESCRIPTION,
};

export const mapShippingPriceTypeToDropdownLabel = {
  [ShippingPriceOptions.CalculatedShipping]:
    translations.CREATE_SHOP.LISTING_STEP.ADD_SHIPPING_PROFILE_POPUP.CALCULATED_PRICE_TITLE,
  [ShippingPriceOptions.FixedPrice]:
    translations.CREATE_SHOP.LISTING_STEP.ADD_SHIPPING_PROFILE_POPUP.FIXED_PRICE_TITLE,
};

export const mapShippingPriceTypeToText = {
  [ShippingPriceOptions.CalculatedShipping]:
    translations.CREATE_SHOP.LISTING_STEP.ADD_SHIPPING_PROFILE_POPUP.CALCULATED_PRICE_DESCRIPTION,
  [ShippingPriceOptions.FixedPrice]:
    translations.CREATE_SHOP.LISTING_STEP.ADD_SHIPPING_PROFILE_POPUP.FIXED_PRICE_DESCRIPTION,
};

export const mapShippingPriceTypeToLabel = {
  [ShippingPriceOptions.CalculatedShipping]: 'Calculated price',
  [ShippingPriceOptions.FixedPrice]: 'Fixed price',
};

export const handlePayoutRedirectInNewTab = (
  status: ThirdPartyConnectionStatus,
  provider: PayoutMethods,
  orderId?: string,
) => {
  StorageSrv.payouts.triggerPayoutsListener({ status, provider, orderId });
  closeWindow();
};

export const shippoDropdownOption = [
  {
    key: ShippingMenuOptions.Deactivate,
    label: `Deactivate Shippo`,
    danger: true,
  },
];

export const shippingProfileOptions = [
  { key: MenuOptions.Edit, label: 'Edit details', danger: false },
  { key: MenuOptions.Duplicate, label: 'Duplicate', danger: false },
  { key: MenuOptions.Delete, label: 'Delete', danger: true },
];

export const DROPDOWN_COUNTRIES_OPTIONS = countries.map((country) => ({
  value: country.country,
  label: `${country.emojiFlag}  ${country.country}`,
}));
