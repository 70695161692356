import { popupIdsOptions, ProfileMenuOptions } from 'types';
import { ROUTES, TEDOOO_SUPPORT_ROUTE } from 'constant';

export const mapProfileMenuOptionToRoute: { [key in ProfileMenuOptions]: string | null } = {
  [ProfileMenuOptions.Help]: TEDOOO_SUPPORT_ROUTE,
  [ProfileMenuOptions.Following]: ROUTES.FOLLOWING,
  [ProfileMenuOptions.CreatePost]: ROUTES.HOME,
  [ProfileMenuOptions.Posts]: ROUTES.ACTIVITIES,
  [ProfileMenuOptions.ContactUs]: TEDOOO_SUPPORT_ROUTE,
  [ProfileMenuOptions.MyShops]: ROUTES.MY_SHOPS,
  [ProfileMenuOptions.Settings]: ROUTES.SETTINGS,
  [ProfileMenuOptions.CreateShop]: ROUTES.CREATE_SHOP,
  [ProfileMenuOptions.Offers]: ROUTES.ORDERS,
  [ProfileMenuOptions.AboutUs]: null,
  [ProfileMenuOptions.UpgradeToPro]: null,
  [ProfileMenuOptions.RenewPro]: null,
  [ProfileMenuOptions.Favorites]: ROUTES.PRODUCTS,
  [ProfileMenuOptions.Visions]: ROUTES.VISIONS,
  [ProfileMenuOptions.GetVisionsCredits]: null,
};

export const mapProfileOptionToPopupId = {
  [ProfileMenuOptions.GetVisionsCredits]: popupIdsOptions.buyVisionCreditsPopup,
  [ProfileMenuOptions.RenewPro]: popupIdsOptions.upgradeToProPopup,
  [ProfileMenuOptions.UpgradeToPro]: popupIdsOptions.upgradeToProPopup,
  [ProfileMenuOptions.AboutUs]: popupIdsOptions.getTheAppPopup,
  [ProfileMenuOptions.CreatePost]: popupIdsOptions.createPostPopup,
};
