import { Middleware } from 'redux';

import { CreateShopSlicer } from './CreateShopSlicer';
import { IStore2, PayoutMethods, ThirdPartyConnectionStatus } from 'types';
import { refreshPaypal } from '../payouts/PayoutsActions';
import { StorageSrv } from 'services/StorageSrv';
import { closeWindow, isEmptyArray } from 'utils';
import {
  createShop,
  getMyShop,
  getProductCsvProgress,
  getProductsFromCsv,
  getShopInformation,
} from './CreateShopActions';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import {
  filterItemsByUploadedImages,
  getCreateShopCatalogItemFromCsv,
  mapPaypalErrorCodeToAmplitudeMessage,
} from './helper';
import { ListingSlicer } from '../listing/ListingSlicer';
import { addShippingProfile } from '../shipping/ShippingActions';
import { refreshToken } from '../user/UserActions';
import { CurrencyCode } from 'constant/currencies';
import { getShop } from '../shop/ShopActions';

const UPLOADING_PROGRESS_INTERVAL = 1000;

export const createShopMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case getProductCsvProgress.fulfilled.type: {
        const state: IStore2 = getState();

        const { successUrls, progress } = action.payload;

        const { requestId, productFromCsv } = state.createShop.csvUploadInformation;

        if (progress < 100) {
          dispatch(CreateShopSlicer.actions.onPercentageChange(progress));
          setTimeout(() => {
            dispatch(getProductCsvProgress(requestId));
          }, UPLOADING_PROGRESS_INTERVAL);
        } else {
          const newItems = filterItemsByUploadedImages(productFromCsv, successUrls);
          dispatch(CreateShopSlicer.actions.onSaveListingsFromCsv(newItems));
          dispatch(CreateShopSlicer.actions.onPercentageChange(100));
        }

        break;
      }

      case getProductsFromCsv.fulfilled.type: {
        const state: IStore2 = getState();

        const { requestId, products } = action.payload;

        const createShopProducts = getCreateShopCatalogItemFromCsv(
          products,
          state.createShop.catalog.items,
        );

        dispatch(
          CreateShopSlicer.actions.updateUploadingFromCsvDetails({
            products: createShopProducts,
            requestId,
          }),
        );
        dispatch(getProductCsvProgress(requestId));

        break;
      }
      case refreshPaypal.rejected.type:
        const { errorCode } = action.payload.message;
        StorageSrv.payouts.triggerPayoutsListener({
          status: ThirdPartyConnectionStatus.Error,
          provider: PayoutMethods.Paypal,
          errorCode,
        });
        AnalyticsEvents.onPaypalConnectionFail(
          mapPaypalErrorCodeToAmplitudeMessage[errorCode] || 'other',
        );
        // close the connecting payments window after submitting
        closeWindow();
        break;

      case refreshPaypal.fulfilled.type:
        StorageSrv.payouts.triggerPayoutsListener({
          status: ThirdPartyConnectionStatus.Success,
          provider: PayoutMethods.Paypal,
        });
        dispatch(getShopInformation());
        // close the connecting payments window after submitting
        closeWindow();
        break;

      case CreateShopSlicer.actions.updateShopIdAndStep.type: {
        dispatch(getShopInformation());
        dispatch(getMyShop());
        break;
      }
      case CreateShopSlicer.actions.onSaveListing.type:
      case CreateShopSlicer.actions.addListing.type: {
        const state: IStore2 = getState();

        const { shippingProfiles } = state.shipping;

        const { items } = state.createShop.catalog;
        let shippingId = '';
        if (!isEmptyArray(shippingProfiles)) {
          shippingId = shippingProfiles[0].id;
        }
        if (!isEmptyArray(items)) {
          shippingId = items[0].shippingProfileId || '';
        }

        const { currencyCode } = state.createShop;

        dispatch(
          ListingSlicer.actions.resetForm({
            currency: currencyCode,
            shippingProfileId: shippingId,
          }),
        );
        break;
      }

      case CreateShopSlicer.actions.navigateToEditListingForm.type: {
        const { id } = action.payload;
        dispatch(ListingSlicer.actions.setListingToEditId({ id }));
        break;
      }
      case CreateShopSlicer.actions.navigateToDuplicateListingForm.type: {
        const { id } = action.payload;
        dispatch(ListingSlicer.actions.setListingToDuplicateId({ id }));
        break;
      }

      case CreateShopSlicer.actions.editListing.type: {
        const state: IStore2 = getState();

        const { index } = action.payload;

        const item = state.createShop.catalog.items[index];
        dispatch(ListingSlicer.actions.editListing({ item }));
        break;
      }

      case CreateShopSlicer.actions.duplicateListing.type: {
        const state: IStore2 = getState();

        const { index } = action.payload;

        const item = state.createShop.catalog.items[index];
        dispatch(ListingSlicer.actions.duplicateListing({ item }));
        break;
      }

      case addShippingProfile.fulfilled.type: {
        const { id } = action.payload;

        const state: IStore2 = getState();

        const { shippingProfiles } = state.shipping;

        if (isEmptyArray(shippingProfiles)) {
          dispatch(ListingSlicer.actions.updateShippingProfile({ id }));
        }
        break;
      }
      case CreateShopSlicer.actions.reset.type:
        dispatch(
          ListingSlicer.actions.resetForm({ currency: CurrencyCode.USD, shippingProfileId: null }),
        );
        break;

      case getShopInformation.fulfilled.type: {
        const state: IStore2 = getState();

        const { currencyCode, shippingProfiles } = action.payload;

        const { items } = state.createShop.catalog;

        const { listingIdToEdit: listingId, listingIdToDuplicate } = state.listing;

        if (!listingId && !listingIdToDuplicate) {
          let shippingId: string | null = null;
          if (!isEmptyArray(shippingProfiles)) {
            shippingId = shippingProfiles[0].id;
          }
          if (!isEmptyArray(items)) {
            shippingId = items[0].shippingProfileId || null;
          }
          dispatch(
            ListingSlicer.actions.resetForm({
              currency: currencyCode,
              shippingProfileId: shippingId,
            }),
          );
        }
        break;
      }
      case createShop.fulfilled.type: {
        const state: IStore2 = getState();

        const { shopId } = state.createShop;

        if (!shopId) {
          dispatch(refreshToken());
        }

        if (shopId) {
          dispatch(getShop({ id: shopId }));
        }

        break;
      }

      default:
        break;
    }

    return next(action);
  };
