import {
  ServerStatus,
  NotificationsTypes,
  EmailNotificationsTypes,
  PayoutMethods,
  PremiumPlanOptions,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';
import { GetVacationDetailsResponse, SuccessResponse } from '../../graphql/generated/graphql';

export enum Languages {
  en = 'en',
  zhhans = 'zh-Hans',
  ar = 'ar',
  si = 'si',
  th = 'th',
  ko = 'ko',
  es = 'es',
  hi = 'hi',
  id = 'id',
  sw = 'sw',
  tr = 'tr',
  ja = 'ja',
  ur = 'ur',
  vi = 'vi',
  it = 'it',
  ptbr = 'pt-br',
}

export interface IBlockedUserState {
  avatar: string | null;
  name: string;
  userName: string;
  id: string;
}

export interface IAccountPreferences {
  isLiveTranslationEnabled: boolean;
  isLocalTimeEnabled: boolean;
  isLastSeenEnabled: boolean;
  language: string | null;
  email: string | null;
  birthday: number | null;
  defaultAddress: IShippingAddress | null;
  currencyCode: CurrencyCode;
  premiumPlan: PremiumPlanOptions | null;
}

export interface ISettingsState {
  blockedUsers: IBlockedUserState[] | null;
  accountPreferences: IAccountPreferences | null;
  pushNotifications: { [key in NotificationsTypes]: boolean } | null;
  emailNotifications: { [key in EmailNotificationsTypes]: boolean } | null;
  passwordServerStatus: ServerStatus | null;
  usernameServerErrorMessage: string | null;
  shippingAddresses: IShippingAddress[] | null;
  deleteAccountErrorMessage: string | null;
  currencyServerStatus: ServerStatus | null;
  payouts: PayoutMethods[] | null;
  vacationModeDetails: IVacationModeDetails | null;
  isLoading: boolean;
}

export interface IVacationModeDetails {
  shopMessage: string;
  autoReplyMessage: string;
}

export interface IPostNotificationsResponse {
  push: {
    posts: boolean;
    orderUpdates: boolean;
    groupMessages: boolean;
    privateMessages: boolean;
  };
  email: {
    orderUpdates: boolean;
    privateMessages: boolean;
  };
}

export interface IAccountPreferenceResponse {
  lastSeen: boolean;
  localTime: boolean;
  liveTranslations: boolean;
  language: string | null;
  email: string | null;
  birthdate: number | null;
  defaultShippingAddress: IShippingAddressResponse | null;
  currencyCode: CurrencyCode;
  premiumPlan: PremiumPlanOptions | null;
}

export interface IShippingAddressResponse {
  id: string;
  country: Countries;
  state?: string;
  fullName: string;
  streetAddress: string;
  other: string | null;
  city: string;
  postalCode?: string;
  phoneNumber?: string;
  default: boolean | null;
}

export interface IGetShippingAddressesResponse {
  data: IShippingAddressResponse[];
}

export interface IBlockedUsersResponse {
  _id: string;
  username: string;
  avatar: string | null;
  fullName: string;
}

export interface ISettingsApi {
  updateSettings(token: string, body: IUpdateSettingsRequest): Promise<void>;
  updateLanguage(token: string, body: { lang: string }): Promise<void>;
  getPostNotifications(token: string | undefined): Promise<IPostNotificationsResponse>;
  updateVacationMode(token: string, body: IVacationModeDetails | null): Promise<SuccessResponse>;
  getVacationModeDetails(token: string): Promise<GetVacationDetailsResponse>;
  updatePostNotifications(
    type: NotificationsTypes,
    push: boolean,
    email: boolean | null,
    token: string,
  ): Promise<void>;
  getAccountPreferences(token: string): Promise<IAccountPreferenceResponse>;
  getBlockedUsers(token: string): Promise<IBlockedUsersResponse[]>;
  unBlockUser(token: string, userId: string): Promise<void>;
  updatePassword(token: string, body: { oldPassword: string; password: string }): Promise<void>;
  updateUserDetails(
    token: string,
    fullName?: string,
    username?: string,
    email?: string,
    birthday?: number,
  ): Promise<void>;
  deleteShippingAddress(shippingId: string, token: string): Promise<void>;
  getShippingAddresses(token?: string): Promise<IGetShippingAddressesResponse>;
  checkPassword(userName: string, password: string): Promise<void>;
  updateUserCurrency(token: string, currencyCode: string): Promise<void>;
  deleteAccount(token: string, reason: string, text: string | null): Promise<void>;
  addShippingAddress(shippingAddress: IShippingAddress, token: string): Promise<{ id: string }>;
  editShippingAddress(shippingAddress: IShippingAddress, token: string): Promise<void>;
  setDefaultAddress(id: string, token: string): Promise<void>;
}

export interface IUpdateSettingsRequest {
  postNotifications?: boolean;
  liveTranslation?: boolean;
  localTime?: boolean;
  lastSeen?: boolean;
}

export interface IShippingAddress {
  id: string | null;
  country: Countries;
  state: string | null;
  email: string;
  fullName: string;
  streetAddress: string;
  apartmentNumber: string | null;
  city: string;
  postalCode: string;
  phoneNumber: string;
  isDefault: boolean;
}

export interface IShippingAddressState extends IShippingAddress {
  isLoading: boolean;
}
