import { Middleware } from 'redux';

import {
  getPaypalPaymentConfirmation,
  getStripePaymentConfirmation,
  getStripeVisionPaymentConfirmation,
} from './PayoutsActions';
import { PopupSlicer } from '../popup/PopupActions';
import { IStore2, PayoutMethods, popupIdsOptions, ThirdPartyConnectionStatus } from 'types';
import { handlePayoutRedirectInNewTab } from 'pages/createShop/helper';
import { PayoutsSlicer } from './PayoutsSlicer';
import { PopupsSrv } from '../../services/PopupsSrv';

export const payoutsMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case getStripeVisionPaymentConfirmation.fulfilled.type:
      case getStripePaymentConfirmation.fulfilled.type: {
        dispatch(PopupSlicer.actions.hide());

        const { orderId } = action.payload;

        dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.paymentSuccessPopup, orderId }));
        break;
      }

      case getStripePaymentConfirmation.rejected.type: {
        const { orderId } = action.meta.arg;
        dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.paymentFailedPopup, orderId }));
        dispatch(PayoutsSlicer.actions.reset());
        break;
      }
      case getPaypalPaymentConfirmation.fulfilled.type: {
        const { orderId } = action.payload;
        handlePayoutRedirectInNewTab(
          ThirdPartyConnectionStatus.Success,
          PayoutMethods.Paypal,
          orderId,
        );
        break;
      }

      case PopupSlicer.actions.hide.type:
        const state: IStore2 = getState();

        const { ids: displayedPopups } = state.popup;

        const isPaymentPopupDisplayed =
          PopupsSrv.checkIfDisplayed(popupIdsOptions.payOrderPopup, displayedPopups) ||
          PopupsSrv.checkIfDisplayed(popupIdsOptions.payBasketPopup, displayedPopups) ||
          PopupsSrv.checkIfDisplayed(popupIdsOptions.payVisionOfferPopup, displayedPopups);

        if (isPaymentPopupDisplayed && displayedPopups.length === 1) {
          dispatch(PayoutsSlicer.actions.reset());
        }
        break;

      default:
        break;
    }

    return next(action);
  };
