import {
  BasketRateAttributes,
  ContactInformationTypeResponse,
  IProduct,
  IContactInformationOption,
  IFeedResponseApi,
  IGetShopInformationResponse,
  IGroupRow,
  IPrice,
  IProductShippingInformationResponse,
  IReviewRow,
  IShopActivity,
  ITotalRateItem,
  IMojo,
  IPriceResponse,
  PayoutMethods,
} from 'types';
import { CurrencyCode } from 'constant/currencies';

export interface IShopDetails {
  address: string;
  avg: number;
  category: Array<string>;
  city: string;
  description: string;
  fav: number;
  foundedCountry: string;
  hasStories: boolean;
  images: Array<string>;
  keywords: Array<string>;
  visitedMoments: boolean;
  lat: number;
  lon: number;
  map: string;
  name: string;
  coverPhoto?: string;
  online: boolean;
  premium: boolean;
  shopNumber: string;
  steps: Array<number>;
  totalReviews: number;
  type: number;
  id: string;
}

export interface IShopsResponseApi {
  countries: Array<{ _id: string; country: string }>;
  data: Array<IShopDetails>;
  total: number;
}

export enum ServerStatus {
  ERROR,
  SUCCESS,
  PENDING,
}

export interface IStoryUser {
  _id: string;
  username: string;
  avatar?: string;
}

export interface IStoryDetails {
  time: number;
  url: string;
  userId: string;
  visited: boolean;
  _id: string;
}

export interface IShopResponseApi {
  address: string;
  avg: number;
  category: Array<string>;
  city: string;
  description: string;
  fav: number;
  foundedCountry: string;
  hasStories: boolean;
  img_links: Array<string>;
  keywords: Array<string>;
  lastMoment: number;
  lat: number;
  lon: number;
  map: string;
  name: string;
  online: boolean;
  premium: boolean;
  shop_number: string;
  totalReviews: number;
  type: number;
  visitedMoments: boolean;
  _id: string;
  contact_info: Array<{ number: string; type: ContactInformationTypeResponse }>;
  countries?: string[];
  payment?: string[];
  shippingType?: string[];
  userId: string;
  stories: Array<IStoryDetails>;
  market_name: string;
  minQuantity: number;
  coverPhoto?: string;
  favs: number;
  totalFollowing: number;
  userId2: Array<string>;
  userReview?: IUserReviewApi;
  currencyCode: CurrencyCode;
  userDetails: Array<{ avatar: string; username: string; _id: string; fullName: string }>;
  marked?: boolean;
}

export interface ISimilarShop {
  avatar: string;
  name: string;
  location: string;
  userId: string;
  rate: number;
  totalReviews: number;
  username: string;
  category: string;
  isPremium: boolean;
  images: string[];
  id: string;
  isFollowing: boolean;
}

export interface IShopActivityResponseApi {
  id: string;
  image?: string;
  text: string;
  date: number;
  repostId?: string;
}

export interface IShopProductResponseApi {
  currency: string;
  currencyCode?: CurrencyCode;
  description?: string;
  imageUrl: string;
  variants?: string[];
  convertedFrom: IPriceResponse;
  price: number;
  originalPrice: number;
  id: string;
  title?: string;
  instructions?: string;
  didLike: boolean;
  totalLikes: number;
  shippingInformation?: IProductShippingInformationResponse;
  hasInstantCheckoutV2?: boolean;
  selectedCategory?: string[];
  keywords?: string[];
  tariff?: string;
  isDigital?: boolean;
  quantity?: number;
  labels?: { texts: ITextLabel[]; backgroundColor: string; textColor: string }[];
}

export interface ITextLabel {
  text: string;
  style: string;
}

export interface ISimilarShopResponse {
  id: string;
  userId: string;
  city?: string;
  avatar?: string;
  country: string;
  category: string;
  following: boolean;
  images: string[];
  username: string;
  premium: boolean;
  name: string;
  rating: number;
  totalReviews: number;
}

export interface IStoreDetailsApi {
  shop: IShopResponseApi;
  posts: IShopActivityResponseApi[];
  reviews: IShopReviewResponse[];
  products: IShopProductResponseApi[];
  groups: IGroupsResponse[];
  similarShops: ISimilarShopResponse[];
  authorizationStatus: string | null;
  reviewEnabled: boolean;
  hasNewItems: boolean;
  profileViews: number;
  payments: { provider: PayoutMethods; link: string }[];
  totalLikes?: number;
  vacationInfo?: { text: string; date: number };
}

export interface IGroupsResponse {
  id: string;
  description: string;
  image?: string;
  name: string;
  totalParticipants: number;
}

export interface IUserReviewApi {
  creationDate: number;
  images: Array<string>;
  shop: string;
  star: number;
  text: string;
  title: string;
  userId: string;
  _id: string;
}

export interface IUserReview {
  date: number;
  images: Array<string>;
  shopId: string;
  rate: number;
  text: string;
  title: string;
  userId: string;
  id: string;
}

export interface IStories {
  avatar: string | null;
  username: string;
  id: string;
  stories: any[];
}

export interface IStoreDetails {
  category: string;
  categories: string[];
  city: string;
  description: string;
  isFollowing: boolean;
  country: string;
  coverImage: string | null;
  averageRating: number;
  userId: string;
  address: string;
  keywords: string[];
  mapImage: string;
  totalFollowers: number;
  totalFollowing: number;
  name: string;
  shop_number: string;
  marketName: string;
  userReview?: IUserReview;
  isPremium: boolean;
  id: string;
  contacts: IContactInformationOption[];
  reviews: IReviewRow[];
  totalReviews: number;
  countries: string[];
  shippingType: string[];
  stories: IStories[];
  ownersIds: Array<string>;
  userDetails: IShopUserDetails;
  catalog: IProduct[];
  activities: IShopActivity[];
  similarShops: ISimilarShop[];
  authorizationStatus: number | null;
  hasNewItems: boolean;
  groups: IGroupRow[];
  totalProfileViews: number;
  currencyCode: CurrencyCode;
  totalSavedProducts: number;
  connectedPayouts: PayoutMethods[] | null;
  vacationDetails: { text: string; date: number } | null;
  isMarkedAsCrystalShop: boolean;
  isReviewEnabled: boolean;
}

export interface IShopUserDetails {
  avatar: string;
  username: string;
  id: string;
  fullName: string;
}

export interface IShopReviewsState {
  list: IReviewRow[];
  totalRates: ITotalRateItem[];
  hasMore: boolean;
}

export interface IShopState {
  isLoading: boolean;
  id: string;
  details: IStoreDetails | null;
  reviews: IShopReviewsState | null;
  fullCatalog: IProduct[] | null;
  mojos: { list: IMojo[] | null; paginationToken: string | null } | null;
  followers: IFollower[];
  basketShippingRates: IBasketShippingRate[] | null;
  isRatesLoading: boolean;
  totalSavedProducts: number;
}

export interface IBasketShippingRate {
  id: string;
  image: string | null;
  name: string;
  attributes: BasketRateAttributes[];
  price: IPrice;
  deliveryDate: number;
  isSelected: boolean;
}

export interface IShopReviewResponse {
  _id: string;
  avatar?: string;
  userId: string;
  username: string;
  stars: number;
  title?: string;
  description?: string;
  date: number;
  images: string[];
}

export interface IShopReviewsResponse {
  reviews: IShopReviewResponse[];
  hasMore: boolean;
}

export interface IGetProductResponse {
  item: IShopProductResponseApi;
  shopId: string;
  userId: string;
}

export interface IInitialShopReviewResponse extends IShopReviewsResponse {
  totals: number[];
  selfReview?: IShopReviewResponse;
}

export interface IGetFollowersResponse {
  data: {
    avatar: string;
    id: string;
    name: string;
    time: string;
    username: string;
    following: boolean;
  }[];
  hasMore: boolean;
  premium: boolean;
}

export interface IFollower {
  id: string;
  avatar: string;
  name: string;
  username: string;
  time: number;
}

export interface IShopApi {
  getShopInformation(token: string, shopId: string | null): Promise<IGetShopInformationResponse>;

  getCatalog(shopId: string, token: string): Promise<IShopProductResponseApi[]>;

  getInitialReviews(shopId: string, token: string): Promise<IInitialShopReviewResponse>;

  getShop(id: string, token?: string): Promise<IStoreDetailsApi>;

  getSimilarShops(category: string, token?: string): Promise<IShopsResponseApi>;

  onShopReviewsLoadMore(
    shopId: string,
    maxId: string,
    token?: string,
  ): Promise<IShopReviewsResponse>;

  getShopPosts(shopId: string, token: string, maxId?: string): Promise<IFeedResponseApi>;

  toggleFavorites(id: string, isFollowing: boolean, token?: string): Promise<void>;

  editCoverPhoto(coverPhoto: string, shopId: string, token?: string): void;

  getFollowers(followersDisplayed: number, token?: string): Promise<IGetFollowersResponse>;

  getProduct(id: string, token?: string): Promise<IGetProductResponse>;

  deleteProduct(token: string, productId: string): Promise<void>;

  getProductLink(token: string, productId: string): Promise<{ link: string }>;

  reorderCatalog(token: string, productId: string, toIndex: number, shopId: string): Promise<void>;
}
