import {
  IDiscoverListDetails,
  IDiscoverSectionTitle,
  IPrice,
  IProductDimensions,
  IPromptDetails,
  ITag,
  IVision,
  IVisionOffer,
  IVisionRequest,
  ReturnPolicyType,
} from 'types';
import {
  DiscoverPageResultWeb,
  VisionCreditOfferWeb,
  VisionInformationForUserResponse,
  PaginatedVisionResult,
  StartCheckoutSessionResponse,
  MyRequestsResponse,
  SellerRequestsResponse,
  SellerRequestsTabCountResponse,
  SellerOfferResponse,
  SendOfferResponse,
  ShippingRate,
  SuccessResponse,
  GetCurrencyResponse,
} from 'graphql/generated/graphql';

export interface IVisionsState {
  promptDetails: IPromptDetails;
  hasUsedSmartTags: boolean;
  visionCreditOptions: IVisionsCreditOption[] | null;
  totalCredits: number | null;
  items: IVisionsPageItem[] | null;
  requestsPreview: {
    buyerDetails: IRequestsPreviewDetails;
    sellerDetails: IRequestsPreviewDetails;
  } | null;
  myRequests: { list: IVisionRequest[] | null; paginationToken: string | null };
  myOffers: {
    activeTab: VisionOffersTab;
    potentialOffers: {
      list: IVisionRequest[] | null;
      paginationToken: string | null;
      totalCount: number;
    };
    pendingOffers: {
      list: IVisionOffer[] | null;
      paginationToken: string | null;
      totalCount: number;
    };
    expiredOffers: {
      list: IVisionOffer[] | null;
      paginationToken: string | null;
      totalCount: number;
    };
  };
  tags: string[];
  isLoading: boolean;
}

export interface IVisionsApi {
  getCreditOptions(token: string): Promise<VisionCreditOfferWeb[]>;
  getVisionsPage(token: string): Promise<DiscoverPageResultWeb>;
  getVisionsByListId(
    token: string,
    listId: string,
    paginationToken: string | null,
  ): Promise<PaginatedVisionResult>;
  getVisionsInformation(token: string): Promise<IGetVisionInfoResponse>;
  startVisionsCreditsCheckout(
    token: string,
    offerId: string,
  ): Promise<StartCheckoutSessionResponse>;
  getMyVisionOffers(
    token: string,
    paginationToken: string,
    listId: VisionOffersTab,
  ): Promise<SellerOfferResponse>;
  getCurrencyCode(token: string): Promise<GetCurrencyResponse>;
  getPotentialVisionOffers(token: string, paginationToken: string): Promise<SellerRequestsResponse>;
  getMyVisionRequests(token: string, paginationToken: string): Promise<MyRequestsResponse>;
  getMyOffersTotalCounts(token: string): Promise<SellerRequestsTabCountResponse>;
  sendVisionOffer(
    token: string,
    requestId: string,
    price: number,
    tariff: string | null,
    returnsPolicy: ReturnPolicyType,
    shippingProfileId: string,
    note: string | null,
    dimensions: IProductDimensions,
  ): Promise<SendOfferResponse>;
  editVisionOffer(
    token: string,
    offerId: string,
    requestId: string,
    price: number,
    tariff: string | null,
    returnsPolicy: ReturnPolicyType,
    shippingProfileId: string,
    note: string | null,
    dimensions: IProductDimensions,
  ): Promise<SuccessResponse>;
  getShippingRate(
    token: string,
    dimensions: IProductDimensions,
    requestId: string,
    shippingProfileId: string,
  ): Promise<ShippingRate>;
}

export interface IRequestsPreviewDetails {
  totalRequests: number;
  images: string[];
}

export enum DiscoverVisionsComponentType {
  UnequalGrid = 'LAYOUT_1',
  Categories = 'LAYOUT_2',
  Carousel = 'LAYOUT_3',
  GenerateVisionAd = 'vision_ad',
}

export interface IVisionsPageItem {
  type: DiscoverVisionsComponentType;
  titles: IDiscoverSectionTitle[];
  listDetails: IDiscoverListDetails | null;
  sectionDetails: { marginTop: number; order: number };
  visions: IVision[];
}

export interface IVisionsCreditOption {
  id: string;
  price: IPrice;
  totalCredits: number;
  tag: ITag | null;
}

export enum VisionOffersTab {
  Potential = 'Potential',
  Pending = 'Pending',
  Expired = 'Expired',
}

export interface IGetVisionInfoResponse {
  getVisionInformationForUser: VisionInformationForUserResponse;
  getSellerRequestsCounts: { pendingCount: number; potentialCount: number };
}
