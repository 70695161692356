import { cloneDeep } from 'lodash';

export const isEmptyArray = (arr?: Array<any> | null) => !!arr && arr.length === 0;

export const isEmptyArrayOrUndefined = (arr?: Array<any> | null) => !arr || arr.length === 0;

export const transformToArray = (content: any) => (content instanceof Array ? content : [content]);

export const deepCloneArray = (array: any[]) => cloneDeep(array);

export const createArray = (length: any) => [...new Array(length)];

export const isArraysEqual = (arr1: Array<any>, arr2: Array<any>) => {
  return (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((val, index) => val === arr2[index])
  );
};
