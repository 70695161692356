import { Middleware } from 'redux';

import { IStore2, MessageIds, popupIdsOptions } from 'types';
import {
  getCatalog,
  getFollowers,
  getOrderShopId,
  getProductLink,
  ShopSlicer,
} from './ShopActions';
import { updateAvatar } from 'store/user/UserActions';
import { requestOrder } from 'store/specificOrder/SpecificOrderActions';
import { addShippingAddress } from 'store/settings/SettingsActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import { MessageSlicer } from 'store/message/MessageActions';
import { PopupsSrv } from 'services/PopupsSrv';
import { onAddItemToBasket } from '../basket/BasketActions';
import { BasketSlicer } from '../basket/BasketSlicer';
import { UserSelector } from '../user/UserSelector';

export const shopMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case PopupSlicer.actions.hide.type: {
        const state: IStore2 = getState();

        const { ids } = state.popup;

        if (
          (PopupsSrv.checkIfDisplayed(popupIdsOptions.requestToBuyPopup, ids) ||
            PopupsSrv.checkIfDisplayed(popupIdsOptions.payBasketPopup, ids)) &&
          ids.length === 1
        ) {
          dispatch(BasketSlicer.actions.removeBuyNowItem());
        }
        break;
      }
      case PopupSlicer.actions.show.type:
        const { id } = action.payload;

        if (id === popupIdsOptions.followersPopup) {
          dispatch(getFollowers());
        }
        if (
          id === popupIdsOptions.addListingChooseDuplicatePopupShop ||
          id === popupIdsOptions.addListingChooseDuplicatePopupCreate
        ) {
          const state: IStore2 = getState();

          const shopId = UserSelector.loggedInUserShopIdSelector(state);

          if (shopId) {
            dispatch(getCatalog({ shopId }));
          }
        }
        break;

      case updateAvatar.fulfilled.type: {
        const state: IStore2 = getState();

        const isShopOwner = state.shop.details?.userId === state.user.id;

        if (isShopOwner) {
          dispatch(ShopSlicer.actions.updateAvatar(action.meta.arg));
        }
        break;
      }

      case getProductLink.fulfilled.type:
        const { link } = action.payload;
        dispatch(MessageSlicer.actions.show({ id: MessageIds.linkCopied, link }));
        break;

      case getOrderShopId.fulfilled.type:
        const { shopId } = action.payload;

        const { shippingAddress, items, selectedDeliveryId, couponCode } = action.meta.arg;

        if (shippingAddress.shouldRememberAddress) {
          dispatch(addShippingAddress({ shippingAddress }));
        }
        dispatch(requestOrder({ shippingAddress, items, shopId, selectedDeliveryId, couponCode }));
        break;

      case requestOrder.rejected.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.requestOrderError }));
        break;

      case onAddItemToBasket.fulfilled.type:
        const { details } = action.meta.arg;
        dispatch(
          PopupSlicer.actions.show({
            id: popupIdsOptions.itemAddedToBasketPopup,
            itemAddedToBasketDetails: details,
          }),
        );
        break;

      default:
        break;
    }

    return next(action);
  };
