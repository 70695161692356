import { translations } from 'translations/en';
import {
  CurrencyCode,
  mapCurrencyToLongString,
  mapCurrencyToShortString,
} from 'constant/currencies';
import { checkSmallScreen } from 'utils';
import {
  DiscountTypes,
  ICreateShopCatalogItem,
  IImageContainer,
  IListingState,
  IProductDimensions,
  ListingOption,
} from 'types';
import {
  cmToInch,
  gramsToKg,
  inchToCm,
  kgToGrams,
  kgToLbs,
  lbsToKg,
  lbsToOz,
  ozToLb,
} from 'utils/UnitUtils';

export const radioButtonList = [
  {
    value: DiscountTypes.Percentage,
    label: translations.CREATE_SHOP.LISTING_STEP.PERCENTAGE_DISCOUNT,
  },
  { value: DiscountTypes.Fixed, label: translations.CREATE_SHOP.LISTING_STEP.FIXED_AMOUNT },
];

const order = [
  CurrencyCode.USD,
  CurrencyCode.CAD,
  CurrencyCode.EUR,
  CurrencyCode.GBP,
  CurrencyCode.AUD,
  CurrencyCode.ILS,
  CurrencyCode.DKK,
  CurrencyCode.HKD,
  CurrencyCode.INR,
  CurrencyCode.HUF,
  CurrencyCode.IDR,
  CurrencyCode.MXN,
  CurrencyCode.VND,
  CurrencyCode.SEK,
  CurrencyCode.CHF,
  CurrencyCode.CZK,
  CurrencyCode.TWD,
  CurrencyCode.PLN,
  CurrencyCode.NOK,
  CurrencyCode.PHP,
  CurrencyCode.BRL,
  CurrencyCode.CNY,
  CurrencyCode.JPY,
  CurrencyCode.MYR,
  CurrencyCode.NZD,
  CurrencyCode.SGD,
  CurrencyCode.THB,
];

export const imageToDuplicate = (image: IImageContainer) => {
  if (!image.listingImage) return image;

  return {
    ...image,
    listingImage: `${image.listingImage}?date=${Date.now()}`,
  };
};

export const imagesToDuplicate = (images: IImageContainer[]) => images.map(imageToDuplicate);

const labelsToRender = checkSmallScreen() ? mapCurrencyToShortString : mapCurrencyToLongString;

export const DROPDOWN_OPTIONS = order.map((currency) => ({
  label: labelsToRender[currency],
  value: String(currency),
}));

export const convertDimensions = (dimensions: IProductDimensions): IProductDimensions => {
  const { isMetric, weightSmall, weightBig, height, length, width } = dimensions;

  if (isMetric) {
    const totalImperialWeight = weightBig + ozToLb(weightSmall);

    const totalMetricWeight = lbsToKg(totalImperialWeight);

    const metricWeight = Math.floor(totalMetricWeight);

    const metricDecimalWeight = kgToGrams(Number((totalMetricWeight - metricWeight).toFixed(3)));

    return {
      length: inchToCm(length),
      height: inchToCm(height),
      width: inchToCm(width),
      weightBig: metricWeight,
      weightSmall: metricDecimalWeight,
      isMetric,
    };
  }

  const totalMetricWeight = weightBig + gramsToKg(weightSmall);

  const newTotalWeight = kgToLbs(totalMetricWeight);

  const newWeight = Math.floor(newTotalWeight);

  const newDecimalWeight = Math.round(lbsToOz(newTotalWeight - newWeight));

  return {
    length: cmToInch(length),
    height: cmToInch(height),
    width: cmToInch(width),
    weightBig: newWeight,
    weightSmall: newDecimalWeight,
    isMetric,
  };
};

export const updateListing = (
  item: ICreateShopCatalogItem,
  isDuplicate: boolean,
): IListingState => {
  const {
    id,
    title,
    description,
    price,
    priceAfterDiscount,
    currency,
    quantity,
    isDigital,
    digitalFiles,
    discountType,
    saleAmount,
    images,
    tariffNumber,
    shippingProfileId,
    dimensions,
    returnPolicy,
    category,
    keywords,
  } = item;

  return {
    id: isDuplicate ? '' : id,
    title: isDuplicate ? `copy of ${title}` : title,
    description,
    price,
    priceAfterDiscount,
    currency,
    quantity,
    isDigital,
    digitalFiles,
    discountType,
    saleAmount,
    images: isDuplicate ? imagesToDuplicate(images) : images,
    tariffNumber,
    shippingProfileId: shippingProfileId || null,
    dimensions: dimensions || {
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      decimalWeight: 0,
      isMetric: false,
    },
    returnPolicy: returnPolicy || null,
    category,
    keywords,
    listingIdToEdit: '',
    listingIdToDuplicate: '',
    listingOption: ListingOption.Create,
    shouldDisplayWarning: false,
  };
};
