import {
  IMenuState,
  IUserState,
  IShopState,
  IContactUsState,
  ISearchResultState,
  IReviewState,
  IPopup,
  IFeedState,
  IForgetPasswordState,
  ISpecificPostState,
  INotificationState,
  ISettingsState,
  IGetTheAppState,
  IMessageState,
  IProfilePageState,
  IDrawerState,
  ICreateShopState,
  IOnboardingState,
  IOrdersState,
  IChatState,
  IMentionsState,
  ISpecificOrderState,
  IPayoutsState,
  IShippingState,
  IProductsState,
  IConfigState,
  IDiscoverState,
  IMojosState,
  ISpecificProductState,
  IListingState,
  IVisionsState,
  IGenerateVisionState,
  ISpecificVisionState,
  IVisionsListState,
  ISpecificVisionRequestState,
  ISendVisionOfferState,
  IBasketState,
} from 'types';
import { ICheckoutForNonSignupUsersState } from './checkoutForNonSignupUsers/types';

export * from './review/types';

export * from './searchResult/types';

export * from './checkoutForNonSignupUsers/types';

export * from './contactUs/types';

export * from './shop/types';

export * from './drawers/types';

export * from './user/types';

export * from './forgetPassword/types';

export * from './feed/types';

export * from './settings/types';

export * from './specificPost/types';

export * from './notifications/types';

export * from './mentions/types';

export * from './message/types';

export * from './profile/types';

export * from './getTheApp/types';

export * from './createShop/types';

export * from './chat/types';

export * from './onboarding/types';

export * from './orders/types';

export * from './specificOrder/types';

export * from './payouts/types';

export * from './shipping/types';

export * from './menu/types';

export * from './specificProduct/types';

export * from './products/types';

export * from './config/types';

export * from './discover/types';

export * from './mojos/types';

export * from './amplitude/types';

export * from './listing/types';

export * from './visions/types';

export * from './generateVision/types';

export * from './specificVision/types';

export * from './visionsList/types';

export * from './specificVisionRequest/types';

export * from './sendVisionOffer/types';

export * from './basket/types';

export interface IStore2 {
  config: IConfigState;
  discover: IDiscoverState;
  chat: IChatState;
  user: IUserState;
  shop: IShopState;
  mojos: IMojosState;
  profile: IProfilePageState;
  reviews: IReviewState;
  contact: IContactUsState;
  drawer: IDrawerState;
  specificPost: ISpecificPostState;
  settings: ISettingsState;
  notifications: INotificationState;
  searchResult: ISearchResultState;
  popup: IPopup;
  feed: IFeedState;
  message: IMessageState;
  forgetPassword: IForgetPasswordState;
  checkoutForNonSignupUsers: ICheckoutForNonSignupUsersState;
  menu: IMenuState;
  mentions: IMentionsState;
  getTheApp: IGetTheAppState;
  createShop: ICreateShopState;
  onboarding: IOnboardingState;
  orders: IOrdersState;
  specificOrder: ISpecificOrderState;
  payouts: IPayoutsState;
  specificProduct: ISpecificProductState;
  shipping: IShippingState;
  listing: IListingState;
  products: IProductsState;
  visions: IVisionsState;
  generateVision: IGenerateVisionState;
  specificVision: ISpecificVisionState;
  visionsList: IVisionsListState;
  specificVisionRequest: ISpecificVisionRequestState;
  sendVisionOffer: ISendVisionOfferState;
  basket: IBasketState;
}

export interface ILoggedInUserDetails {
  avatar: string | null;
  firstName: string;
  username: string;
  userId: string;
}

export interface IToken extends ILoggedInUserDetails {
  aud: Array<string>;
  exp: number;
  iat: number;
  lang: string;
  sub: string;
  subUntil: number;
  thirdParty: number;
}

export enum Method {
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum FeedStaticItemsTypes {
  Groups = 'GROUPS',
  FeedFilters = 'FEED_FILTERS',
  CreatePost = 'CREATE_POST',
}
