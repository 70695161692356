import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  FixedPriceOptions,
  IShippingAddress,
  IShippingAddressState,
  IShippingState,
  ProcessingTimeOptions,
  ShippingCountriesType,
  ShippingPriceOptions,
} from 'types';
import {
  addShippingProfile,
  deleteShippingProfile,
  getShippingServices,
  linkShippo,
  updateShippingProfile,
  getPackagesType,
  buyShippingLabel,
  getShippingLabelShippingRates,
  getShippingLabelAndEmail,
  validateShippingProfileAddress,
  getLabelInformation,
  getShippingProfiles,
  validateShippingAddress,
} from './ShippingActions';
import {
  parseShippingProfiles,
  parseShippingProfilesToAffectedListings,
  parsePackagesTypeList,
  parseShippingLabelUrls,
  parseShippingLabelShippingRates,
  parseDisabledShippingRates,
  parseGQLShippingProfiles,
} from '../apiParser';
import { Countries } from 'constant/countries';
import { getShopInformation } from '../createShop/CreateShopActions';
import { getRemainingCountries } from 'pages/createShop/popups/createShippingProfilePopup/helper';
import {
  DEFAULT_DESTINATION,
  getAllSelectedCountries,
  getDeliveryTimeOption,
  getDisabledCountries,
  getNewCountry,
  isAllCountriesSelected,
  mapProcessingTimeTypeToTime,
} from './helper';
import { getShopInformationSettings } from '../settings/SettingsActions';
import { isEmptyArray } from 'utils';

const DEFAULT_SHIPPING_ADDRESS: IShippingAddressState = {
  id: null,
  country: Countries.UnitedStates,
  isLoading: false,
  city: '',
  state: null,
  streetAddress: '',
  postalCode: '',
  fullName: '',
  phoneNumber: '',
  apartmentNumber: '',
  email: '',
  isDefault: false,
};

const initialState: IShippingState = {
  shippoUrl: null,
  isLoading: false,
  shippingProfileForm: {
    id: null,
    name: '',
    processingTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
    shippingPriceOption: ShippingPriceOptions.CalculatedShipping,
    shippingAddress: {
      country: Countries.UnitedStates,
      address: '',
      city: '',
      postalCode: '',
      state: null,
    },
    shippingDestinations: [DEFAULT_DESTINATION],
  },
  shippingAddressForm: DEFAULT_SHIPPING_ADDRESS,
  availableShippingServices: [],
  shippingProfiles: [],
  mapShippingProfileIdToAffectedListings: {},
  shippingRates: null,
  disabledShippingRates: null,
  packagesTypeList: null,
  isLoadingShippingService: false,
  isLoadingBuyShippingLabel: true,
  shippingServicePrice: null,
  shippingLabelDownloadUrls: null,
  isBuyShippingLabelLoading: false,
  createShippingLabelForm: {
    packageType: 'Unusual package',
    shippingServiceId: '',
    selectedShippingService: null,
    hasInsurance: false,
    hasSignature: false,
    dimensions: { length: 0, width: 0, height: 0, weightBig: 0, weightSmall: 0, isMetric: false },
    shippingDate: Date.now().toString(),
    recipientAddress: DEFAULT_SHIPPING_ADDRESS,
  },
};

export const ShippingSlicer = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    reset: () => initialState,
    updateIsLoadingBuyShippingLabel: (state) => {
      state.isLoadingBuyShippingLabel = true;
    },
    toggleIsMetric: (state) => {
      state.createShippingLabelForm.dimensions.isMetric =
        !state.createShippingLabelForm.dimensions.isMetric;
    },
    initializeRecipientAddress: (state, action) => {
      const { shippingAddress } = action.payload;

      state.createShippingLabelForm.recipientAddress = shippingAddress;
    },
    updateRecipientAddress: (state, action) => {
      const { postalCode, address, city, country, state: addressState } = action.payload;

      const { recipientAddress } = state.createShippingLabelForm;
      recipientAddress.postalCode = postalCode;
      recipientAddress.streetAddress = address;
      recipientAddress.city = city;
      recipientAddress.country = country;
      recipientAddress.state = addressState;
      recipientAddress.fullName = state.shippingAddressForm.fullName;
    },
    updateLength: (state, action) => {
      const { length } = action.payload;
      state.createShippingLabelForm.dimensions.length = length;
    },
    updateWidth: (state, action) => {
      const { width } = action.payload;
      state.createShippingLabelForm.dimensions.width = width;
    },
    updateHeight: (state, action) => {
      const { height } = action.payload;
      state.createShippingLabelForm.dimensions.height = height;
    },
    updateBigWeight: (state, action) => {
      const { weightBig } = action.payload;
      state.createShippingLabelForm.dimensions.weightBig = weightBig;
    },
    updateSmallWeight: (state, action) => {
      const { weightSmall } = action.payload;
      state.createShippingLabelForm.dimensions.weightSmall = weightSmall;
    },
    updateDimensions: (state, action) => {
      const { dimensions } = action.payload;
      state.createShippingLabelForm.dimensions = dimensions;
    },
    updateInsuranceStatus: (state, action) => {
      const { hasInsurance } = action.payload;
      state.createShippingLabelForm.hasInsurance = !hasInsurance;
    },
    updateSignatureStatus: (state, action) => {
      const { hasSignature } = action.payload;
      state.createShippingLabelForm.hasSignature = !hasSignature;
    },
    updateShippingDate: (state, action) => {
      const { shippingDate } = action.payload;
      state.createShippingLabelForm.shippingDate = shippingDate;
    },
    updatePackageType: (state, action) => {
      const { packageType } = action.payload;
      state.createShippingLabelForm.packageType = packageType;
    },
    updateSelectedShippingService: (state, action) => {
      const { shippingServiceId } = action.payload;

      const selectedService = state.shippingRates
        ? state.shippingRates.find((item) => item.id === shippingServiceId)
        : null;

      state.createShippingLabelForm.selectedShippingService = selectedService || null;

      state.shippingServicePrice = selectedService ? selectedService.price : null;
    },
    removeShippingDestination: (state, action) => {
      const { index } = action.payload;
      state.shippingProfileForm.shippingDestinations.splice(index, 1);
    },
    addShippingDestination: (state) => {
      const isEverywhereElseSelected = !!state.shippingProfileForm.shippingDestinations.find(
        (destination) => destination.countries.type === ShippingCountriesType.EverywhereElse,
      );

      const allSelectedCountries = getAllSelectedCountries(
        state.shippingProfileForm.shippingDestinations,
      );

      const countryValues = isEverywhereElseSelected ? getNewCountry(allSelectedCountries) : [];

      const shippingServiceId = isEmptyArray(state.availableShippingServices)
        ? 'other'
        : state.availableShippingServices[0].services[0].id;

      const countryType = isEverywhereElseSelected
        ? ShippingCountriesType.Specific
        : ShippingCountriesType.EverywhereElse;
      state.shippingProfileForm.shippingDestinations.push({
        countries: { type: countryType, values: countryValues },
        shippingServicesIds: [shippingServiceId],
        isDomesticFreeShipping: false,
        isInternationalFreeShipping: false,
        deliveryTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
        fixedPrice: {
          dropdownValue: FixedPriceOptions.Fixed,
          firstItem: 0,
          additionalItem: 0,
        },
      });
    },
    onToggleCountry: (state, action) => {
      const { country, isChecked, index } = action.payload;

      const disabledCountries = getDisabledCountries(
        state.shippingProfileForm.shippingDestinations,
      );

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      if (isChecked) {
        currentDestination.countries.values.push(country);
        if (isAllCountriesSelected(state.shippingProfileForm.shippingDestinations)) {
          currentDestination.countries.type = ShippingCountriesType.EverywhereElse;
          currentDestination.countries.values = [];
        }
      } else {
        if (currentDestination.countries.type === ShippingCountriesType.EverywhereElse) {
          currentDestination.countries.type = ShippingCountriesType.Specific;
          currentDestination.countries.values = getRemainingCountries(disabledCountries);
        }

        const countryIndex = currentDestination.countries.values.indexOf(country);
        currentDestination.countries.values.splice(countryIndex, 1);
      }
    },
    onToggleRegion: (state, action) => {
      const { countries, isChecked, index } = action.payload;

      const disabledCountries = getDisabledCountries(
        state.shippingProfileForm.shippingDestinations,
      );

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      if (isChecked) {
        countries.forEach((country: Countries) => {
          if (!currentDestination.countries.values.includes(country)) {
            currentDestination.countries.values.push(country);
          }
        });
        if (isAllCountriesSelected(state.shippingProfileForm.shippingDestinations)) {
          currentDestination.countries.type = ShippingCountriesType.EverywhereElse;
          currentDestination.countries.values = [];
        }
      } else {
        if (currentDestination.countries.type === ShippingCountriesType.EverywhereElse) {
          currentDestination.countries.type = ShippingCountriesType.Specific;
          currentDestination.countries.values = getRemainingCountries(disabledCountries);
        }
        currentDestination.countries.values = currentDestination.countries.values.filter(
          (selectedOption) => !countries.includes(selectedOption),
        );
      }
    },
    onToggleAllCountries: (state, action) => {
      const { isChecked, index } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];
      currentDestination.countries.type = ShippingCountriesType.Specific;
      currentDestination.countries.values = [];

      if (isChecked) {
        currentDestination.countries.type = ShippingCountriesType.EverywhereElse;
      }
    },
    onShippingPriceOptionChange: (state, action) => {
      const { value } = action.payload;

      state.shippingProfileForm.shippingPriceOption = value;
      state.shippingProfileForm.shippingDestinations = [DEFAULT_DESTINATION];
      if (value === ShippingPriceOptions.FixedPrice) {
        state.shippingProfileForm.shippingDestinations.unshift({
          countries: { type: ShippingCountriesType.Specific, values: [Countries.UnitedStates] },
          shippingServicesIds: [],
          deliveryTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
          isInternationalFreeShipping: false,
          isDomesticFreeShipping: false,
          fixedPrice: {
            dropdownValue: FixedPriceOptions.Fixed,
            firstItem: 0,
            additionalItem: 0,
          },
        });
      }
    },
    onProcessingTimeOptionChange: (state, action) => {
      const { value } = action.payload;
      state.shippingProfileForm.processingTime.dropdownValue = value;
      if (value !== ProcessingTimeOptions.Custom) {
        const { min, max } = mapProcessingTimeTypeToTime[value];

        state.shippingProfileForm.processingTime.min = min;
        state.shippingProfileForm.processingTime.max = max;
      }
    },
    onMinProcessingTimeChange: (state, action) => {
      const { value } = action.payload;
      state.shippingProfileForm.processingTime.min = value;
    },
    onMaxProcessingTimeChange: (state, action) => {
      const { value } = action.payload;
      state.shippingProfileForm.processingTime.max = value;
    },
    onShippingServiceSelect: (state, action) => {
      const { selectedService, index, isSelected } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      if (isSelected) {
        if (state.shippingProfileForm.shippingPriceOption === ShippingPriceOptions.FixedPrice) {
          currentDestination.shippingServicesIds = [selectedService];
        } else {
          currentDestination.shippingServicesIds.push(selectedService);
        }
      } else {
        const serviceIndex = currentDestination.shippingServicesIds.findIndex(
          (service) => service === selectedService,
        );
        currentDestination.shippingServicesIds.splice(serviceIndex, 1);
      }
    },
    onToggleServiceGroup: (state, action) => {
      const { services, isChecked, index } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      if (isChecked) {
        services.forEach((service: string) => {
          if (!currentDestination.shippingServicesIds.includes(service)) {
            currentDestination.shippingServicesIds.push(service);
          }
        });
      } else {
        currentDestination.shippingServicesIds = currentDestination.shippingServicesIds.filter(
          (selectedOption) => !services.includes(selectedOption),
        );
      }
    },
    onToggleAllShippingServices: (state, action) => {
      const { isChecked, index } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      if (isChecked) {
        const allServices: string[] = [];
        state.availableShippingServices.forEach((group) => {
          const groupIds = group.services.map((service) => service.id);
          allServices.push(...groupIds);
        });
        currentDestination.shippingServicesIds = allServices;
      } else {
        currentDestination.shippingServicesIds = [];
      }
    },
    onDomesticFreeShippingToggle: (state, _action) => {
      const currentDestination = state.shippingProfileForm.shippingDestinations[0];
      currentDestination.isDomesticFreeShipping = !currentDestination.isDomesticFreeShipping;
    },
    onInternationalFreeShippingToggle: (state, _action) => {
      const currentDestination = state.shippingProfileForm.shippingDestinations[0];
      currentDestination.isInternationalFreeShipping =
        !currentDestination.isInternationalFreeShipping;
    },
    onOriginCountryChange: (state, action) => {
      const { country } = action.payload;

      state.shippingProfileForm.shippingAddress.country = country;
      state.shippingProfileForm.shippingDestinations.forEach(
        (destination) => (destination.shippingServicesIds = []),
      );
    },
    updateCity: (state, action) => {
      const { city } = action.payload;

      state.shippingProfileForm.shippingAddress.city = city;
    },
    updateAddress: (state, action) => {
      const { address } = action.payload;

      state.shippingProfileForm.shippingAddress.address = address;
    },
    updatePostalCode: (state, action) => {
      const { postalCode } = action.payload;

      state.shippingProfileForm.shippingAddress.postalCode = postalCode;
    },
    updateName: (state, action) => {
      const { name } = action.payload;

      state.shippingProfileForm.name = name;
    },
    onFixedPriceDropdownChange: (state, action) => {
      const { index, value } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];
      currentDestination.fixedPrice.dropdownValue = value;
      if (value === FixedPriceOptions.FreeShipping) {
        currentDestination.fixedPrice.firstItem = 0;
        currentDestination.fixedPrice.additionalItem = 0;
      }
    },
    onDeliveryTimeDropdownChange: (state, action) => {
      const { index, value } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];
      currentDestination.deliveryTime.dropdownValue = value;
      if (value !== ProcessingTimeOptions.Custom) {
        const deliveryTime = mapProcessingTimeTypeToTime[value];
        currentDestination.deliveryTime.min = deliveryTime.min;
        currentDestination.deliveryTime.max = deliveryTime.max;
      }
    },
    onMinimumDeliveryTimeChange: (state, action) => {
      const { index, min } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      currentDestination.deliveryTime.min = min;
    },
    onMaximumDeliveryTimeChange: (state, action) => {
      const { index, max } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];

      currentDestination.deliveryTime.max = max;
    },
    onOneItemPriceChange: (state, action) => {
      const { index, price } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];
      currentDestination.fixedPrice.firstItem = price;
    },
    onAdditionalItemPriceChange: (state, action) => {
      const { index, price } = action.payload;

      const currentDestination = state.shippingProfileForm.shippingDestinations[index];
      currentDestination.fixedPrice.additionalItem = price;
    },
    editShippingProfile: (state, action) => {
      const { id } = action.payload;

      const shippingProfile = state.shippingProfiles.find((profile) => profile.id === id);

      if (shippingProfile) {
        const { name, shippingDestinations, processingTime, shippingPriceOption, originAddress } =
          shippingProfile;

        const processingTimeDropdownValue = getDeliveryTimeOption(
          processingTime.min,
          processingTime.max,
        );

        state.shippingProfileForm.id = id;
        state.shippingProfileForm.name = name;
        state.shippingProfileForm.shippingDestinations = shippingDestinations;
        state.shippingProfileForm.processingTime = {
          ...processingTime,
          dropdownValue: processingTimeDropdownValue,
        };
        state.shippingProfileForm.shippingPriceOption = shippingPriceOption;
        state.shippingProfileForm.shippingAddress = originAddress;
      }
    },
    duplicateShippingProfile: (state, action) => {
      const { id } = action.payload;

      const shippingProfile = state.shippingProfiles.find((profile) => profile.id === id);

      if (shippingProfile) {
        const { name, shippingDestinations, processingTime, shippingPriceOption, originAddress } =
          shippingProfile;

        const processingTimeDropdownValue = getDeliveryTimeOption(
          processingTime.min,
          processingTime.max,
        );
        state.shippingProfileForm.id = null;
        state.shippingProfileForm.name = `${name} (copy)`;
        state.shippingProfileForm.shippingDestinations = shippingDestinations;
        state.shippingProfileForm.shippingPriceOption = shippingPriceOption;
        state.shippingProfileForm.shippingAddress = originAddress;
        state.shippingProfileForm.processingTime = {
          ...processingTime,
          dropdownValue: processingTimeDropdownValue,
        };
      }
    },
    resetShippingProfileForm: (state, action) => {
      const { country } = action.payload;
      state.shippingProfileForm = {
        id: null,
        name: '',
        processingTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
        shippingPriceOption: ShippingPriceOptions.CalculatedShipping,
        shippingAddress: { country, address: '', city: '', postalCode: '', state: null },
        shippingDestinations: [
          {
            countries: {
              type: ShippingCountriesType.EverywhereElse,
              values: getRemainingCountries([]),
            },
            shippingServicesIds: [],
            isDomesticFreeShipping: false,
            isInternationalFreeShipping: false,
            fixedPrice: {
              dropdownValue: FixedPriceOptions.FreeShipping,
              firstItem: 0,
              additionalItem: 0,
            },
            deliveryTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
          },
        ],
      };
    },
    updateMapToAffectedListings: (state, action) => {
      const { oldId, newId } = action.payload;

      const oldProfileValue = state.mapShippingProfileIdToAffectedListings[oldId];

      const newProfileValue = state.mapShippingProfileIdToAffectedListings[newId];
      state.mapShippingProfileIdToAffectedListings[oldId] = oldProfileValue - 1;
      state.mapShippingProfileIdToAffectedListings[newId] = newProfileValue + 1;
    },
    submitShippingAddressForm: (
      state,
      action: { payload: { shippingAddress: IShippingAddress } },
    ) => {
      const { shippingAddress } = action.payload;

      state.shippingAddressForm = { ...shippingAddress, isLoading: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShippingLabelShippingRates.pending, (state) => {
      state.isLoadingShippingService = true;
    });
    builder.addCase(getShippingLabelShippingRates.fulfilled, (state, action) => {
      state.shippingRates = parseShippingLabelShippingRates(action.payload);

      state.disabledShippingRates = parseDisabledShippingRates(action.payload);

      state.isLoadingShippingService = false;
    });
    builder.addCase(getPackagesType.fulfilled, (state, action) => {
      state.packagesTypeList = parsePackagesTypeList(action.payload);
      state.isBuyShippingLabelLoading = false;
    });
    builder.addCase(buyShippingLabel.pending, (state) => {
      state.isLoadingShippingService = true;
      state.isBuyShippingLabelLoading = true;
    });
    builder.addCase(buyShippingLabel.rejected, (state) => {
      state.isLoadingShippingService = false;
      state.isBuyShippingLabelLoading = false;
    });
    builder.addCase(buyShippingLabel.fulfilled, (state, action) => {
      state.shippingLabelDownloadUrls = parseShippingLabelUrls(action.payload);
      state.isLoadingShippingService = false;
    });
    builder.addCase(getShippingLabelAndEmail.fulfilled, (state, action) => {
      state.shippingLabelDownloadUrls = parseShippingLabelUrls(action.payload);
    });
    builder.addCase(linkShippo.fulfilled, (state, action) => {
      const { linkUrl } = action.payload;
      state.shippoUrl = linkUrl;
      state.isLoading = false;
    });
    builder.addCase(getLabelInformation.rejected, (state) => {
      state.isLoadingBuyShippingLabel = false;
    });
    builder.addCase(validateShippingAddress.pending, (state) => {
      state.shippingAddressForm.isLoading = true;
    });
    builder.addCase(getShippingServices.fulfilled, (state, action) => {
      const { services } = action.payload;
      state.availableShippingServices = services;
      if (isEmptyArray(state.availableShippingServices)) {
        state.shippingProfileForm.shippingPriceOption = ShippingPriceOptions.FixedPrice;
      }
      if (
        state.shippingProfileForm.shippingPriceOption === ShippingPriceOptions.CalculatedShipping
      ) {
        const allShippingServicesIds = state.availableShippingServices.reduce(
          (acc, group) => acc.concat(group.services.map((service) => service.id)),
          [] as string[],
        );
        state.shippingProfileForm.shippingDestinations[0].shippingServicesIds =
          allShippingServicesIds;
      }
    });
    builder.addCase(deleteShippingProfile.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      const profileIndexToDelete = state.shippingProfiles.findIndex((profile) => profile.id === id);

      if (profileIndexToDelete !== -1) {
        state.shippingProfiles.splice(profileIndexToDelete, 1);
      }
    });
    builder.addCase(addShippingProfile.fulfilled, (state, action) => {
      const { id } = action.payload;

      const processingTimeDropdownValue = state.shippingProfileForm.processingTime.dropdownValue;

      const { min, max } = mapProcessingTimeTypeToTime[processingTimeDropdownValue];

      const processingTime = { dropdownValue: processingTimeDropdownValue, min, max };
      state.shippingProfiles.push({
        id,
        shippingDestinations: state.shippingProfileForm.shippingDestinations,
        originAddress: state.shippingProfileForm.shippingAddress,
        name: state.shippingProfileForm.name,
        shippingPriceOption: state.shippingProfileForm.shippingPriceOption,
        processingTime,
      });
      state.isLoading = false;
    });
    builder.addCase(updateShippingProfile.fulfilled, (state) => {
      const profileId = state.shippingProfileForm.id;

      const currentProfile = state.shippingProfiles.find((profile) => profile.id === profileId);

      if (currentProfile) {
        currentProfile.shippingDestinations = state.shippingProfileForm.shippingDestinations;
        currentProfile.originAddress = state.shippingProfileForm.shippingAddress;
        currentProfile.name = state.shippingProfileForm.name;
        currentProfile.shippingPriceOption = state.shippingProfileForm.shippingPriceOption;
        currentProfile.processingTime = state.shippingProfileForm.processingTime;
      }
      state.isLoading = false;
    });
    builder.addCase(getShippingProfiles.fulfilled, (state, action) => {
      const { profiles } = action.payload;
      state.shippingProfiles = parseGQLShippingProfiles(profiles);
    });
    builder.addMatcher(
      isAnyOf(linkShippo.pending, validateShippingProfileAddress.pending),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        linkShippo.fulfilled,
        updateShippingProfile.rejected,
        addShippingProfile.rejected,
        validateShippingProfileAddress.rejected,
        linkShippo.rejected,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(getShopInformation.fulfilled, getShopInformationSettings.fulfilled),
      (state, action) => {
        const { shippingProfiles, products } = action.payload;
        state.shippingProfiles = parseShippingProfiles(shippingProfiles);
        state.mapShippingProfileIdToAffectedListings = parseShippingProfilesToAffectedListings(
          products,
          shippingProfiles,
        );
      },
    );
    builder.addMatcher(
      isAnyOf(validateShippingAddress.rejected, validateShippingAddress.fulfilled),
      (state) => {
        state.shippingAddressForm.isLoading = false;
      },
    );
  },
});
