import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2, LoadImagesDirection, MuteChatOptions, ReportOptions } from 'types';
import { ChatApi } from './ChatApi';
import { getSelectedFeed } from '../post/helper';
import { isEmptyArray } from 'utils';

const api = new ChatApi();

export const onSearch = createAsyncThunk(
  'async/onSearch',
  async (input: { searchText: string; loggedInUserId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { searchText } = input;

    if (searchText) {
      return api.getMessages(searchText, token);
    }

    return Promise.reject();
  },
);

export const archiveChat = createAsyncThunk(
  'async/archiveChat',
  async (input: { chatId: string; isGroup: boolean; isArchived: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const chatId = state.chat.selectedChat?.id || '';

    const isGroup = !!state.chat.selectedChat?.isGroup;

    const { isArchived } = input;

    return api.archiveChat(chatId, isGroup, isArchived, token);
  },
);

export const createGroup = createAsyncThunk('async/createGroup', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { createChatDetails } = state.chat;

  const { token = '' } = state.user;

  return api.createGroup(createChatDetails, token);
});

export const inviteUsers = createAsyncThunk(
  'async/inviteUsers',
  async (input: { groupId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { groupId } = input;

    const { selectedUsers } = state.chat.createChatDetails.suggestedMembers;

    const users = selectedUsers.map((user) => user.id);

    const { token = '' } = state.user;

    return api.inviteUsers(groupId, users, token);
  },
);

export const editGroupDetails = createAsyncThunk(
  'async/editGroupDetails',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { selectedChat, createChatDetails } = state.chat;

    if (!selectedChat) {
      return Promise.resolve();
    }

    const {
      avatar: updatedAvatar,
      name: updatedName,
      description: updatedDescription,
      privacy: updatedPrivacy,
    } = createChatDetails;

    const {
      avatar: prevAvatar,
      name: prevName,
      description: prevDescription,
      id: groupId,
      privacy: prevPrivacy,
    } = selectedChat;

    const avatarToSend = prevAvatar !== updatedAvatar ? updatedAvatar : null;

    const descriptionToSend = prevDescription !== updatedDescription ? updatedDescription : null;

    const nameToSend = prevName !== updatedName ? updatedName : null;

    const privacyToSend = prevPrivacy !== updatedPrivacy ? updatedPrivacy : null;

    const { token = '' } = state.user;

    return api.editGroup(
      groupId,
      nameToSend,
      avatarToSend,
      descriptionToSend,
      privacyToSend,
      token,
    );
  },
);

export const getGroupParticipants = createAsyncThunk(
  'async/getGroupParticipants',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    if (!state.chat.selectedChat?.members) {
      return Promise.resolve({ data: [], total: 0 });
    }

    const {
      members: { search, skip },
      id,
    } = state.chat.selectedChat;

    const { token = '' } = state.user;

    return api.getParticipants(search, id, skip, token);
  },
);

export const onUsersSearch = createAsyncThunk(
  'async/onUsersSearch',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { search } = state.chat.createChatDetails.suggestedUsers;

    return api.getUsers(search, token);
  },
);

export const onMembersSearch = createAsyncThunk(
  'async/onMembersSearch',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { search } = state.chat.createChatDetails.suggestedMembers;

    return api.getUsers(search, token);
  },
);

export const onReportUser = createAsyncThunk(
  'async/onReportUser',
  async (input: { userId: string; option: ReportOptions; text: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { option, text, userId } = input;

    const { token = '' } = state.user;

    return api.reportUser(userId, option, text, token);
  },
);

export const onLeaveGroup = createAsyncThunk('async/onLeaveGroup', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  const id = state.chat.selectedChat?.id || '';

  return api.leaveGroup(id, token);
});

export const onMuteChat = createAsyncThunk(
  'async/onMuteChat',
  async (input: { chatId: string; timeToMute: MuteChatOptions }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const isGroup = state.chat.selectedChat?.isGroup;

    const { chatId, timeToMute } = input;

    if (isGroup) {
      return api.muteGroup(chatId, timeToMute, token);
    }

    return api.muteUser(chatId, timeToMute, token);
  },
);

export const onUnmuteGroup = createAsyncThunk(
  'async/onUnmuteGroup',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const isGroup = state.chat.selectedChat?.isGroup;

    const timeToMute = 0;

    const chatId = state.chat.selectedChat?.id || '';

    if (isGroup) {
      return api.muteGroup(chatId, timeToMute, token);
    }

    return api.muteUser(chatId, timeToMute, token);
  },
);

export const getChatMedia = createAsyncThunk(
  'async/getChatMedia',
  async (_input: { loggedInUserId: string; loggedInUsername: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    const media = selectedChat?.media.list || [];

    const id = selectedChat?.id || '';

    const isGroup = !!selectedChat?.isGroup;

    const date = media[media.length - 1]?.date || Date.now();

    return api.getChatImages(id, isGroup, date, LoadImagesDirection.Previous, 30, token);
  },
);

export const getChatFiles = createAsyncThunk('async/getChatFiles', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  const { selectedChat } = state.chat;

  const files = selectedChat?.files.list || [];

  const id = selectedChat?.id || '';

  const isGroup = !!selectedChat?.isGroup;

  const maxId = files[files.length - 1]?.id;

  return api.getChatFiles(id, isGroup, token, maxId);
});

export const getChatLinks = createAsyncThunk('async/getChatLinks', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  const { selectedChat } = state.chat;

  const links = selectedChat?.links.list || [];

  const id = selectedChat?.id || '';

  const isGroup = !!selectedChat?.isGroup;

  const maxId = links[links.length - 1]?.id;

  return api.getChatLinks(id, isGroup, token, maxId);
});

export const getBothChatImages = createAsyncThunk(
  'async/getBothChatImages',
  async (
    input: { date: number; loggedInUserId: string; loggedInUsername: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { date } = input;

    const { selectedChat } = state.chat;

    const id = selectedChat?.id || '';

    const isGroup = !!selectedChat?.isGroup;

    return api.getChatImages(id, isGroup, date, LoadImagesDirection.Both, 10, token);
  },
);

export const getNextChatImages = createAsyncThunk(
  'async/getNewerChatImages',
  async (_input: { loggedInUserId: string; loggedInUsername: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const media = state.chat.selectedChat?.mediaGallery.media || [];

    const date = media[media.length - 1].date || 0;

    const { selectedChat } = state.chat;

    const id = selectedChat?.id || '';

    const isGroup = !!selectedChat?.isGroup;

    return api.getChatImages(id, isGroup, date, LoadImagesDirection.Next, 10, token);
  },
);

export const getPrevChatImages = createAsyncThunk(
  'async/getPrevChatImages',
  async (_input: { loggedInUserId: string; loggedInUsername: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    const id = selectedChat?.id || '';

    const isGroup = selectedChat?.isGroup || false;

    const date = state.chat.selectedChat?.mediaGallery.media[0].date || 0;

    return api.getChatImages(id, isGroup, date, LoadImagesDirection.Previous, 10, token);
  },
);

export const getShopInformation = createAsyncThunk(
  'async/getShopInformation',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.getShopInformation(id, token);
  },
);

export const getGroupInformation = createAsyncThunk(
  'async/getGroupInformation',
  async (
    input: { id: string; isLink: boolean; shouldNavigateToChat?: boolean },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { id, isLink } = input;

    const { token = '' } = state.user;

    return api.getGroupInformation(id, token, isLink);
  },
);

export const removeMemberFromGroup = createAsyncThunk(
  'async/removeMemberFromGroup',
  async (input: { userId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { userId } = input;

    const groupId = state.chat.selectedChat?.id || '';

    const { token = '' } = state.user;

    return api.removeMember(groupId, userId, token);
  },
);

export const getOlderMessages = createAsyncThunk(
  'async/getOlderMessages',
  async (
    _input: { loggedInUserId: string; loggedInUsername: string; loggedInUserFullName: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve({ data: [], blocked: false });
    }

    const maxTime = selectedChat.messages[0]?.date || null;

    return api.getOlderMessages(selectedChat.id, selectedChat.isGroup, maxTime, token);
  },
);

export const loadReplyMessages = createAsyncThunk(
  'async/onReplyClick',
  async (
    input: {
      replyDate: number;
      loggedInUserId: string;
      loggedInUsername: string;
      loggedInUserFullName: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { replyDate } = input;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve({ data: [], blocked: false });
    }

    return api.getBothDirectionMessages(
      selectedChat.id || '',
      selectedChat.isGroup,
      replyDate,
      token,
    );
  },
);

export const getInitialMessages = createAsyncThunk(
  'async/getInitialMessages',
  async (
    _input: { loggedInUserId: string; loggedInUsername: string; loggedInUserFullName: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve({ data: [], blocked: false });
    }

    return api.getOlderMessages(selectedChat.id, selectedChat.isGroup, null, token);
  },
);

export const getBothMessages = createAsyncThunk(
  'async/getBothMessages',
  async (
    _input: { loggedInUserId: string; loggedInUsername: string; loggedInUserFullName: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve({ data: [], blocked: false });
    }

    return api.getBothDirectionMessages(
      selectedChat.id,
      selectedChat.isGroup,
      selectedChat.selectedMessageDate || Date.now(),
      token,
    );
  },
);

export const onAdminToggle = createAsyncThunk(
  'async/addAdmin',
  async (input: { userId: string; shouldMakeAdmin: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { userId, shouldMakeAdmin } = input;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve();
    }

    const { id } = selectedChat;

    return api.onAdminToggle(id, userId, shouldMakeAdmin, token);
  },
);

export const getNewerMessages = createAsyncThunk(
  'async/getNewerMessages',
  async (
    _input: { loggedInUserId: string; loggedInUsername: string; loggedInUserFullName: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedChat } = state.chat;

    if (!selectedChat) {
      return Promise.resolve({ data: [], blocked: false });
    }
    let time = 0;

    if (selectedChat.messages.length) {
      time = selectedChat.messages[selectedChat.messages.length - 1].date;
    }

    return api.getNewerMessages(selectedChat.id, selectedChat.isGroup, time, token);
  },
);

export const getGroupLink = createAsyncThunk(
  'async/getGroupLink',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return api.getGroupLink(id, token);
  },
);

export const joinGroup = createAsyncThunk(
  'async/joinGroup',
  async (input: { id: string; isLink: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, isLink } = input;

    if (isLink) {
      return api.joinGroupFromLink(id, token);
    }

    return api.joinGroupViaInvite(id, token);
  },
);

export const blockUser = createAsyncThunk('async/blockUser', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { selectedChat } = state.chat;

  const { token } = state.user;

  return api.blockUser(selectedChat?.id || '', token || '');
});

export const unblockUser = createAsyncThunk('async/unblockUser', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { selectedChat } = state.chat;

  const { token } = state.user;

  return api.unblockUser(selectedChat?.id || '', token || '');
});

export const onChatSearch = createAsyncThunk(
  'async/onChatSearch',
  async (
    _input: {
      loggedInUserId: string;
      loggedInUsername: string;
      loggedInUserFullName: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const search = state.chat.selectedChat?.searchMessages.search || '';

    const chatId = state.chat.selectedChat?.id || '';

    const skip = state.chat.selectedChat?.searchMessages.skip || 0;

    const { token = '' } = state.user;

    return api.getSearchMessages(chatId, search, skip, token);
  },
);

export const getChatFeed = createAsyncThunk(
  'async/getChatFeed',
  async (input: { chatId: string; token: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { chatId } = input;

    const { list } = getSelectedFeed(state.feed);
    let maxId: string | undefined;
    if (list && !isEmptyArray(list)) {
      const lastPost = list[list.length - 1];
      maxId = lastPost.orderId || lastPost.id;
    }

    return api.getGroupChatPosts(chatId, token, maxId);
  },
);

export const unExpireMessage = createAsyncThunk(
  'async/unExpireMessage',
  async (input: { messageId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { messageId } = input;

    return api.unexpireMessage(messageId, token);
  },
);

export const reportMessage = createAsyncThunk(
  'async/reportMessage',
  async (input: { messageId: string; option: string; extra?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { messageId, option, extra } = input;

    return api.reportMessage(token, messageId, option, extra);
  },
);
