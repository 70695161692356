import './createPost.scss';
import { useDispatch } from 'react-redux';
import { FC } from 'react';
import { CircleButton, Tooltip } from 'tedooo-web-design-system/ui';
import { AlbumIcon, TagIcon } from 'tedooo-web-design-system/icons';

import { translations } from 'translations/en';
import { Avatar, Button } from 'components/helpers';
import { useTedoooSelector } from 'store/Store';
import { UserSelector } from 'store/user/UserSelector';
import { PopupSlicer } from 'store/popup/PopupActions';
import { ICreatePost, popupIdsOptions } from 'types';
import { useRequireToken } from 'hooks/useRequireToken';

const CreatePost: FC<ICreatePost> = ({ onUserClick, isSeller }) => {
  const dispatch = useDispatch();

  const onOpen = useRequireToken(() =>
    dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.createPostPopup })),
  );

  const onCatalogClick = () =>
    dispatch(
      PopupSlicer.actions.show({ id: popupIdsOptions.createPostPopup, shouldOpenCatalog: true }),
    );

  const onImageClick = useRequireToken(() => {
    const options = { id: popupIdsOptions.createPostPopup, shouldOpenImagePicker: true };
    dispatch(PopupSlicer.actions.show(options));
  });

  const avatar = useTedoooSelector(UserSelector.defaultAvatarSelector);

  const postText = isSeller
    ? translations.FEED.POST.SHARE_THOUGHTS_SELLER
    : translations.FEED.POST.SHARE_THOUGHTS;

  return (
    <div className="create-post-container">
      <Avatar onClick={onUserClick} src={avatar} size={40} />
      <Button className="start-post-button" text={postText} onClick={onOpen} />
      <Tooltip placement="bottom" text={translations.FEED.POST.ADD_IMAGES}>
        <CircleButton
          type="secondary-gray"
          size="medium"
          icon={<AlbumIcon />}
          onClick={onImageClick}
        />
      </Tooltip>
      {isSeller && (
        <Tooltip placement="bottom" text={translations.FEED.POST.SHARE_ITEM_FROM_CATALOG}>
          <CircleButton
            type="secondary-gray"
            size="medium"
            icon={<TagIcon />}
            onClick={onCatalogClick}
            className="catalog-button"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default CreatePost;
