import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ContactUsReducer } from './ContactUsReducer';
import { IContactUsState, ServerStatus } from 'types';

const initialState: IContactUsState = {
  isLoading: false,
  status: ServerStatus.PENDING,
};

const reducer = new ContactUsReducer();

export const onContactSubmit = createAsyncThunk(
  'async/submitContactUs',
  async (input: { token?: string; text: string; name: string; email: string }) => {
    const { text, name, email, token } = input;

    return reducer.onSubmit(text, name, email, token);
  },
);

export const ContactUsSlicer = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    resetValues: (state) => {
      state.isLoading = false;
      state.status = ServerStatus.PENDING;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onContactSubmit.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(onContactSubmit.fulfilled, (state) => {
      state.isLoading = false;
      state.status = ServerStatus.SUCCESS;
    });
    builder.addCase(onContactSubmit.rejected, (state) => {
      state.isLoading = false;
      state.status = ServerStatus.ERROR;
    });
  },
});
