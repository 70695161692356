import { Middleware } from 'redux';

import { getSuggestedShops, getUserPage } from './ProfileActions';
import { ShopSlicer } from '../shop/ShopActions';
import { SearchResultSlicer } from '../searchResult/SearchResultActions';
import { ProfileSlicer } from './ProfileSlicer';
import { IStore2 } from '../types';
import { isEmptyArrayOrUndefined } from 'utils';

export const profileMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case getUserPage.fulfilled.type:
        const state: IStore2 = getState();

        const { userId } = action.meta.arg;

        if (action.payload.shop) {
          const { shop } = action.payload;
          dispatch(ShopSlicer.actions.updateDetails({ shop }));
        }
        if (action.payload.searchResult) {
          const { total, data } = action.payload.searchResult;
          dispatch(SearchResultSlicer.actions.updateSearchResults({ data, total, userId }));
        }
        if (action.payload.user) {
          const { user } = action.payload;
          dispatch(ProfileSlicer.actions.updateProfileDetails({ user }));
          if (isEmptyArrayOrUndefined(state.profile.suggestedShops)) {
            dispatch(getSuggestedShops(user.id));
          }
        }
        break;

      default:
        break;
    }

    return next(action);
  };
