export const parseObject = (str: string | null) => {
  try {
    if (!str) {
      return {};
    }

    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export const stringify = (value: object) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return '';
  }
};

export const isEmptyObject = (value: object) => {
  return Object.keys(value).length === 0;
};
