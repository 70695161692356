import { gql } from '@apollo/client';

export const STRIPE_BASKET_CHECKOUT_DETAILS_MUTATION = gql`
  mutation CreatePaymentIntentStripe(
    $checkoutId: String!
    $shippingRateId: String
    $couponCode: String
  ) {
    createPaymentIntentStripe(
      checkoutId: $checkoutId
      shippingRateId: $shippingRateId
      couponCode: $couponCode
    ) {
      clientId
      paymentId
      merchantDisplayName
      customerId
      customerToken
      paymentIntentSecret
      price {
        price
        currencyCode
        currency
      }
    }
  }
`;

export const GET_BASKET_CHECKOUT_DETAILS_QUERY = gql`
  query GetInstantCheckoutForCheckoutId($checkoutId: String!) {
    getInstantCheckoutForCheckoutId(checkoutId: $checkoutId) {
      rates {
        rateId
        tags
        image
        name
        price {
          price
          currencyCode
          currency
        }
        arrivalDate
      }
      payments {
        stripe {
          id
          title
          description
          buttonText
          imageUrls
        }
        paypal
      }
    }
  }
`;

export const CHECK_COUPON_CODE = gql`
  query CheckCouponCode($couponCode: String!, $shopId: String!, $cartPrice: Long!) {
    checkCouponCode(couponCode: $couponCode, cartPrice: $cartPrice, shopId: $shopId) {
      ... on CheckCouponCodeResponseSuccess {
        newPrice
      }
      ... on CheckCouponCodeResponseError {
        code
        reason
      }
    }
  }
`;

export const GET_BASKET_PAYMENTS_DETAILS_QUERY = gql`
  query GetInstantCheckoutForCheckoutId($checkoutId: String!) {
    getInstantCheckoutForCheckoutId(checkoutId: $checkoutId) {
      payments {
        stripe {
          id
          title
          description
          buttonText
          imageUrls
        }
        paypal
      }
    }
  }
`;

export const GET_VISION_STRIPE_PAYOUT_DETAILS_QUERY = gql`
  mutation CreateStripeCheckoutForVisionOffer($shippingRateId: String!, $offerId: String!) {
    createStripeCheckoutForVisionOffer(shippingRateId: $shippingRateId, offerId: $offerId) {
      clientId
      paymentId
      merchantDisplayName
      customerId
      customerToken
      paymentIntentSecret
      price {
        price
        currencyCode
        currency
      }
    }
  }
`;

export const GET_VISION_PAYPAL_LINK_QUERY = gql`
  mutation CreatePaypalCheckoutForVisionOffer($shippingRateId: String!, $offerId: String!) {
    createPaypalCheckoutForVisionOffer(shippingRateId: $shippingRateId, offerId: $offerId) {
      link
    }
  }
`;

export const GET_BASKET_PAYPAL_LINK_QUERY = gql`
  mutation CreatePaypalCheckout(
    $shippingRateId: String
    $checkoutId: String!
    $couponCode: String
  ) {
    createPaypalCheckout(
      shippingRateId: $shippingRateId
      checkoutId: $checkoutId
      couponCode: $couponCode
    ) {
      link
    }
  }
`;
