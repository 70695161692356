import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICreateShopCatalogItem, IStore2 } from 'types';
import { CreateShopApi } from './CreateShopApi';
import { ShopReducer } from '../shop/ShopReducer';
import { parseLegacyPaymentMethods, parseProduct, parseToCreateShopRequest } from './helper';

const createShopApi = new CreateShopApi();

const shopApi = new ShopReducer();

export const createShop = createAsyncThunk('async/createShop', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const {
    shopName,
    shopDescription,
    country,
    catalog,
    coverPhoto,
    socialLinks,
    phoneNumber,
    email,
    shopId,
    deletedProductsIds,
    addedProductsIds,
    currencyCode,
    legacyPayoutMethods,
    initialCatalogOrder,
  } = state.createShop;

  const { token = '' } = state.user;

  const parsedRequest = parseToCreateShopRequest(
    shopName,
    shopDescription,
    country,
    catalog.items,
    coverPhoto,
    socialLinks,
    phoneNumber,
    email,
    shopId,
    deletedProductsIds,
    addedProductsIds,
    currencyCode,
    legacyPayoutMethods,
    initialCatalogOrder,
  );

  return createShopApi.createShop(token, parsedRequest);
});

export const getShopInformation = createAsyncThunk(
  'async/getCreateShopInformation',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '', loggedInUserShopId = null } = state.user;

    return shopApi.getShopInformation(token, loggedInUserShopId);
  },
);

export const getProductsFromCsv = createAsyncThunk(
  'async/getProductsFromCsv',
  async (csv: string, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { currencyCode } = state.createShop;

    return createShopApi.getProductsFromCsv(token, csv, currencyCode);
  },
);

export const addItemToCatalog = createAsyncThunk(
  'async/addItemToCatalog',
  async (input: { item: ICreateShopCatalogItem }, { getState }: any) => {
    const state: IStore2 = getState();

    const { item } = input;

    const { shopId } = state.createShop;

    const { token = '' } = state.user;

    const parsedProduct = parseProduct(item);

    return createShopApi.addItemToCatalog(token, shopId || '', parsedProduct);
  },
);

export const editSpecificListing = createAsyncThunk(
  'async/editSpecificListing',
  async (input: { item: ICreateShopCatalogItem }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { item } = input;

    const parsedProduct = parseProduct(item);

    return createShopApi.editSpecificListing(token, parsedProduct.id || '', parsedProduct);
  },
);

export const updateLegacyPaymentSection = createAsyncThunk(
  'async/updateLegacyPaymentSection',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { legacyPayoutMethods } = state.createShop;

    const { loggedInUserShopId } = state.user;

    const paymentMethods = parseLegacyPaymentMethods(legacyPayoutMethods);

    const body = { paymentMethods };

    return createShopApi.updateShop(token, loggedInUserShopId || '', body);
  },
);

export const getProductCategories = createAsyncThunk(
  'async/getProductCategories',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return createShopApi.getProductCategories(token);
  },
);

export const getMyShop = createAsyncThunk('async/getMyShop', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { loggedInUserShopId } = state.user;

  const { token } = state.user;

  return shopApi.getShop(loggedInUserShopId || ' ', token);
});

export const getProductCsvProgress = createAsyncThunk(
  'async/getUploadingCsvStatus',
  async (requestId: string, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return createShopApi.getUploadingCsvStatus(token, requestId);
  },
);
