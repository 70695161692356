import {
  CategoryResponseOptions,
  IProduct,
  ImageComposition,
  IPost,
  IPriceResponse,
  ISubPost,
  PostActionType,
  DiscoverListsId,
} from 'types';
import { DiscoverPageResult } from 'graphql/generated/graphql';

export * from '../post/types';

export interface ISubPostResponseApi {
  _id: string;
  likes: number;
  comments: number;
  didLike: boolean;
}

export interface ILinkResponseApi {
  link: string;
}

export interface IFeedProfileState {
  name: string;
  extraInformation: string;
  avatar: string | null;
  coverPhoto: string | null;
  totalFollowers: number;
}

export interface IFeedProfile {
  name: string;
  extraInformation: string;
  avatar: string | null;
  coverPhoto: string | null;
  totalFollowers: number;
  bio: string | null;
  totalFollowing: number | null;
  totalProfileViews: number | null;
}

export interface IFeedState {
  mainFeed: IFeedStore;
  chatFeed: IFeedStore;
  searchFeed: IFeedStore;
  userFeed: IFeedStore;
  feedBubbles: IFeedBubble[] | null;
  repostsFeed: { list: IPost[] | null; hasMore: boolean };
  impressedPosts: { [key: string]: boolean };
  selectedFeedType: FeedTypes | null;
  mapPostIdToSubPosts: { [key: string]: Array<ISubPost> | undefined };
  selectedSubPostId: string | null;
  selectedPostId: string | null;
  selectedCommentId: string | null;
  selectedReplyId: string | null;
  isLoading: boolean;
  profile: IFeedProfileState | null;
  createPostDetails: CreatePostDetails;
  stories: IStory[] | null;
}
interface CreatePostDetails {
  shops: Array<IShopShortInfo>;
  isCatalogLoading: boolean;
  isLoading: boolean;
}

export interface IFeedStore {
  list: IFeedPost[] | null;
  hasMore: boolean;
  isLoading: boolean;
  postIndexToScroll: number;
  paginationToken: string | null;
  selectedFeedBubble: string | null;
}

export interface IFeedPost extends IPost {
  order: number;
  orderId?: string;
}

export interface IFeedBubble {
  id: string;
  text: string;
}

export interface IStaticItem {
  extras?: any;
  id: string;
  key: string;
}

export enum FeedTypes {
  User = 'user',
  Chat = 'chat',
  Feed = 'feed',
  Search = 'search',
  Reposts = 'reposts',
}

export interface IStoryResponse {
  avatar: string;
  moments: Array<{ _id: string; url: string; time: number }>;
  byUser: string;
  username: string;
  fullName: string;
}

export interface IStory {
  stories: { _id: string; url: string; time: number }[];
  username: string;
  fullName: string;
  avatar: string;
  userId: string;
}

export interface IFeedCommentResponseApi {
  text: string;
  _id: string;
  image?: string;
  userId: string;
  date: number;
  deleted: true;
}

export interface IFeedResponseItemApi {
  _id: string;
  userId: string;
  username: string;
  avatar?: string;
  shopId?: string;
  shopName: string;
  shopImage?: string;
  fullName: string;
  text: string;
  images: Array<string>;
  backgroundText?: string;
  backgroundIndex?: number;
  isShoppingPost?: boolean;
  composition: ImageComposition;
  ratio: number;
  didLike: boolean;
  didRepost: boolean;
  comments: number;
  likes: number;
  reposts: number;
  repostId?: string;
  repostDetails?: IRepostDetailsResponse;
  date: number;
  lastEdited: number;
  editable: boolean;
  // 0 none, 1 like 2 comment
  reason: PostActionType;
  // for like username
  reasonExtra?: string;
  price?: IPriceResponse;
  productId?: string;
  // 1 buy and sell, 0 regular
  category: CategoryResponseOptions;
  following: boolean;
  isFollowing: boolean;
  lastComment: ICommentItemResponseApi;
  selfComments?: Array<IFeedCommentResponseApi>;
  premium: boolean;
  title?: string;
  totalFollowers?: number;
  totalFollowing?: number;
  orderId?: string;
  tags?: { [key: string]: string };
  shouldHideDate?: boolean;
}

export interface IRepostDetailsResponse {
  avatar: string;
  backgroundIndex?: number;
  backgroundText?: string;
  comments: number;
  composition: number;
  date: number;
  fullName: string;
  groupId?: string;
  groupName?: string;
  images?: string[];
  lastComment: ICommentItemResponseApi;
  lastEdited: number;
  shopName: string;
  shopId: string;
  following: boolean;
  text: string;
  title?: string;
  price?: IPriceResponse;
  productId?: string;
  shopImage?: string;
  reposts: number;
  likes: number;
  ratio: number;
  userId: string;
  username: string;
  _id: string;
  premium: boolean;
  isFollowing: boolean;
  tags: { [key: string]: string };
  isShoppingPost?: boolean;
  shouldHideDate?: boolean;
}

export interface IFeedResponseApi {
  hasMore: boolean;
  data: Array<IFeedResponseItemApi>;
}

export interface ITaggedUserResponseApi {
  tag: string;
  userId: string;
  username: string;
  avatar?: string;
}

export interface ICommentItemResponseApi {
  _id: string;
  id: string;
  userId: string;
  avatar?: string;
  username: string;
  fullName: string;
  text: string;
  image?: string;
  date: number;
  replies?: number;
  premium: boolean;
  likes: number;
  didLike: boolean;
  taggedUser: Array<ITaggedUserResponseApi>;
  deleted?: boolean;
}

export interface ICommentsResponseApi {
  total: number;
  hasMore: boolean;
  data: Array<ICommentItemResponseApi>;
}

export interface ILikeResponseApi {
  _id: string;
  userId: string;
  username: string;
  shopName: string;
  fullName: string;
  premium: boolean;
  following: boolean;
  avatar?: string;
  shopId?: string;
  date: number;
}

export interface ILikesResponseApi {
  data: Array<ILikeResponseApi>;
  hasMore: boolean;
}

export interface IShopBaseInfoResponse {
  name: string;
  image?: string;
  totalFollowers: number;
}

export interface IUserInfoResponse {
  username: string;
  fullName: string;
  shopName: string;
  bio: string;
  premium: boolean;
  following: boolean;
  avatar?: string;
  coverPhoto?: string;
  totalFollowers: number;
}

export interface IFeedApi {
  getPosts(
    token: string,
    listId: DiscoverListsId,
    paginationToken: string,
  ): Promise<DiscoverPageResult>;

  getShopPosts(shopId: string, token: string, maxId?: string): Promise<IFeedResponseApi>;

  getUserPosts(userId: string, token: string, maxId?: string): Promise<IFeedResponseApi>;

  getShops(token: string): Promise<IShopShortInfoResponse[]>;

  getShopProfile(shopId: string, token: string): Promise<IShopBaseInfoResponse>;

  getUserProfile(userId: string, token: string): Promise<IUserInfoResponse>;

  getStories(token: string): Promise<any>;
}

export interface IShopShortInfoResponse {
  follows: number;
  img_links: string;
  name: string;
  shop_number: string;
  _id: string;
}

export interface IShopShortInfo {
  id: string;
  name: string;
  shopImage: string;
  catalog: IProduct[] | null;
}

export interface ICreatePostResponse {
  id: string;
}

export interface IGetRepostsResponse {
  hasMore: boolean;
  data: IRepostsResponse[];
}

export interface IRepostsResponse {
  date: number;
  id: string;
  didLike: boolean;
  userDetails: {
    avatar: string;
    id: string;
    name: string;
    username: string;
  };
  repostDetails?: {
    comments: number;
    didLike: boolean;
    likes: 0;
    text: string;
    isFollowing: boolean;
    isPremium: boolean;
    shopDetails: { id: string; avatar: string; name: string } | null;
    isShoppingPost?: boolean;
    lastEdited: number;
  };
}
