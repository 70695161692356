import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import sessionStorage from 'redux-persist/es/storage/session';

import { UserSlicer } from './user/UserActions';
import { userMiddleware } from './user/UserMiddleware';
import { ContactUsSlicer } from './contactUs/ContactUsActions';
import { SearchResultSlicer } from './searchResult/SearchResultActions';
import { MenuSlicer } from './menu/MenuActions';
import { PopupSlicer } from './popup/PopupActions';
import { ForgetPasswordSlicer } from './forgetPassword/ForgetPasswordActions';
import { ShopSlicer } from './shop/ShopActions';
import { ProfileSlicer } from './profile/ProfileSlicer';
import { ReviewSlicer } from './review/ReviewActions';
import { IStore2 } from 'types';
import { reviewMiddleware } from './review/ReviewMiddleware';
import { FeedSlicer } from './feed/FeedSlicer';
import { SpecificPostSlicer } from './specificPost/SpecificPostSlicer';
import { feedMiddleware } from './feed/FeedMiddleware';
import { specificPostMiddleware } from './specificPost/SpecificPostMiddleware';
import { MessageSlicer } from './message/MessageActions';
import { menuMiddleware } from './menu/MenuMiddleware';
import { NotificationsSlicer } from './notifications/NotificationsSlicer';
import { DrawerSlicer } from './drawers/DrawerActions';
import { amplitudeMiddleware } from './amplitude/amplitudeMiddleware';
import { SettingsSlicer } from './settings/SettingsSlicer';
import { settingsMiddleware } from './settings/SettingsMiddleWare';
import { GetTheAppSlicer } from './getTheApp/GetTheAppActions';
import { CreateShopSlicer } from './createShop/CreateShopSlicer';
import { createShopMiddleware } from './createShop/CreateShopMiddleware';
import { shopMiddleware } from './shop/ShopMiddleware';
import { profileMiddleware } from './profile/ProfileMiddleware';
import { searchResultMiddleware } from './searchResult/SearchResultMiddleware';
import { ChatSlicer } from './chat/ChatSlicer';
import { chatMiddleware } from './chat/ChatMiddleware';
import { MentionsSlicer } from './mentions/MentionsSlicer';
import { OnboardingSlicer } from './onboarding/OnboardingSlicer';
import { onboardingMiddleware } from './onboarding/OnboardingMiddleware';
import { SpecificOrderSlicer } from './specificOrder/SpecificOrderSlicer';
import { OrdersSlicer } from './orders/OrdersSlicer';
import { ordersMiddleware } from './orders/OrdersMiddleware';
import { PayoutsSlicer } from './payouts/PayoutsSlicer';
import { payoutsMiddleware } from './payouts/PayoutsMiddleware';
import { specificOrderMiddleware } from './specificOrder/SpecificOrderMiddleware';
import { SpecificProductSlicer } from './specificProduct/SpecificProductSlicer';
import { ShippingSlicer } from './shipping/ShippingSlicer';
import { shippingMiddleware } from './shipping/ShippingMiddleware';
import { ListingSlicer } from './listing/ListingSlicer';
import { specificProductMiddleware } from './specificProduct/SpecificProductMiddleware';
import { ProductsSlicer } from './products/ProductsSlicer';
import { ConfigSlicer } from './config/ConfigSlicer';
import { DiscoverSlicer } from './discover/DiscoverSlicer';
import { mapPersistedKeysToStorageKeyName, PersistedKeys } from './helper';
import { MojosSlicer } from './mojos/MojosSlicer';
import { VisionsSlicer } from './visions/VisionsSlicer';
import { GenerateVisionSlicer } from './generateVision/GenerateVisionSlicer';
import { SpecificVisionSlicer } from './specificVision/SpecificVisionSlicer';
import { VisionsListSlicer } from './visionsList/VisionsListSlicer';
import { visionsMiddleware } from './visions/VisionsMiddleware';
import { SpecificVisionRequestSlicer } from './specificVisionRequest/SpecificVisionRequestSlicer';
import { SendVisionOfferSlicer } from './sendVisionOffer/SendVisionOfferSlicer';
import { notificationsMiddleware } from './notifications/NotificationsMiddleware';
import { BasketSlicer } from './basket/BasketSlicer';
import { basketMiddleware } from './basket/BasketMiddleware';
import { CheckoutSlicer } from './checkoutForNonSignupUsers/CheckoutSlicer';
import { checkoutMiddleware } from './checkoutForNonSignupUsers/CheckoutMiddleware';

const persistMenuConfig = {
  key: PersistedKeys.Menu,
  storage,
  whitelist: ['recentSearches'],
};

const persistUserConfig = {
  key: PersistedKeys.User,
  storage: sessionStorage,
};

const persistShippingConfig = {
  key: PersistedKeys.Shipping,
  storage: sessionStorage,
  whitelist: ['shippingProfiles'],
};

const persistCreateShopConfig = {
  key: PersistedKeys.CreateShop,
  storage: sessionStorage,
};

const persistMentionsConfig = {
  key: PersistedKeys.Mentions,
  storage,
  whitelist: ['cachedUsers'],
};

const persistSettingsConfig = {
  key: PersistedKeys.Settings,
  storage: sessionStorage,
  whitelist: ['shippingAddresses'],
};

const persistListingConfig = {
  key: PersistedKeys.Listing,
  storage: sessionStorage,
};

const persistFeedConfig = {
  key: PersistedKeys.Feed,
  storage: sessionStorage,
  whitelist: ['impressedPosts'],
};

const persistDiscoverConfig = {
  key: PersistedKeys.Discover,
  storage: sessionStorage,
  whitelist: ['categories'],
};

const persistGenerateVisionsConfig = {
  key: PersistedKeys.GenerateVision,
  storage: sessionStorage,
  whitelist: ['results', 'promptDetails', 'isVisibleToPublic'],
};

const persistAppConfig = {
  key: PersistedKeys.Config,
  storage: sessionStorage,
  whitelist: ['featureFlags'],
};

const rootPersistConfig = {
  key: PersistedKeys.Root,
  storage,
  whitelist: [],
};

const appReducer = combineReducers({
  config: persistReducer(persistAppConfig, ConfigSlicer.reducer),
  discover: persistReducer(persistDiscoverConfig, DiscoverSlicer.reducer),
  chat: ChatSlicer.reducer,
  user: persistReducer(persistUserConfig, UserSlicer.reducer),
  shop: ShopSlicer.reducer,
  menu: persistReducer(persistMenuConfig, MenuSlicer.reducer),
  mentions: persistReducer(persistMentionsConfig, MentionsSlicer.reducer),
  createShop: persistReducer(persistCreateShopConfig, CreateShopSlicer.reducer),
  contact: ContactUsSlicer.reducer,
  searchResult: SearchResultSlicer.reducer,
  profile: ProfileSlicer.reducer,
  popup: PopupSlicer.reducer,
  forgetPassword: ForgetPasswordSlicer.reducer,
  notifications: NotificationsSlicer.reducer,
  reviews: ReviewSlicer.reducer,
  drawer: DrawerSlicer.reducer,
  message: MessageSlicer.reducer,
  checkoutForNonSignupUsers: CheckoutSlicer.reducer,
  feed: persistReducer(persistFeedConfig, FeedSlicer.reducer),
  settings: persistReducer(persistSettingsConfig, SettingsSlicer.reducer),
  specificPost: SpecificPostSlicer.reducer,
  getTheApp: GetTheAppSlicer.reducer,
  onboarding: OnboardingSlicer.reducer,
  specificOrder: SpecificOrderSlicer.reducer,
  orders: OrdersSlicer.reducer,
  payouts: PayoutsSlicer.reducer,
  specificProduct: SpecificProductSlicer.reducer,
  shipping: persistReducer(persistShippingConfig, ShippingSlicer.reducer),
  listing: persistReducer(persistListingConfig, ListingSlicer.reducer),
  products: ProductsSlicer.reducer,
  mojos: MojosSlicer.reducer,
  visions: VisionsSlicer.reducer,
  generateVision: persistReducer(persistGenerateVisionsConfig, GenerateVisionSlicer.reducer),
  specificVision: SpecificVisionSlicer.reducer,
  visionsList: VisionsListSlicer.reducer,
  specificVisionRequest: SpecificVisionRequestSlicer.reducer,
  sendVisionOffer: SendVisionOfferSlicer.reducer,
  basket: BasketSlicer.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === UserSlicer.actions.signOut.type) {
    Object.keys(mapPersistedKeysToStorageKeyName).forEach((key) => {
      sessionStorage.removeItem(mapPersistedKeysToStorageKeyName[key]);
    });
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      userMiddleware,
      reviewMiddleware,
      feedMiddleware,
      menuMiddleware,
      specificPostMiddleware,
      amplitudeMiddleware,
      settingsMiddleware,
      createShopMiddleware,
      shopMiddleware,
      profileMiddleware,
      checkoutMiddleware,
      searchResultMiddleware,
      chatMiddleware,
      onboardingMiddleware,
      specificOrderMiddleware,
      ordersMiddleware,
      payoutsMiddleware,
      shippingMiddleware,
      specificProductMiddleware,
      visionsMiddleware,
      notificationsMiddleware,
      basketMiddleware,
    ]),
});

export const persist = persistStore(store);

export const useTedoooSelector: TypedUseSelectorHook<IStore2> = useSelector;
