export const translations = {
  COMMON: {
    COUPON: 'COUPON',
    SIGN_UP: 'Sign up',
    LOGIN: 'Log In',
    LOGIN_OR_SIGNUP: 'Log in or Sign up',
    SIGN_OUT: 'Sign Out',
    LOGOUT: 'logout',
    FOLLOW: 'Follow',
    EDIT: 'Edit',
    REMOVE: 'Remove',
    DOWNLOAD: 'Download',
    EXIT: 'Exit',
    INVITE: 'Invite',
    JOIN_NOW: 'Join Now',
    NOT_YET: 'Not yet',
    MARK_AS_DELIVERED: 'Yes, mark as delivered',
    WITHDRAW: 'Withdraw',
    SELECTED: 'Selected',
    TRANSLATED: 'Translated',
    FORWARDED: 'Forwarded',
    JOIN: 'Join',
    ADD: 'Add',
    NEXT: 'Next',
    SEND: 'Send',
    SENT: 'Sent',
    PUBLIC: 'Public',
    FINISH_SIGN_UP: 'Finish sign up',
    VIEW_PROFILE: 'View Profile',
    LIKED: 'Liked',
    SUBMIT: 'Submit',
    SUGGESTED: 'Suggested',
    DONE: 'Done',
    CREATE: 'Create',
    REMOVE_ALL: 'Remove all',
    UNFOLLOW: 'Unfollow',
    YOU: 'You',
    CONTINUE: 'Continue',
    CONNECT: 'Connect',
    SKIP: 'Skip',
    SKIP_FOR_NOW: 'Skip for now',
    SAVE: 'Save',
    DISCOVER: 'Discover',
    CONGRATULATION: 'Congratulation',
    AWESOME: 'Awesome!',
    COMPANY: 'Company',
    FOLLOWERS: 'Followers',
    FOLLOWER: 'Follower',
    FOLLOWING: 'Following',
    OF_5: ' of 5',
    OF: 'of',
    SHOPS: 'Shops',
    ACTIVITY: 'Activity',
    UPLOAD: 'Upload',
    UNDERSTOOD: 'Understood',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
    FORWARD: 'Forward',
    REVIEWS: 'reviews',
    REVIEW: 'review',
    NO_FOLLOWERS: 'No followers yet',
    NO_REVIEWS: 'No reviews yet',
    REVIEWED: 'reviewed',
    REPORT: 'Report',
    BLOCK: 'Block',
    ALL: 'All',
    CREATE_NOW: 'Create now!',
    REPLY: 'Reply',
    SHOP_CATEGORY: 'Shop category',
    SHIPPING_TO: 'Shipping to',
    PAYMENT_METHODS: 'Payment methods',
    SHIPPING_METHODS: 'Shipping methods',
    LIKE: 'Like',
    LIKES: 'Likes',
    AT: 'at',
    SHARE: 'Share',
    REPLIES: 'Replies',
    BACK: 'Back',
    ABOUT: 'About',
    OR: 'or',
    MOST_RECENT: 'Most recent',
    AND: 'and',
    MUTUAL: 'Mutual',
    INFO: 'Info',
    ACTIVITIES: 'Activities',
    GROUPS: 'Groups',
    NAME: 'name',
    IMAGE: 'image',
    GROUP: 'Group',
    JUST_NOW: 'Just now',
    PHOTOS: 'Photos',
    MESSAGE: 'Message',
    GET_THE_APP: 'Get the app',
    AGO: 'ago',
    ACCOUNT: 'Account',
    MANAGE: 'Manage',
    MORE: 'more',
    PRIVATE: 'Private',
    HIGHLIGHTS: 'Highlights',
    ME: 'Me',
    PUBLISH: 'Publish',
    PRO: 'Pro',
    NEW_ITEMS: 'New Items!',
    DESCRIPTION: 'Description',
    SEE_ALL: 'See all',
    APPLY: 'Apply',
    RESET: 'Reset',
    SHOW_MORE: 'Show more',
    BUY_ITEM: 'Buy item',
    VIEW_ITEM: 'View item',
    SHARE_ITEM: 'Share item',
    AUTHOR: 'Author',
    OTHER: 'Other',
    LATER: 'Later',
    REQUESTED: 'Requested',
    UNBLOCK: 'Unblock',
    LEAVE: 'Leave',
    PASSWORD: 'Password',
    COUNTRIES: 'Countries',
    COUNTRY: 'Country',
    QTY: 'Qty:',
    VIEW: 'View',
    SOON: 'SOON!',
    DECLINE_AND_SEND_FEEDBACK: 'Decline and send feedback',
    TRY_AGAIN: 'Try again',
    THANK_YOU: 'Thank you!',
    GOT_IT_THANKS: 'Got it, thanks!',
    PROCESSING: 'Processing ...',
    REQUESTING: 'Requesting ...',
    LEARN_MORE: 'Learn more',
    VIEW_ORDER: 'View order',
    EDIT_ADDRESS: 'Edit address',
    CONFIRM_AND_BUY: 'Confirm & Buy',
    EMAIL: 'Email',
    MESSAGE_SELLER: 'Message Seller',
    FREE: 'FREE',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    ON: 'On',
    OFF: 'Off',
    TRY: 'Try',
  },
  ADD_COUPON_INPUT: {
    PLACEHOLDER: 'Coupon code or gift card',
    DISCOUNT_ACCOUNT: 'Discount applied',
  },
  FILTER: {
    MIN_RATING: 'Minimum Rating',
  },
  HOME: {
    DISCOVER_NOW: 'DISCOVER NOW',
    DISCOVER_ITEMS: 'Discover items',
    TITLE_PART_ONE: 'What are you ',
    TITLE_PART_TWO: 'looking for?',
    SEARCH_BY_CATEGORIES: 'Search by categories',
    BEST_SHOPS: {
      HIGHLIGHT: 'Highlights',
      DISCOVER_MORE: 'Discover more highlights',
    },
    SUGGESTED_SHOPS: 'Suggested shops',
    RECENTLY_SAVED_ITEMS: 'Recently saved items',
    FOLLOWING_SHOPS: 'Shops I follow',
    SEARCH: {
      RECENT_SEARCHES: 'Recent searches',
      RECENTLY_VIEWED_SHOPS: 'Recently viewed shops',
      TRENDING_SEARCHES: 'Trending searches',
    },
    FILTERS_POPUP: {
      TITLE: 'Filters',
      SORT: 'sort',
      RECOMMENDED: 'Recommended',
      RATING: 'Rating',
      MOST_REVIEWED: 'Most Reviewed',
      PRICE_HIGH_TO_LOW: 'Price: high to low',
      PRICE_LOW_TO_HIGH: 'Price: low to high',
      FROM_COUNTRY: 'From country',
    },
    GROUP_POPUP: {
      TITLE: 'Join Request',
      DESCRIPTION_PART_1: 'Your membership is pending approval.',
      DESCRIPTION_PART_2:
        'Write some details about yourself and why you wish to join, so the group',
      DESCRIPTION_PART_3: 'admin can review your request. Only the admins will see it.',
      LOCATION_QUESTION: 'Where are you from? (optional)',
      REASON_TO_JOIN_QUESTION: 'Why would you like to join? (optional)',
      TEXTAREA_PLACEHOLDER: 'Your answer',
    },
  },
  PAGES: {
    HOME: 'Home',
    ABOUT_US: 'About us',
    SETTINGS: 'Settings',
    HISTORY: 'My History',
    CONTACT_US: 'Contact us',
    CREATE_SHOP: 'Create Shop',
    COMPLETE_YOUR_SHOP: 'Complete your shop',
    OFFERS: 'Orders',
    MY_SHOPS: 'My Shops',
    DISCOVER: 'Discover',
    MOJOS: 'Mojos',
    FAVORITES: 'Favorite list',
    HELP: 'Help',
    LANGUAGE: 'Language',
    FOLLOWING: 'Following',
    TERMS: 'Terms of service',
    POSTS_ACTIVITY: 'Posts & Activity',
    PRIVACY_POLICY: 'Privacy policy',
    MESSAGING: 'Messaging',
    NOTIFICATIONS: 'Notifications',
    BECOME_A_MEMBER: 'Upgrade to Pro',
    RENEW_PRO: 'Renew Pro',
    VISIONS: 'Visions',
  },
  OFFERS: {
    ORDER: 'Order',
    MIXED_BUNDLE: 'Mixed bundle',
    DECLINE_REASON: 'Decline reason',
    NUMBER_OF_ITEMS: 'Number of items',
    SHIPPING_ADDRESS: 'Shipping address',
    TOTAL_WITHOUT_SHIPPING: 'Total without shipping',
    TOTAL_WITH_SHIPPING: 'Total with shipping',
    ORDER_COMPLETED: 'Your order is successfully delivered!',
    ORDER_DECLINED: 'declined the order',
    ORDER_SHIPPED: 'shipped the order',
    ADDED_SHIPPING_COST: 'added shipping cost and final price',
    SENT_THE_PAYMENT: 'sent you the payment',
    MARKED_PAYMENT_RECEIVED: 'marked payment received',
    REQUEST_TO_BUY: 'requested to buy',
  },
  SETTINGS: {
    TITLES: {
      SETTINGS: 'Settings',
      ACCOUNT_SETTINGS: 'Account settings',
      PREFERENCES: 'Preferences',
      PAYOUTS_AND_SHIPPING: 'Payouts & Shipping',
      SIGN_IN: 'Sign in and security',
      ACCOUNT: 'Account',
      SUBSCRIPTIONS: 'Subscriptions',
      BLOCKING: 'Blocking',
      CHAT_PREFERENCES: 'Chat preferences',
      NOTIFICATIONS: 'Notifications',
      GENERAL_PREFERENCES: 'General preferences',
    },
    ACCOUNT_SETTINGS: {
      TITLES: {
        FULL_NAME: 'Full name',
        USERNAME: 'Username',
        EMAIL: 'Email',
        BIRTHDAY: 'Birthday',
        DELETE_ACCOUNT: 'Delete Account',
        LANGUAGE: 'Language',
      },
      DESCRIPTION: {
        NO_EMAIL: 'No email inputed',
        NO_BIRTHDAY: 'No birthday provided',
      },
    },
    PREFERENCES: {
      TITLES: {
        LIVE_TRANSLATION: 'Live translation',
        SHOW_LOCAL_TIME: 'Show local time',
        SHOW_LAST_SEEN: 'Show last seen',
      },
      DESCRIPTIONS: {
        LIVE_TRANSLATION:
          'Automatically translate chat messages from other languages in private chats',
        SHOW_LOCAL_TIME:
          "By turning off your local time, you won't be able to see other people local time",
        SHOW_LAST_SEEN:
          'By turning off you last seen, you wont be able to see other people last seen',
      },
    },
    SHIPPING: {
      TITLES: {
        SHIPPING_PROFILES: 'Shipping Profiles',
        SHIPPING_ADDRESSES: 'Shipping Addresses',
      },
      DESCRIPTIONS: {
        NO_SHIPPING_ADDRESS: 'No shipping address provided',
        NO_SHIPPING_PROFILE: 'No shipping profile provided',
        SHIPPO_MANAGE: 'Manage Shippo account',
        SHIPPO_CONNECT: 'Connect to a shipping platform to get discounted rates.',
      },
    },
    NOTIFICATIONS: {
      PUSH: 'Push',
      EMAIL: 'Email',
      NONE: 'None',
      TITLES: {
        NEW_POST: 'People you follow new posts',
        PROFILE_VIEW: 'Who viewed your profile',
        NEW_ORDER: 'New Orders updates',
        GROUP_MESSAGES: 'Messages from the groups you joined',
        PRIVATE_MESSAGES: 'Private Messages',
      },
      DESCRIPTIONS: {
        POST_NOTIFICATIONS: 'Be notified on any post your followers will make',
      },
    },
    GENERAL_PREFERENCES: {
      TITLES: {
        CURRENCY: 'Currency',
        VACATION_MODE: 'Vacation mode',
      },
    },
    SIGN_IN_AND_SECURITY: {
      TITLES: {
        CHANGE_PASSWORD: 'Change password',
      },
    },
    BLOCKING: {
      TITLES: {
        BLOCKED_LIST: 'See your blocked list',
      },
      DESCRIPTIONS: {
        BLOCKED_LIST: "You've blocked number people",
        ZERO_BLOCKED_LIST: "You haven't blocked any user yet",
      },
    },
    POPUPS: {
      CHANGE_LANGUAGE: 'Change Language',
      CHANGE_PASSWORD: 'Change Password',
      FORGOT_PASSWORD: 'Forgot Password',
      FORGOT_PASSWORD_QUESTION: 'Forgot Password?',
      BLOCKED_USERS: 'Blocked users',
      UNBLOCK: 'Unblock',
      NEXT: 'Next',
      OK_THANKS: 'Ok, thanks!',
      LOGGED_IN_VIA: 'You are logged in via',
      VACATION_MODE_SHOP_MESSAGE: 'This shop is on vacation mode',
      VACATION_MODE_MESSAGE: 'Your shop has successfully been set to Vacation mode.',
      VACATION_MODE_POPUP: {
        TITLE: 'Vacation mode',
        DESCRIPTION:
          "Vacation Mode allows you to temporarily pause your shop's operations. While activated, your items won't show up in search queries and can't be bought.",
        TOGGLE_LABEL: 'Set shop on Vacation mode',
        SHOP_ANNOUNCEMENT_PLACEHOLDER: 'Vacation shop announcement',
        AUTO_REPLY_PLACEHOLDER: 'Auto-reply message',
      },
      EDIT_NOTIFICATIONS: {
        TITLE: 'Choose where you get notified',
        APP_NOTIFICATIONS_TITLE: 'In-app notifications',
        APP_NOTIFICATIONS_DESCRIPTION: 'Delivered inside the app',
        EMAIL_TITLE: 'Email',
        EMAIL_DESCRIPTION: 'Sent to your chosen email',
        DISABLED_TOGGLE_TOOLTIP_TEXT:
          'To receive email notifications, you need to first input your email address',
      },
      DELETE_ACCOUNT: {
        TITLE: 'Deleting your Tedooo account',
        DESCRIPTION:
          'Deleting your Tedooo account is a permanent action that cannot be undone. \n' +
          'Your posts, catalog images, comments, likes, and followers will be permanently removed. \n' +
          'Only active orders with a buyer or seller will be saved with your data until the order is completed.',
        REASONS_DESCRIPTION:
          "We're sorry to see you go. Is there a reason why you're deleting your account? We may be able to help with any common issues.",
        CONFIRM_DESCRIPTION: 'For your security, please re-enter your password to continue.',
        INCORRECT_PASSWORD: 'Password is incorrect!',
        DELETE_ACCOUNT: 'Delete account',
        REASONS: {
          NO_SALES: 'I’m not getting any sales',
          NOTHING_TO_BUY: 'I don’t find anything to buy',
          SECOND_ACCOUNT: 'Created a second account',
          IRRELEVANT_DATA:
            'I find the data on the platform uninteresting or irrelevant to my needs',
          MANY_NOTIFICATIONS: 'Too many notifications',
          OTHER: 'Other',
          OTHER_PLACEHOLDER: 'Issue description',
        },
      },
      EDIT_FULL_NAME: {
        TITLE: 'Edit Full Name',
        INFO: 'Your full name will be publicly visible.',
        PLACEHOLDER: 'Full Name',
      },
      EDIT_USERNAME: {
        TITLE: 'Edit Username',
        PLACEHOLDER: 'Username',
        MIN_TEXT_ERROR: 'Name must contain at least 2 characters',
        MAX_TEXT_ERROR: 'Username cannot exceed 20 characters',
      },
      EDIT_BIRTHDAY: {
        EDIT_TITLE: 'Edit Birthday',
        ADD_TITLE: 'Add Birthday',
        PLACEHOLDER: 'Birthday',
      },
      EDIT_EMAIL: {
        EDIT_TITLE: 'Edit Email',
        ADD_TITLE: 'Add Email',
        PLACEHOLDER: 'Email',
      },
      EDIT_CURRENCY: {
        TITLE: 'Edit Currency',
        MISMATCH_TITLE: 'Shop Currency Mismatch',
        DESCRIPTION:
          "Please note that your shop's currency differs from the one you selected. This means that you will see your shop displayed with your original inputted currency and others shops with the one you selected here.",
        CHANGE_CURRENCY: 'Change currency',
      },
      SHIPPING_ADDRESS_POPUP: {
        MAIN_TITLE: 'Shipping addresses',
        ADD_TITLE: 'Add shipping address',
        EDIT_TITLE: 'Edit shipping address',
        ADD_NEW_ADDRESS: 'Add new address',
        FULL_NAME: 'Full Name',
        STREET_ADDRESS: 'Street address',
        APARTMENT_NUMBER: 'Apt / Suite / Other (optional)',
        CITY: 'City',
        STATE: 'State',
        PROVINCE: 'Province',
        POSTAL_CODE: 'Postal code',
        SET_DEFAULT: 'Set as default',
        FULL_NAME_ERROR: 'Full name is required',
        STREET_ADDRESS_ERROR: 'Street address is required',
        CITY_ERROR: 'City is required',
        POSTAL_CODE_ERROR: 'Postal code is required',
        PHONE_NUMBER_ERROR: 'Phone number is required',
        COUNTRY_ERROR: 'Country is required',
        STATE_ERROR: 'State is required',
        PROVINCE_ERROR: 'Province is required',
        REMEMBER_ADDRESS: 'Remember address',
        PHONE_NUMBER_MESSAGE_TITLE: 'Why is the phone number required?',
        PHONE_NUMBER_MESSAGE:
          'If necessary, we will call or text about your order, such as order verification or fraud prevention.',
      },
    },
  },
  CHAT: {
    ADMIN: 'Admin',
    NO_RESULTS: 'No results',
    PRIVATE_GROUP: 'Private group',
    PUBLIC_GROUP: 'Public group',
    NEW_GROUP: 'New group',
    EDIT_GROUP: 'Edit group',
    IS_TYPING: 'is typing...',
    LAST_SEEN: 'Last seen',
    VIEW_POST: 'View post',
    INVALID_GROUP_INVITE: 'This group invitation is invalid',
    CANT_LEAVE_GROUP: 'You can’t leave the group if you are the admin',
    TYPING: 'Typing...',
    TODAY: 'today',
    SEARCH_NAME: 'Search name/username',
    MESSAGE_PRIVATELY: 'Message privately',
    MAKE_ADMIN: 'Make group admin',
    REMOVE_ADMIN: 'Remove group admin',
    INVALID_GROUP_LINK: 'This group link is no longer available. ',
    DELETED_MESSAGE: 'Deleted message',
    YESTERDAY: 'yesterday',
    SEE_PROFILE: 'See profile',
    SEE_SHOP_PROFILE: 'See shop profile',
    REPORT_USER: 'Report user',
    FORWARD_TO: 'Forward messages to',
    CHATS: 'Chats',
    RELATED_POSTS: 'Related Posts',
    FEED: 'Feed',
    ENTER_MESSAGE: 'Type a message...',
    VIEW_GROUP: 'View group',
    JOIN_GROUP: 'Join group',
    JOIN_THE_GROUP: 'joined the group',
    LEFT_THE_GROUP: 'left the group',
    REMOVE_FROM_GROUP: 'were removed from this group',
    HAS_BEEN_REMOVED: 'has been removed',
    MEMBERS: 'Members',
    MEMBER: 'Member',
    SHARE_LINK: 'Share link',
    ARCHIVED: 'Archived',
    DISCOVER_MY_SHOP: 'Discover my shop on Tedooo!',
    SUGGESTED_GROUPS: 'Suggested groups',
    NO_RESULTS_DESCRIPTION: 'We haven’t found any match to this search',
    PARTICIPANT: 'Participant',
    CHAT_GROUPS: 'Chat groups',
    USERS: 'Users',
    GROUPS: 'Groups',
    GROUP_INVITE: 'Group invite',
    MESSAGES: 'Messages',
    PRIVATE_DESCRIPTION:
      'Private groups can only be accessed by users who have received an invitation link. They will not appear in the search results if a user tries to find them.',
    PUBLIC_DESCRIPTION:
      'Public groups can be found in the search results and are open for anyone to join.',
    MUTUAL_CHAT_GROUPS: 'Mutual chat groups',
    PARTICIPANTS: 'Participants',
    MUTE: 'Mute',
    UN_MUTE: 'Unmute',
    LEAVE_CHAT: 'Leave chat',
    ATTACH_AN_IMAGE: 'Attach an image',
    INVITE_MEMBERS: 'Invite members',
    SEARCH_BY_NAME: 'Search by name/username',
    SEE_ALL_MEMBERS: 'See all members',
    QR_CODE: 'QR code',
    INVITE_LINK: 'Share Invite link',
    VIEW_MEDIA: 'View media and files',
    VIEW_MEDIA_AND_LINKS: 'View media, files and links',
    MEDIA_TITLE: 'media, files and links',
    SEARCH_CHAT: 'Search in chat',
    OPEN_MORE_ACTIONS: 'Open more actions',
    CHOOSE_AN_EMOJI: 'Choose an emoji',
    CHAT_INFO: 'Chat info',
    USER_INFO: 'User info',
    VIEW_OFFER: 'View offer',
    VISION_MESSAGE_TITLES: {
      NEW_OFFER: 'New offer for your Vision',
      YOU_SENT_AN_OFFER: 'You sent an offer on this Vision',
      OFFER_DECLINED: 'Your offer was declined',
      YOU_DECLINED: 'You declined the offer',
    },
    EMPTY_STATE: {
      NO_SEARCH_TEXT: 'We haven’t found any match to this search',
      NO_RESULT: 'No results',
      SEARCH_TITLE: 'Search for users',
      SEARCH_DESCRIPTION: 'Search by name/username',
      NO_MEDIA_TITLE: 'No media',
      NO_MEDIA: 'No images/videos were sent.',
      NO_FILES_TITLE: 'No files',
      NO_FILES: 'No files were sent.',
      NO_LINKS_TITLE: 'No links',
      NO_LINKS: 'No links were sent.',
      NO_PAYOUTS_TITLE: 'Payouts Not Available',
      NO_PAYOUTS_DESCRIPTION: 'Payouts are not currently available for your country',
    },
    SIDE_BAR: {
      EDIT_LATER: 'You can always edit the details later.',
      INVITE_MEMBERS: 'Invite members',
      NEW_CHAT: 'New chat',
    },
    BLOCKED_USER_BAR: {
      TITLE: "You've blocked",
      DESCRIPTION: 'You can’t contact them or see their activity',
    },
    DELETE_MESSAGE_POPUP: {
      TITLE: 'Delete message',
      DESCRIPTION:
        'This message will be deleted to everyone in the chat. Others may have already seen it.',
    },
    MUTE_CHAT_POPUP: {
      HOURS: '8 Hours',
      WEEK: ' 1 week',
      ALWAYS: 'Always',
      MUTE_NOTIFICATIONS: 'Mute Notifications',
    },
    LEAVE_CHAT_POPUP: {
      TITLE: 'Leave Group',
      DESCRIPTION: 'Are you sure you want to leave this chat?',
    },
    PATTERN_EXPIRED_POPUP: {
      TITLE: 'Oh no, the pattern is expired!',
      DESCRIPTION:
        'The daily pattern expires after 24 hours. Remember to visit daily to ensure you get it on time.',
      BUTTON_TEXT: 'Okay',
    },
    FILE_SHARING_POPUP: {
      TITLE: 'File Sharing Rules',
      DESCRIPTION_PART_1: 'Please note that sending files of ',
      DESCRIPTION_PART_2: 'copyrighted files/patterns that are paid or illegal to share, ',
      DESCRIPTION_PART_3: 'may lead to removal from groups and a possible ban from Tedooo.',
      BUTTON_TEXT: 'I agree & understand',
    },
    REPORT_FILE_POPUP: {
      TITLE: 'Report',
      DESCRIPTION:
        'Please provide more details about the copyright infringement to help us review your report effectively.',
    },
    REPORT_FILE_SUCCESS_POPUP: {
      TITLE: 'Thanks for letting us know!',
      DESCRIPTION:
        'Our Trust and Safety team will review your report and take any action necessary',
      BUTTON_TEXT: 'Thank you!',
    },
  },
  PROFILE: {
    REPORT_POPUP: {
      TITLE: 'Report Profile',
      INAPPROPRIATE_CONTENT: 'Inappropriate content/profile picture',
      DANGEROUS_GOODS: 'Dangerous goods',
      PRETEND: "It's pretending to be me or someone else",
      SPAM: 'Spam',
      HARASSMENT: 'Harassment/bullying',
      SEXUAL_COMMENT: 'Sexual comment or advances',
      IRRELEVANT_CONTENT: 'Irrelevant content',
      THANKS_FOR_FEEDBACK: 'Thanks for letting us know!',
      REVIEW_YOUR_REPORT:
        'Our Trust and Safety team will review your\nreport and take any action necessary',
    },
    BLOCK_POPUP: {
      TITLE: 'Block this profile?',
      DESCRIPTION:
        'They wont be able to find your profile, posts, comments or moments on Tedooo.\nNotifications and messages from this member will also be\nblocked.',
    },
    LINKS_POPUP: {
      TITLE: 'Links aren’t allowed in groups',
      DESCRIPTION:
        'Links are not allowed to be shared in the groups. We want these groups to be a place where users can share tips, thoughts, inspiration, and learn from each other. ',
    },
    ACCOUNT_SETTINGS: 'Account settings',
    BIO: 'Bio',
    ADD_BIO: 'Add Bio',
    FULL_NAME: 'Full Name',
    PROFILE_VIEWS: 'Profile views',
    SAVE_VIEWS: 'total saves on your items',
    PRIVATE_TO_YOU: 'Private to you',
    MY_ANALYTICS: 'My analytics',
    MY_SHOPS: 'My shops',
    PRIVATE: 'Private',
    PUBLIC: 'Public',
    SEARCH_APPEARANCES: 'Search appearances',
    UPDATE_PROFILE: 'Update your profile to attract users',
    DISCOVER_VIEWS: 'Discover who viewed your profile',
    DISCOVER_SAVES: 'Discover which of your items were saved',
    SEE_ALL_CHATS: 'See all chats',
    NO_GROUPS: 'Discover interesting groups',
    NO_MUTUAL_GROUPS: 'You dont have any mutual groups',
    SEE_SEARCH_RESULT_OFTEN: 'See how often you appear in search results',
    CREATE_SHOP_INFO:
      'Create your shop profile on Tedooo and start selling your items without any fees!',
    ARE_YOU_SELLER: 'Are you a seller?',
    SHOW_ALL_ACTIVITIES: 'Show all activities',
    SHOPS_DISCOVER: 'Shops you might like',
    MY_GROUPS: 'My groups',
    SHOP_SUGGESTIONS: 'Shop Suggestions',
  },
  NOTIFICATIONS: {
    TITLE: 'Notifications',
    ALL: 'All',
    ORDERS: 'Orders',
    NEW: 'New',
    EARLIER: 'Earlier',
  },
  NON_SIGNUP_CHECKOUT: {
    USE_THIS_ADDRESS: 'Use this address',
    ORDER_SUMMARY: 'order summary',
    CHANGE_METHOD: 'Change Method',
    ENTER_SHIPPING_ADDRESS: 'Enter your shipping address',
    EMAIL_REQUIRED: 'Email is required',
    SECURE_CHECKOUT: 'Secure Checkout',
    REVIEW_AND_PAY: 'review and pay',
    REVIEW_ITEMS_AND_PAY: 'review items and pay',
    CHANGE_ADDRESS: 'Change Address',
    USE_SHIPPING_METHOD: 'Use selected shipping method',
    BEST_VALUE: 'Best value',
    FASTEST: 'Fastest',
    CHEAPEST: 'Cheapest',
    CONTINUE_TO_PAYMENT: 'Continue to payment',
    CHECKOUT: 'Checkout',
    YOUR_SHIPPING_ADDRESS: 'Your shipping address',
    EDIT_SHIPPING_ADDRESS: 'Edit shipping address',
    ENTER_INFO: 'ENTER INFO',
  },
  SHOP: {
    CONTACT_LOCATION: 'Contact + location',
    CONTACT_DETAILS: 'Contact details',
    SIMILAR_SHOPS: 'Similar shops',
    VIEW_SHOP: 'View Shop',
    HOME: 'Home',
    NOT_SET: 'Not set yet',
    CATALOG: 'Catalog',
    MOJOS: 'Mojos',
    SEE_ALL_ACTIVITIES: 'See all activities',
    SEE_ALL_CATALOG: 'See all catalog',
    SEE_ALL_REVIEWS: 'See all reviews',
    SEE_ALL_SIMILAR_SHOPS: 'See all similar shops',
    ACTIVITY: 'Activity',
    WRITE_FIRST_REVIEW: 'Write your first review',
    WRITE_REVIEW: 'Write a review',
    RATE_SHOP: 'Rate this Shop',
    EDIT_PROFILE: 'Edit Profile',
    REVIEW_SHOP: 'Review this shop',
    REVIEWS: 'Reviews',
    CREATE_SHOP_TITLE: 'Are you a Seller?',
    CREATE_SHOP_DESCRIPTION:
      'Create your shop profile on Tedooo and start selling your items without any fees!',
    CREATE_SHOP_BUTTON_TEXT: 'Create now!',
    NO_ACTIVITIES: "When posts will be added, you'll see them here.",
    NO_ACTIVITIES_TITLE: 'No activity',
    NO_PRODUCTS: "When items will be added, you'll see them here.",
    NO_PRODUCTS_TITLE: 'No items added',
    NO_REVIEWS: 'This shop has no reviews',
    NO_REVIEWS_TITLE: 'No reviews',
    NO_GROUPS_TITLE: "You haven't joined any groups",
    NO_DESCRIPTION: 'No description',
    NO_MOJOS_TITLE: 'No Mojos yet',
    NO_MOJOS_DESCRIPTION: 'There aren’t any Mojos to show',
    POSTED: 'Posted',
    REPOSTED: 'Reposted',
    EXCELLENT: 'Excellent',
    VERY_GOOD: 'Very good',
    AVERAGE: 'Average',
    POOR: 'Poor',
    INVITE_TO_GROUP: 'Invite to group',
    TERRIBLE: 'Terrible',
    PENDING_MESSAGE: 'This shop is pending approval, still not publicly visible.',
    ITEM_DETAILS: 'Item Details',
    CUSTOMIZATION: 'Customization',
    ADD_YOUR_CUSTOMIZATION: 'Add your customization',
    BUY_THIS_ITEM: 'Buy this item',
    SEND_MESSAGE: 'Send message',
    ADD_TO_BASKET: 'Add to Basket',
    ADDED_TO_BASKET: 'Added to Basket',
    BUY_NOW_WITH: 'Buy now with',
    EDIT_COVER_PHOTO: 'Edit Cover photo',
    ATTACH_AN_IMAGE: 'Attach an image',
    ACTIVITY_EMPTY_STATE_BUTTON: 'Start a post',
    CATALOG_EMPTY_STATE_BUTTON: 'Add items',
    CATALOG_ITEM_POPUP: {
      DELETE_ITEM: 'Delete item',
      PLUS_SHIPPING: '+ Shipping',
      DELETE_POPUP_DESCRIPTION: 'Are you sure you want to delete this item?',
    },
    BASKET_WONT_BE_SAVED_POPUP: {
      TITLE: 'Your basket wont be saved!',
      DESCRIPTION:
        'You can buy from one shop at a time. If you add this item to your basket, your current basket will be deleted.',
      GO_TO_CHECKOUT_BUTTON: 'Go to checkout',
      DONT_SAVE_BUTTON: "Don't save",
    },
    ITEM_ADDED_TO_BASKET_POPUP: {
      TITLE: 'Item added to basket',
      BUTTON_TEXT: 'View Basket',
    },
  },

  CREATE_SHOP: {
    SAVE_CHANGES: 'Save Changes',
    INTRO_IMPORT_CSV_POPUP: {
      TITLE: 'Now you can easily import your \nlistings by uploading a CSV file!',
      DESCRIPTION: 'Save your time on creating your listings by \nuploading from other platforms.',
      CONTINUE_BUTTON_TEXT: 'Upload items from CSV',
      DISMISS_BUTTON_TEXT: 'No, thanks.',
    },
    IMPORT_CSV_POPUP: {
      FAILED: {
        TITLE: 'Upload failed!',
        DESCRIPTION: 'There was an error processing your file, please try again or contact us.',
        BUTTON_TEXT: 'Try again',
      },
      SUCCESS: {
        TITLE: 'items uploaded!',
        DESCRIPTION: 'Now fill the missing details on your listings to complete the process.',
        BUTTON_TEXT: 'Complete listings missing info',
      },
      PART_SUCCESS: {
        DESCRIPTION: 'Some of your items weren’t uploaded, please try again or contact us',
        BUTTON_TEXT: 'Okay',
      },
    },
    EXIT_POPUP: {
      EDIT: {
        HEADER: 'Leave page?',
        BODY: "Your changes won't be saved.",
        CONTINUE_EDITING: 'Continue editing',
      },
      CREATE: {
        HEADER: 'How do you want to proceed?',
        BODY: 'You can choose to save your draft and continue editing later.',
        DISCARD: 'Discard and Leave',
        DELETE_DRAFT: 'Delete draft',
        SAVE_DRAFT_FOR_LATER: 'Save draft for later',
        SAVE_DRAFT: 'Save draft',
        KEEP_EDITING: 'Keep editing',
      },
    },
    CHOOSE_PRODUCT_POPUP: {
      TITLE: 'Select Listing',
      PLACE_HOLDER: 'Search for product...',
    },
    ADD_LISTING_POPUP: {
      HEADER: 'Add listings',
      FROM_SCRATCH: {
        TITLE: 'Start from scratch',
        DESCRIPTION: 'Create your listing from scratch.',
        CREATE_LISTING_BUTTON: 'Create listing',
        CREATE_FIRST_LISTING_BUTTON: 'Create your first listing',
      },
      DUPLICATE: {
        TITLE: 'Duplicate listing',
        DESCRIPTION: 'Duplicate an existing listing',
        BUTTON: 'Duplicate listing',
      },
    },
    DELETE_DRAFT_POPUP: {
      HEADER: 'Delete your draft?',
      BODY: 'All your changes won’t be saved.',
      BUTTON_TEXT: 'Delete draft',
    },
    BUSINESS: 'Business',
    WELCOME_POPUP: {
      TITLE: 'Ready to create your shop?',
      DESCRIPTION: 'It’s easy and it’s free',
      BUTTON: "Let's start",
      STEP_TITLE: 'Set up your Shop for FREE and keep 100% of your sales with Tedooo!',
      STEP1: {
        TITLE: '1. ZERO COMMISSION FEES',
        DESCRIPTION:
          'Enjoy the benefits of being on a platform that does not take any percentage of your sales.',
      },
      STEP2: {
        TITLE: '2. UNLIMITED PRODUCT UPLOADS',
        DESCRIPTION:
          'Upload an unlimited number of items to your shop, along with pricing and detailed descriptions.',
      },
      STEP3: {
        TITLE: '3. MULTIPLE PAYMENT OPTIONS',
        DESCRIPTION:
          'Offer your cour customers various payment methods to make purchases from your shop.',
      },
      STEP4: {
        TITLE: '4. INCREASE WEBSITE TRAFFIC',
        DESCRIPTION:
          'Boost your sales by linking your website to your Tedooo shop, driving more traffic to your online store.',
      },
      STEP5: {
        TITLE: '5. INCREASE YOUR REACH',
        DESCRIPTION:
          'Once your shop is approved, promote it on other platforms to gain more traction and drive sales.',
      },
      STEP6: {
        TITLE: '6. SOCIALIZE WITH COSTUMERS & CRAFTERS',
        DESCRIPTION:
          'Interact and build a community with your customers, fostering customer loyalty and repeat sales.',
      },
    },
    INITIAL_STEP: {
      NAME_ERROR: 'Shop name is required',
      DESCRIPTION_ERROR: 'Shop description is required',
      TITLE: "Let's start!",
      DESCRIPTION: "We'll walk you through creating your shop and filling it with items",
      EDIT_TITLE: 'Name and Description',
      EDIT_DESCRIPTION: 'Update shop information',
      SHOP_NAME_TITLE: 'Give your shop a title',
      SHOP_NAME_DESCRIPTION: "Don't worry, you can always change it later!",
      SHOP_NAME_PLACEHOLDER: 'Shop name',
      DESCRIBE_SHOP_TITLE: 'Describe your shop',
      DESCRIBE_SHOP_PLACEHOLDER: 'Description',
      TIP: 'Write about how your stuff or whats unique about you!',
    },
    SHOP_INFO_STEP: {
      TITLE: 'Shop info',
      DESCRIPTION: 'Update shop information',
    },
    CONTACT_DETAILS_STEP: {
      TITLE: 'Contact info',
      DESCRIPTION:
        'Please provide your contact number and email address for shipping purposes. They will be kept private and will not be visible to others',
      EDIT_DESCRIPTION: 'Update contact information',
      PHONE_NUMBER_TITLE: 'Phone number',
      PHONE_NUMBER_PLACEHOLDER: 'Phone',
      ADD_FIELD: 'Add another one',
      SOCIAL_WEBSITES_TITLE: 'Socials/Business websites',
      SOCIAL_WEBSITES_PLACEHOLDER: 'Link',
      EMAIL_TITLE: 'Email',
      EMAIL_PLACEHOLDER: 'Email address',
      LOCATION_TITLE: 'Location & Currency',
      LOCATION_SHOP_COUNTRY_PLACEHOLDER: 'Shop Country',
      LOCATION_SHOP_CURRENCY_PLACEHOLDER: 'Shop Currency',
      LOCATION_CITY_PLACEHOLDER: 'City',
      WARNING_MESSAGE:
        'Using a shop currency that’s different from your bank currency may result in currency conversion fees.',
    },
    UPLOAD_LISTING_OPTION_STEP: {
      LOADER_TITLE: 'Creating your listings...',
      LOADER_DESCRIPTION:
        'Please stay on this page. \n' +
        'Upload is in progress, leaving this screen \n' +
        'may interrupt the process.',
      IMPORT: {
        TITLE: 'Import listings by CSV',
        DESCRIPTION: 'Export your listings from Etsy or\nShopify and import their data.',
        BUTTON: 'Upload CSV file',
        CLICKABLE_TEXT: 'Download CSV sample',
      },
    },
    LISTING_STEP: {
      TITLE: 'Add your first listing',
      ADD_ANOTHER_TITLE: 'Add another listing!',
      EDIT_LISTING_TITLE: 'Edit listing',
      EDIT_LISTING_DESCRIPTION: 'Update the listing information',
      DESCRIPTION: 'Insert as many details as you can!',
      ITEM_IMAGES_TITLE: 'Item Images',
      ITEM_IMAGES_DESCRIPTION: 'Upload multiple variations of this item.',
      ITEM_IMAGES_TIP:
        'Use natural light, no flash with a simple background in multiple angles of this item.',
      PHOTO_VARIATIONS: 'Photos variations',
      MAIN_VIEW: 'Main view',
      SIDE_VIEW: 'Side view',
      TOP_VIEW: 'Top view',
      UPSIDE_VIEW: 'Upside view',
      ITEM_INFO_TITLE: 'Item Info',
      ITEM_INFO_TIP:
        "Write in the description the material of this item, if it's available in other colors...",
      ITEM_INFO_TITLE_PLACEHOLDER: 'Title of the item',
      ITEM_INFO_DESCRIPTION_PLACEHOLDER: 'Item Description',
      PRICING_TITLE: 'Inventory & Pricing ',
      PRICE_PLACEHOLDER: 'Price',
      PRICE_VALIDATION: "Price can't be below 0",
      DIMENSION_VALIDATOR: "Field can't be below 0",
      LENGTH_VALIDATOR: 'Length should be the largest',
      TARIFF_PLACEHOLDER: 'Tariff number (optional)',
      QUANTITY_PLACEHOLDER: 'Quantity (optional)',
      CURRENCY_PLACEHOLDER: 'Shop Currency',
      SALE: 'This item is on sale',
      PERCENTAGE_DISCOUNT: 'Percentage discount',
      CATEGORY: 'Category',
      CATEGORY_DESCRIPTION:
        'Share a few more specifics about your item to make it easier to find in search.',
      KEYWORDS_PLACEHOLDER: 'Add keywords',
      FIXED_AMOUNT: 'Fixed amount off',
      FIXED_AMOUNT_PLACEHOLDER: 'Fixed amount',
      SALE_AMOUNT_PLACEHOLDER: 'Sale amount',
      PRICE_AFTER_DISCOUNT_PLACEHOLDER: 'Price after discount',
      ITEM_DIMENSIONS_TITLE: 'Item Dimensions & Weight',
      ITEM_DIMENSIONS_DESCRIPTION:
        "Provide the dimensions of the item once it has been prepared for packaging but hasn't been placed in a box yet (e.g., folded but not yet boxed).",
      ITEM_WEIGHT: 'Item weight',
      ITEM_LENGTH: 'Item length',
      ITEM_WIDTH: 'Item width',
      ITEM_HEIGHT: 'Item height',
      SHIPPING_TITLE: 'Shipping',
      SHIPPING_DESCRIPTION: 'Create or select a shipping profile for this item',
      TARIFF_NUMBER_TITLE: 'Customs Information',
      TARIFF_NUMBER_DESCRIPTION:
        'This information is used to prefill a customs form when you purchase an international Postage Label on Tedooo.',
      TARIFF_NUMBER_MESSAGE:
        'The tariff number is a code used to describe imported, taxable items at customs. \n' +
        'Packages shipped without a tariff code may be delayed by customs or returned to you.',
      RETURNS_AND_EXCHANGES_DESCRIPTION: 'The selected policy will apply to this listing.',
      RETURNS_AND_EXCHANGES_TITLE: 'Returns & Exchanges',
      RETURNS_AND_EXCHANGES_BUTTON_TEXT: 'Add a return & exchanges policy',
      DESCRIPTION_ERROR: 'Item description is required',
      TITLE_ERROR: 'Item title is required',
      PRICE_ERROR: 'Item price is required',
      SHIPPING_PROFILE_ERROR: 'You must add a shipping profile to this item',
      DIMENSIONS_OPTIONAL_FIXED_PRICE: 'Dimensions are optional only for fixed shipping prices.',
      RETURN_POLICY_ERROR: 'You must add a return policy to this item',
      ADD_SHIPPING_PROFILE_BUTTON_TEXT: 'Add a shipping profile',
      CATEGORY_ERROR: 'You must select a category for this item',
      ITEM_IMAGES_ERROR: 'You must add at least one image to this item',
      QUANTITY_TOOLTIP:
        "If you don't specify any quantity, the item will be displayed with a maximum quantity of 300 to the buyer.",
      DIGITAL_ITEM_TEXT: 'This is a digital item',
      DIGITAL_FILES_TITLE: 'Digital files',
      DIGITAL_FILES_DESCRIPTION:
        'Buyers can download these files as soon as they complete their purchase',
      DIGITAL_FILES_BUTTON_TEXT: 'Upload files',
      DIGITAL_ITEM_MESSAGE:
        "If your digital item is personalized and requires buyer input, you aren't required to upload the file here. When the buyer places an order, simply send the custom file to them via Tedooo chat or email.",
      RETURN_POLICY_POPUP: {
        TITLE: 'Return & Exchanges',
        NO_RETURNS_MESSAGE:
          'This policy may not comply with EU law, which required sellers to offer a 14+ day return policy for items shipping in the EU',
        RETURNS_AND_EXCHANGE_DESCRIPTION:
          'Buyers are responsible for return shipping costs. If the item is not returned in its original condition, the buyer is responsible for any loss in value.',
        NO_RETURNS_DESCRIPTION: 'Buyers can contact the seller about any issues with an order',
      },
      SELECT_SHIPPING_PROFILES_POPUP: {
        TITLE: 'Shipping profiles',
      },
      ADD_SHIPPING_PROFILE_POPUP: {
        CREATE_TITLE: 'Create shipping profile',
        EDIT_TITLE: 'Edit shipping profile',
        CONTENT_TITLE: 'Shipping profile info',
        CONTENT_DESCRIPTION: 'Create your shipping profile and save it for the next listings.',
        NAME_PLACEHOLDER: 'Shipping profile name',
        ORIGIN_COUNTRY_PLACEHOLDER: 'Country you’re shipping from',
        ADDRESS_PLACEHOLDER: 'Address',
        CITY_PLACEHOLDER: 'City',
        POSTAL_CODE_PLACEHOLDER: 'Origin postal code',
        PROCESSING_TIME_PLACEHOLDER: 'Processing time',
        SHIPPING_PRICES_PLACEHOLDER: 'Shipping prices',
        ADD_ANOTHER_LOCATION: 'Add another location',
        FIXED_PRICE_PLACEHOLDER: 'What you’ll charge',
        PROCESSING_TIME_TOOLTIP:
          'After a buyer has placed their order, the duration it takes for you to prepare, package, and send it out. This will influence the estimated arrival time of the item.',
        CALCULATED_PRICE_TITLE: 'Calculated price (Recommended)',
        CALCULATED_PRICE_DESCRIPTION:
          'Calculated price shipping on Tedooo dynamically calculates the shipping cost during checkout, taking into account specific order details such as item weight, dimensions, destination, and carrier rates.',
        FIXED_PRICE_TITLE: 'Fixed price',
        FIXED_PRICE_DESCRIPTION:
          'With fixed price shipping on Tedooo, you can set up predetermined shipping prices for each country based on item dimensions and weight.',
        SUBMIT_TEXT_BUTTON: 'Save shipping profile',
        SHIPPING_DESTINATION_FORM: {
          SHIPPING_SERVICE_PLACEHOLDER: 'Shipping services',
          SHIPPING_DESTINATIONS_PLACEHOLDER: 'Shipping destinations',
          ALL_SHIPPING_SERVICES: 'All shipping services',
          WORLDWIDE: 'Worldwide',
          EVERYWHERE_ELSE: 'Everywhere else',
          PRICE_PLACEHOLDER: 'What you’ll charge',
          DELIVERY_TIME_PLACEHOLDER: 'Delivery time',
          ONE_ITEM_PLACEHOLDER: 'One item',
          ADDITIONAL_ITEM_PLACEHOLDER: 'Additional item',
          FREE_INTERNATIONAL_SHIPPING: 'Free international shipping',
          FREE_DOMESTIC_SHIPPING: 'Free domestic shipping',
          SHIPPING_SERVICE_ERROR_MESSAGE: 'Please select at least one shipping service',
          COUNTRY_ERROR_MESSAGE: 'Please select at least one country',
          FIXED_PRICE_ERROR: 'Fixed price cannot be below 0',
        },
      },
      VALIDATE_ADDRESS_POPUP: {
        TITLE: 'Validate your address',
        DESCRIPTION:
          'Please ensure your address is completely correct before submitting.\n' +
          'We are unable to verify addresses from your country, so any errors may lead to issues with the shipping process.',
        BUTTON_TEXT: 'My Address is Correct',
      },
      EDIT_WILL_AFFECT_LISTINGS_POPUP: {
        DESCRIPTION:
          'Please note that making changes to this shipping profile will have an impact on other listings you have published. The current shipping profile is already in use for other listings, and any modifications made will consequently affect them.',
      },
      KEYWORDS_POPUP: {
        TITLE: 'Add keywords',
        SEARCH_PLACEHOLDER: 'Start typing to add a keyword...',
        DESCRIPTION: 'Add up to 10 tags that represent your item.',
      },
    },
    FIRST_LISTING_TOAST: {
      TITLE: 'Congrats on your first listing!',
      DESCRIPTION: 'Add more listings to get more sales.',
    },
    COMPLETE_PROFILE_STEP: {
      TITLE: 'Complete your profile!',
      DESCRIPTION: 'Add a cover photo and add more items',
      EDIT_TITLE: 'Cover and Catalog',
      EDIT_DESCRIPTION: 'Update shop images and details',
      COVER_PHOTO_TITLE: 'Cover photo',
      COVER_PHOTO_DESCRIPTION: 'Add an image that represents your shop',
      COVER_PHOTO_TIP:
        'We recommend to upload an image 420X120 px, the image could be an item, your logo or just an image that represents you.',
      COVER_PHOTO_PLACEHOLDER: 'Drop your image here or ',
      CATALOG_TITLE: 'Catalog',
      CATALOG_DESCRIPTION:
        'Add listings to your catalog or upload a CSV exported from Etsy or Shopify.',
      IMPORT_CSV_BUTTON: 'Upload CSV',
      IMPORT_CSV_WARNING:
        'We couldn’t process some of the images in your listings, please review them.',
      ALLOW_BUYERS_CUSTOM: 'Allow buyers to ask for a custom order',
      ADD_ANOTHER_ITEM: 'Add another item',
      ADD_ANOTHER_LISTING: 'Add another listing',
    },
    SHIPPO_STEP: {
      CONNECT_POPUP: {
        TITLE: "Wait, you didn't connect to Shippo!",
        DESCRIPTION:
          'We recommend that you connect to Shippo to maintain the same price and shipping options you initially received!',
        BUTTON_TEXT: 'Connect to Shippo',
      },
      TITLE: 'Want to get the best shipping rates? Set a shipping account!',
      FORM_TITLE: 'Connect Shippo and unlock incredible savings of up to 90%!',
      SHIPPO: 'Shippo',
      RULE_ONE: '• Sign up free',
      RULE_TWO: '• 90% discounts on shipping',
      RULE_THREE: '• Over 85 carriers worldwide',
      MESSAGE_TEXT:
        "By connecting now, you ensure that you'll maintain the same price and shipping options you initially received, guaranteeing a seamless and uninterrupted experience from start to finish.",
      SHIPPING_INTRO_POPUP: {
        TITLE: 'Shipping is here! Manage it all through Tedooo!',
        DESCRIPTION:
          'Discover the exciting new feature on Tedooo - set up shipping for your items and enjoy the best rates! \n' +
          "We're thrilled to announce our seamless integration with Shippo, which empowers you to buy shipping labels directly through Tedooo.",
        BUTTON_TEXT: 'Add your shipping info',
      },
    },
    PAYMENT_STEP: {
      TITLE: 'How would you like to get paid?',
      FORM_TITLE: 'Add a payouts method',
      FORM_DESCRIPTION: 'Enter your details to receive payments on Tedooo.',
      COUNTRY_DROPDOWN_PLACEHOLDER: 'Billing country/region',
      COUNTRY_REGION_DESCRIPTION: 'Country/region you opened your financial account',
      PAYOUT_METHODS_TITLE: 'Payout methods',
      ADD_ANOTHER_PAYOUT: 'Add another payouts method',
      PAYPAL: 'PayPal',
      PAYPAL_RULE_1: '• PayPal fees may apply',
      STRIPE: 'Stripe',
      STRIPE_RULE_1: '• Stripe fees may apply',
      PAYOUT_RULE_2: '• 1 business day',
      PAYOUT_RULE_3: '• 0% Tedooo fees',
      CONNECTED_PAYOUTS: 'Connected payouts',
      PAYPAL_ERROR_POPUP: {
        TITLE: 'Connection failed',
        EMAIL_NOT_VERIFIED:
          "Your PayPal email isn't verified yet. Please verify your email and then try to connect again.",
        PAYMENT_NOT_RECEIVABLE:
          'This PayPal account is unable to receive payments. Please connect to another account.',
        DEFAULT_DESCRIPTION: 'Couldn’t connect to PayPal please try again later.',
        BUTTON_TEXT: 'Okay, thank you',
      },
      REDIRECT_POPUP: {
        TITLE: 'You are about to be redirected',
        DESCRIPTION:
          'You will be redirected to another page. After completing the process there, you will be redirected back to this page.',
      },
      HELPER_POPUP: {
        TITLE: 'What fees does Tedooo charge for payouts?',
        DESCRIPTION:
          'Tedooo is 100% commission-free and does not take a commission on any payments or payouts. \n' +
          'However, payouts are charged processing fee from the Stripe/PayPal payment platform. For more information, please visit Stripe’s/PayPal’s websites.\n' +
          'Sellers and Independents are responsible for compliance with all applicable tax laws.',
      },
      DEACTIVATE_POPUP: {
        PAYPAL_TITLE: 'Deactivate Paypal Account?',
        STRIPE_TITLE: 'Deactivate Stripe Account?',
        DESCRIPTION:
          'This will disable all payment processing and payouts associated with your account. If you proceed, you will need to re-enter your payment information if you wish to use this payouts method in the future.',
        DEACTIVATE_PAYPAL_BUTTON: 'Deactivate PayPal',
        DEACTIVATE_STRIPE_BUTTON: 'Deactivate Stripe',
      },
      ONE_PAYOUT_POPUP: {
        DESCRIPTION:
          'We recommend to add multiple payment methods to get more sales on Tedooo, you can always add later!',
        STRIPE_TITLE: 'What about Stripe payouts?',
        PAYPAL_TITLE: 'What about PayPal payouts?',
        STRIPE_BUTTON_TEXT: 'Add Stripe method',
        PAYPAL_BUTTON_TEXT: 'Add PayPal method',
      },
      PAYOUT_INTRO_POPUP: {
        TITLE: 'Say goodbye to manual payments, say hello to payouts!',
        DESCRIPTION:
          "With Tedooo's new payment system, powered by Stripe and PayPal, you can connect your accounts and start receiving payments directly.",
        ADDITIONAL_INFO:
          '* From the 12/02/21, only shops with connected payouts will be visible on Tedooo',
        CONNECT_BUTTON_TEXT: 'Connect your payouts now!',
      },
      PAYOUT_MESSAGE: {
        PART_ONE: 'Tedooo takes',
        NO_FEES: 'NO FEES',
        PART_TWO: 'on your sales, while PayPal/Stripe may apply fees...',
        LEARN_MORE: 'Learn more',
      },
      EDIT_LEGACY_PAYOUT_DESCRIPTION:
        'Your shop is currently set up to used manual payouts methods, to edit click below.',
      EDIT_LEGACY_PAYOUT_BUTTON_TEXT: 'Edit manual payouts',
      ADD_LEGACY_PAYOUT_TITLE: 'Other payouts methods',
      ADD_LEGACY_PAYOUT_DESCRIPTION:
        "If you don't use the listed payouts methods, manually add your preferred methods and handle payments directly with buyers...",
      ADD_LEGACY_PAYOUT_BUTTON_TEXT: 'Add manually a payouts method',
    },
    LEGACY_PAYOUTS_STEP: {
      TITLE: 'Payment and Shipping',
      EDIT_DESCRIPTION: 'Update shop information',
      PAYMENT_TITLE: 'How would you like to get paid?',
      DELIVERY_TITLE: 'Which countries you deliver to?',
      DELIVERY_METHOD_PLACEHOLDER: 'link/username/email',
      PAYMENT_DROPDOWN_PLACEHOLDER: 'Payment Method',
      PAYMENT_INPUT_PLACEHOLDER: 'Link/Account username',
      HOW_IT_WORKS: 'How it works?',
      PAYMENT_MESSAGE: {
        OPENER: 'On Tedooo, compared to other platforms, there are ',
        ZERO_FEES: '0% FEES ',
        CLOSURE: 'on your sales. The payments are made between the seller and buyer directly. ',
      },
      DELIVERY_MESSAGE:
        "On Tedooo, once the buyer will request to buy, you'll receive his address. Calculate the shipping cost and wait for his approval. ",
      ALL_COUNTRIES: 'All Countries',
      HELP_POPUP: {
        TITLE: 'How to get my link/username/email?',
        DESCRIPTION: `To link your payment method with Tedooo, please log in to your payment account and copy your email/username/link.
           Then, return to Tedooo and paste the information into the field.`,
      },
      ONE_PAYMENT_POPUP: {
        TITLE_1: 'Wait... ',
        TITLE_2: 'You have only 1 payment method?',
        DESCRIPTION:
          'We recommend to add multiple payment methods to get more sales on Tedooo, you can always add later!',
      },
      LEGACY_PAYOUT_POPUP: {
        TITLE: 'Tedooo manual payouts',
        DESCRIPTION:
          "On Tedooo, you can connect your payouts methods and get paid seamlessly, giving buyers a better buying experience. Alternatively, you can handle payments directly with buyers outside of the app, using your preferred payment method. To make this process smoother, make sure to input your payment method's username, email, or link into the fields provided on your Tedooo profile page.",
      },
    },
    COMPLETE_DRAFT: {
      TITLE: 'Complete your shop!',
      DESCRIPTION: "Finish creating your shop so you won't miss any sales!",
    },
    FINAL_ANIMATION_CREATE: 'Creating your shop...',
    FINAL_ANIMATION_EDIT: 'Updating your shop...',
  },
  SIGN: {
    FORGET_PASSWORD: 'Forgot password?',
    STAR_LOGGED_IN: 'Stay logged in',
    CONTINUE_WITH_FACEBOOK: 'Continue with Facebook',
    CONTINUE_WITH_GOOGLE: 'Continue with Google',
    RESET_PASSWORD_FEW_STEPS: 'Reset your password in two quick steps',
    VERIFY_IDENTITY: 'Verify your identity',
    CREATE_NEW_PASSWORD: 'Create a new password',
    ENTER_THE_CODE_WE_SENT: 'Enter the code we sent to the phone number ending in ',
    YOUR_PASSWORD_CHANGED_SUCCESSFULLY: 'Your password has changed successfully!',
    CREATE_NEW_PASSWORD_TEXT:
      'Create a new password that is at least 6 characters long. A strong password is combination of letters, numbers, and punctuation marks.',
    ENTER_CODE: 'Enter the code we sent to the phone number\nending in',
    FORGOT_PASSWORD: 'Forgot password?',
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    ALREADY_MEMBER: 'Already have an account?',
    CALL_ME: 'Call me instead',
    CREATE_ACCOUNT: 'Create an account',
    SIGN_IN_OR: 'Sign in or ',
    QUICK_AND_EASY: 'Registration is quick and easy',
    AGREE_TEDOOO: "By continuing, you agree to Tedooo's",
    SEND_WHATSAPP: 'Send via Whatsapp',
    TERMS_OF_SERVICE: 'Terms Of Service',
    ACKNOWLEDGE: "and acknowledge Tedooo's",
    PRIVACY_POLICY: ' Privacy Policy',
    DIDNT_GET_CODE: "Didn't get a code?",
    RESEND_NOW: 'Resend now!',
    JOIN_TEDOOO: 'Join Tedooo',
    CONNECT_WITH_CRAFTER: 'Connect with crafters and the world\naround you on Tedooo.',
    DISCOVER_TEDOOO: 'Discover Tedooo’s full crafty feed!',
    JOIN_THE_COMMUNITY:
      'Join the community of crafters, a world for your crafts inspiration, sales and tips for free.',
  },
  FEED: {
    CREATE_POST: 'Create Post',
    EDIT_POST: 'Edit Post',
    POST_FROM: 'Post as...',
    CHOOSE_ITEM: 'Choose 1 item',
    PROFILE: {
      PROFILE_VIEWS: 'Profile views',
      VISIT_SHOP: 'Visit shop',
      VIEW_FULL_PROFILE: 'View full profile',
      GO_TO_PROFILE: 'Go to profile',
    },
    FEED_FILTERS: {
      ALL: 'All',
    },
    POSTS_SIDE_BAR: {
      RECENT_POSTS: 'Recent posts',
      NO_POSTS: 'No posts to show',
      SHARE_FIRST_POST: 'Share your first post',
    },
    POST: {
      POST: 'Post',
      HIDE_REPLIES: 'Hide replies',
      EDITED: '· Edited',
      REPLY: 'Reply',
      EVERYONE: 'Everyone',
      CHOOSE_PHOTOS: 'Choose your listing main photo first',
      ADD_IMAGES: 'Add images',
      BACKGROUND_TEXT: 'Background text',
      SHARE_ITEM_FROM_CATALOG: 'Share item from catalog',
      ADD_EMOJI: 'Add emoji',
      PUBLISH_FROM: 'Publish from',
      PUBLISH_FROM_INFO: 'Choose where to publish your post from',
      ADD_PHOTOS: 'Add photos',
      WRITE_YOUR_POST: 'Write your post here...',
      WRITE_POST: 'Write a post',
      COMMENT: 'Comment',
      COMMENTS_TEXT: 'Comments',
      REPOST: 'Repost',
      REPOSTS: 'Reposts',
      REPOSTED: 'Reposted',
      WRITE_COMMENT: 'Write a comment...',
      DELETE_INFO: 'Are you sure you want to permanently delete this post?',
      COPY_LINK_ERROR: 'Something went wrong, please try again later',
      SHARE_THOUGHTS: 'Share your thoughts...',
      SHARE_THOUGHTS_SELLER: 'Share thoughts or list your items...',
      POST_REMOVED_FROM_ACTIVITY_SUCCESS: 'Post removed from your activity',
      POST_REMOVED_FROM_ACTIVITY_FAIL: 'Failed to remove post from your activity',
      COMMENTS: {
        NO_COMMENTS_TITLE: 'No comments',
        NO_COMMENTS_TEXT: 'Write the first comment',
        DELETE: 'Delete comment',
        DELETED: 'Deleted comment',
        DELETED_CONFIRM_TEXT: 'Are you sure you want to delete your comment?',
        DELETE_INFO_TEXT: 'All likes and replies on this comment will not be removed',
        LOAD_MORE: 'Load more comments',
      },
      REPOST_POPUP: {
        REPOST_WITH_THOUGHTS_TITLE: 'Repost with your thoughts',
        REPOST_WITH_THOUGHTS_DESCRIPTION: 'Create a new post with the post attached',
        REPOST_DESCRIPTION: 'Bring this post to others feed',
        UNDO_REPOST_TITLE: 'Undo repost',
        UNDO_REPOST_DESCRIPTION: 'This repost will be removed from your feed',
      },
      REPORT_POPUP: {
        TITLE: 'Report Post',
        INAPPROPRIATE_CONTENT: 'Inappropriate content',
        DANGEROUS_GOODS: 'Dangerous goods',
        PRETEND: "It's pretending to be me or someone else",
        SPAM: 'Spam',
        HARASSMENT: 'Harassment/bullying',
        SEXUAL_COMMENT: 'Sexual comment or advances',
        IRRELEVANT_CONTENT: 'Irrelevant content',
        ISSUE_DESCRIPTION: 'Issue Description',
      },
      SHARE_POPUP: {
        POST_TITLE: 'Share post',
        PRODUCT_TITLE: 'Share Product',
        GROUP_TITLE: 'Share Invite link',
        GROUP: 'Share on chat group',
        FACEBOOK: 'Share to Facebook',
        TWITTER: 'Share to Twitter',
        COPY_LINK: 'Copy link',
        CHAT: 'Share in chat',
      },
      SHARE_POST_IN_CHAT_POPUP: {
        TITLE: 'Share in chat',
        GROUPS: 'Groups',
        CONTACTS: 'CONTACTS',
      },
      SUCCESSFUL_REPOST_MESSAGE: 'Repost successful',
      FAILED_REPOST_MESSAGE: 'Repost failed',
      UNDO_REPOST_MESSAGE: 'Repost removed',
    },
    SPECIFIC_POST: {
      BACK_TO_FEED: 'Back to Feed',
    },
    SIDE_BAR: {
      SAVED_PRODUCTS_SECTION: {
        TITLE: 'Recently saved',
        SEE_ALL: 'See all saved items',
      },
    },
    LOGGED_OUT_SIDE_BAR: {
      TITLE: 'New at Tedooo?',
      DESCRIPTION:
        'Join the community of crafters, a world for your crafts inspiration, sales and tips for free.',
      CREATE_ACCOUNT_BUTTON: 'Create account',
    },
  },
  NO_RESULTS: {
    TEXT: 'Make sure everything is spelled correctly or try different keywords.',
  },
  GET_THE_APP: {
    DOWNLOAD_APP: 'Download the Tedooo app',
    GET_THE_APP: 'Get the Tedooo App',
    DESCRIPTION:
      "Enter your phone number and we'll send you an SMS with a link to download the app.",
    LINK_SENT: 'Download Link Sent!',
    SEARCH_TEDOOO: 'Or just search "Tedooo" on:',
    FEATURE_AVAILABLE_ON_APP: 'This feature is available on the app',
    NOT_NOW: 'Not now',
    OPEN_TEDOOO: 'Open Tedooo',
  },
  ONBOARDING: {
    PROFILE_PICTURE_STEP: {
      ALMOST_THERE: 'Almost there! 🎉',
      ADD_A_PROFILE_PICTURE: 'Add a profile picture',
      CHOOSE_A_PHOTO: 'Choose a photo that represents you!',
    },
    BIRTHDAY_STEP: {
      WHENS_YOUR_BIRTHDAY: 'When’s your birthday?',
      DONT_WORRY: 'Don’t worry we’ll not expose your age.',
      DATE_OF_BIRTH: 'Date of Birth',
      DAYS_UNTIL_YOUR_BIRTHDAY: 'days until your birthday 🎉',
    },
    MAIN_GOAL_STEP: {
      TITLE: 'What’s your main goal?',
      SECONDARY_TITLE: 'Select your primary focus.',
      MAIN_GOAL_CARD: {
        SELLER: {
          TITLE: 'Sell items',
          DESCRIPTION: 'I want to list my items and sell my cool stuff',
        },
        BUYER: {
          TITLE: 'Just browse',
          DESCRIPTION: 'I’m here to discover or buy cool stuff',
        },
      },
    },
    INTERESTS_STEP: {
      TITLE: 'Choose your interests',
      DESCRIPTION: 'You can choose multiple options.',
      SEARCH_PLACEHOLDER: 'Search and select multiple options...',
      CATEGORY_SECTION: {
        SELECTED_INTERESTS: 'Selected interests',
      },
      EMPTY_STATE: {
        TITLE: 'Search for interests',
        DESCRIPTION: 'eg. crochet, resin, art objects',
      },
    },
    FOLLOW_SHOPS_STEPS: {
      TITLE: 'Follow Shops',
      DESCRIPTION: 'Here are some suggestions by Tedooo',
    },
    JOIN_GROUPS_STEP: {
      TITLE: 'Join Groups',
      DESCRIPTION: 'Here are some suggestions by Tedooo',
    },
    PROFILE_CREATED_STEP: {
      TITLE: 'Profile Created!',
      DESCRIPTION:
        "Congratulations! Your Tedooo profile has been created successfully. You're now part of a vibrant community of crafters!",
    },
  },
  ORDERS: {
    BUTTONS_TEXT: {
      WITHDRAW: 'Withdraw',
      ADDED_SHIPPING_COST: 'Add shipping cost',
      DECLINE_ORDER: 'Decline order',
      PAY_NOW: 'Pay now',
      HAVING_ISSUE_WITH_PAYMENT: 'Having an issue with the payment',
      PAYMENT_RECEIVED: 'Payment received',
      WRITE_REVIEW: 'Write a review',
      TRACK_ORDER: 'Track order',
      ORDER_IS_DELIVERED: 'Order is delivered',
      SEE_TRACKING_HISTORY: 'See tracking history',
      VIEW_AND_DOWNLOAD_FILES: 'View and download files',
      EDIT_TRACKING_NUMBER: 'Edit tracking number',
      ORDER_SHIPPED: 'I’ve shipped the order',
      SHIP_WITHOUT_LABEL: 'Ship without creating a label',
      CREATE_SHIPPING_LABEL: 'Create a shipping label',
      DOWNLOAD_LABEL_AND_PACKING_SLIP: 'Download Label & Packing slip',
      SEE_WHY: 'See why',
      APPROVE_ORDER: 'Approve order',
      ACCEPT_ORDER: 'Accept order',
    },
    TAG_TEXT: {
      ON_THE_WAY: 'On the way',
      COMPLETED: 'Completed',
      CANCELLED: 'Declined',
      WAITING_FOR_BUYER: 'Waiting for the buyer',
      WAITING_FOR_SELLER: 'Waiting for the seller',
      YOUR_TURN: 'Your turn',
    },
    PREVIEW_TITLE: {
      SELLER: {
        REQUESTED_STEP: 'to enter the shipping cost and final price',
        OFFERED_STEP: 'to send you the total payment',
        PAYMENT_SENT_STEP: 'to check if the payment was received',
        INSTANT_CHECKOUT_PAYMENT_SENT_STEP: 'to accept and prepare the order',
        PAYMENT_RECEIVED_STEP: 'to ship the order',
        SHIPPED_STEP: 'The order is shipped and on the way',
        DELIVERED_STEP: 'Your order is successfully delivered!',
        DECLINED_STEP: 'The order is declined',
      },
      BUYER: {
        REQUESTED_STEP: 'to send you the final price',
        PAYMENT_RECEIVED_STEP: 'to ship the order',
        OFFERED_STEP: 'to send the total payment',
        PAYMENT_SENT_STEP: 'to check if the payment was received',
        SHIPPED_STEP: 'The order is shipped and on the way',
        DELIVERED_STEP: 'Your order is successfully delivered!',
        DECLINED_STEP: 'You declined the order',
      },
    },
    DASHBOARD: {
      SWITCH_BUTTON: {
        SELLING: 'Switch to selling',
        BUYING: 'Switch to buying',
      },
      HEADER_BOXES: {
        TOTAL_ACTIVE_ORDERS: 'Total Active Orders',
        PAST_ORDERS: 'Past Orders',
        SHOP_VIEWS: 'Shop Views',
        EXTERNAL_LINKS: 'External Links Clicked',
        ON_THE_WAY: 'On the way',
      },
      ORDER_BOX: {
        ORDER_ID: 'Order ID',
        N_OF_ITEMS: 'N. of items',
        ITEM_PRICE: 'Item price',
        ITEMS_PRICE: 'Items price',
        SHIPPING_PRICE: 'Shipping price',
        TOTAL_PRICE: 'Total price',
        BADGE: {
          WAITING_FOR_THE_BUYER: 'WAITING FOR THE BUYER',
          WAITING_FOR_THE_SELLER: 'WAITING FOR THE SELLER',
          YOUR_TURN: 'YOUR TURN',
          COMPLETED: 'COMPLETED',
          ON_THE_WAY: 'ON THE WAY',
        },
        BUTTONS: {
          EDIT_TRACKING_NUMBER: 'Edit tracking number',
          TRACK_ORDER: 'Track order',
          IVE_SHIPPED_THE_ORDER: 'I’ve shipped the order',
          WRITE_A_REVIEW: 'Write a review',
          PAY_NOW: 'Pay now',
          DECLINE_ORDER: 'Decline order',
          ADDED_SHIPPING_COST: 'Add shipping cost',
        },
        TOOLTIP: {
          ITEMS_PRICE: 'The price is the total amount of the items',
          SHIPPING_PRICE:
            'The shipping cost will be determined by the seller based on the shipping address you have provided.',
        },
      },
      ORDERS_OVERVIEW: {
        MAIN_TITLE: 'Orders Overview',
        NAVBAR_BUTTONS: {
          ALL: 'All',
          TODO: 'To-Do',
          AWAITING: 'Awaiting',
          SHIPPED: 'Shipped',
          COMPLETED: 'Completed',
          DECLINED: 'Declined',
        },
        EMPTY_STATE: {
          SELLER_DASHBOARD: {
            TITLE: 'How to improve sales?',
            DESCRIPTION: 'Great job so far, keep improving your profile',
            BUTTON_TEXT: 'Go To Your Shop',
          },
          BUYER_DASHBOARD: {
            BUTTON_TEXT: 'Start shopping!',
          },
          TODO: {
            TITLE: 'You’re all done by now!',
            DESCRIPTION: "There aren't any actions pending for you.",
          },
          AWAITING: {
            TITLE: 'No awaiting tasks',
            DESCRIPTION: "There aren't any actions awaiting for the sellers.",
          },
          SHIPPED: {
            TITLE: 'No orders in transit',
            DESCRIPTION: "There aren't any current shipments.",
          },
          COMPLETED: {
            TITLE: 'No orders completed',
            DESCRIPTION: "There aren't any completed orders yet.",
          },
          DECLINED: {
            TITLE: 'No orders were declined',
            DESCRIPTION: "There aren't any orders that were declined.",
          },
          NO_ACTIVE_ORDERS: {
            TITLE: 'No active orders',
            DESCRIPTION: 'You currently have no active orders',
          },
        },
      },
    },
    PREVIEW_DESCRIPTION: {
      SELLER: {
        REQUESTED_STEP: 'Recipient address:',
        OFFERED_STEP: 'Begin working on the order only after receiving payment from the buyer.',
        PAYMENT_SENT_STEP: 'Click on “Payment received” if received',
        INSTANT_CHECKOUT_PAYMENT_SENT_STEP:
          "To proceed with this order, ensure to mark it as 'accepted' before it expires.",
        PAYMENT_RECEIVED_STEP: 'Be sure to have the tracking number with you (if there is one)',
        PAYMENT_RECEIVED_STEP_CAN_CREATE_SHIPPING_LABEL:
          'Create a shipping label from Tedooo and get up to 90% sale!',
        SHIPPED_STEP: "We'll notify you when it's delivered.",
        DELIVERED_STEP: 'Share it with others!',
        DECLINED_STEP: 'You can see the reason the buyer declined in the chat',
      },
      BUYER: {
        REQUESTED_STEP:
          'The final price will be determined once the seller inputs the shipping cost',
        PAYMENT_SENT_STEP:
          'When the order will be ready the seller will ship it and add the tracking number (if there is one)',
        SHIPPED_STEP: 'After picking up the order, mark it as delivered',
        DELIVERED_STEP: 'Share it with others!',
        DECLINED_STEP: 'You can see the declined reason',
      },
    },
    ACTIVITY_DESCRIPTION: {
      PLACED_AN_ORDER: 'placed an order',
      REQUESTED_STEP: 'requested to buy',
      OFFERED_STEP: 'entered the shipping cost and final price.',
      PAYMENT_SENT_STEP: 'paid',
      PAYMENT_RECEIVED_STEP: 'marked the payment as received',
      SHIPPED_STEP: 'shipped the order',
      DECLINED_STEP: 'declined the order',
      DELIVERED_STEP: 'marked the order as delivered',
      SHIPPING_UPDATED_STEP: 'API indicated that your package has been delivered.',
    },
    POPUPS: {
      ADD_TRACKING_NUMBER_POPUP: {
        ADD_TITLE: 'Add tracking number',
        EDIT_TITLE: 'Edit tracking number',
        CONTENT_TITLE: 'Add the order tracking number',
        CONTENT_DESCRIPTION: 'So the buyer will be able to track the order',
        INPUT_PLACEHOLDER: 'Tracking number',
        INPUT_ERROR: 'Invalid tracking number',
        DROPDOWN_PLACEHOLDER: 'Shipping Carrier',
        BUTTON_TEXT: 'Save and send',
        CHECKBOX_TEXT: 'I don’t have a tracking number',
        FOUND_MULTIPLE_CARRIERS_TITLE: 'We’ve found multiple carriers that match',
        FOUND_MULTIPLE_CARRIERS_DESCRIPTION: 'Select the carrier of the shipping service you used',
      },
      WITHDRAW_ORDER_POPUP: {
        TITLE: 'Withdraw order?',
        CONTENT_DESCRIPTION:
          "After withdrawing your order request, you won't be able to proceed with the purchase.",
      },
      DID_ORDER_ARRIVE_POPUP: {
        TITLE: 'Did your order arrive?',
        CONTENT_DESCRIPTION:
          'If it did , the seller will be notified and the order will be marked as completed.',
      },
      SHOP_VIEWS_POPUP: {
        TITLE: 'Shop Views',
      },
      ON_THE_WAY_POPUP: {
        TITLE: 'On The Way',
      },
      REVIEW_ORDER_POPUP: {
        WARNING_MESSAGE_TITLE_FIELD: 'Title should be minimum four letters!',
        WARNING_MESSAGE_DESCRIPTION_FIELD: 'Description should be minimum eight letters!',
        TEXT_AREA_PLACEHOLDER: 'Describe experience',
      },
      DECLINE_ORDER_POPUP: {
        POPUP_TITLE: 'Decline order',
        QUESTION_TITLE: 'Why would you like to decline this order?',

        TEXTAREA_PLACEHOLDER: 'Describe the reason',
      },
      PAY_NOW_POPUP: {
        TITLE: 'Checkout',
        SHIPPING_RATE_ERROR_MESSAGE:
          'We couldn’t find any shipping rates for this item. Please contact the seller to proceed this purchase.',
        BUTTON_TEXT: 'Continue with',
        PAYPAL_DESCRIPTION:
          'Continuing will take you to your PayPal account. You’ll be able to review and submit your order after you log in.',
        TIP_CONTENT_PART_1:
          'Tedooo is 100% commission-free!\nHowever, Clients will be charged a Stripe or Paypal processing fee for each payment. The seller will charge you',
        TIP_CONTENT_PART_2:
          '(shipping not included) for this transaction.\nAdditional duties and taxes may apply.',
        TIP_CONTENT_HOW_TO_PAY:
          'Copy the username or click on the link of the payment method you want to send the payment thought, with the exact amount.',
      },
      CONFIRM_PAYMENT_POPUP: {
        TITLE: 'Did you sent the payment?',
        DESCRIPTION: 'The seller will be notified that the payment was sent.',
        PRIMARY_BUTTON_TEXT: 'I sent the payment',
        TERTIARY_BUTTON_TEXT: 'Still not sent',
      },
      PAYMENT_FAILED_POPUP: {
        TITLE: 'Oh no, the payment failed!',
        DESCRIPTION: 'The payment failed. Try again, or use a different method.',
      },
      PAYMENT_SUCCESSFUL_POPUP: {
        TITLE: 'Payment Successful!',
        DESCRIPTION:
          'The seller will update you soon with the shipping details. Thank you for your purchase!',
        VIEW_ORDER_DESCRIPTION: 'You can now view the status of your order on the Orders page.',
        BUTTON_TEXT: 'Open order',
      },
      CONTACT_THE_SELLER_POPUP: {
        TITLE: 'Contact the seller',
        DESCRIPTION:
          'Seems like the seller have an issue with the payouts information. \n' +
          'Please contact them to proceed.',
      },
      ORDER_ARRIVED_POPUP: {
        TITLE: 'WooHoo! Your order has arrived!',
        DESCRIPTION: 'Let the unboxing time begin.',
        BUTTON_TEXT: 'Got it!',
      },
      WAITING_FOR_SELLER_POPUP: {
        TITLE: 'Now wait for the seller',
        DESCRIPTION:
          'We’ll notify you when the seller will send you final price. In the meantime go grab a cup of tea!',
        BUTTON_TEXT: 'Thank you!',
      },
      TRACK_ORDER_POPUP: {
        TITLE: 'Track Order',
        SELLER_EMPTY_STATE:
          'There is still no activity, it could  take a few hours.\n' +
          'If it’s taking too long, check if the tracking number is correct.  ',
        BUYER_EMPTY_STATE:
          'There is still no activity, it could  take a few hours.\n' +
          'If it’s taking too long, contact the seller to check if the tracking number is correct.',
      },
      BASKET_POPUP: {
        TITLE: 'Your Basket',
        ADD_SHIPPING_ADDRESS: 'Add shipping address',
        EDIT_SHIPPING_ADDRESS: 'Edit shipping address',
        SAVE_ADDRESS: 'Save address',
        SHIP_TO: 'Ship to',
        SHIPPING_DESCRIPTION:
          'After sending, the seller will send you the final order including the shipping cost',
        REQUEST_TO_BUY: 'Request to buy',
      },
      NEW_ORDER_POPUP: {
        TITLE: 'You have a new order!',
        DESCRIPTION: 'Someone has requested to buy from your shop.',
        BUTTON_TEXT: 'View order',
      },
      SEND_FINAL_PRICE_POPUP: {
        SEND_FINAL_PRICE: 'Send final price',
        EDIT_PRICE: 'Edit price',
        SHIPPING_COST: 'Shipping cost',
        ITEMS: 'Items',
        RECIPIENT_ADDRESS: 'Recipient address:',
        OFFER_FREE_SHIPPING: 'Offer free shipping',
        APPLY_PRICE: 'Apply Price',
        LISTED_PRICE: 'Listed Price',
        NO_PAYOUTS_DESCRIPTION:
          'You need to add your payouts method so the buyer can send you the the payment.',
        ADD_PAYOUT: 'Add payouts method',
        APPROVE_ORDER: 'Approve order',
        APPROVE: 'Approve',
        SHIP_TO: 'Ship to',
        SHIPPING_DETAILS: 'Shipping details',
        YOU_OFFERED_FREE_SHIPPING: 'You offered free shipping',
      },
      PAYMENT_RECEIVED_POPUP: {
        TITLE: 'Did you receive the payment?',
        DESCRIPTION: 'Before you continue, check if you received it',
        STILL_NOT_RECEIVED: 'Still not received',
        PAYMENT_RECEIVED: 'I received the payment',
      },
      PAYMENT_ISSUE_POPUP: {
        TITLE: 'Payment issue',
        FORM_TITLE: 'What is the issue you are experiencing?',
        FORM_DESCRIPTION: 'Please select one of the options',
        PAYMENT_WAS_NOT_RECEIVED_OPTION: 'The payment wasn’t received',
        AMOUNT_NOT_CORRECT_OPTION: 'The amount isn’t correct',
        SEND_FEEDBACK: 'Send feedback',
        OTHER_OPTION_PLACEHOLDER: 'Describe the reason',
      },
      UPDATE_SHIPPING_INFORMATION_POPUP: {
        TITLE: 'Update shipping information',
        DESCRIPTION:
          'To create a shipping label, you must add your contact info and shipping information on your listings',
        BUTTON_TEXT: 'Open my shop',
      },
      CREATE_SHIPPING_LABEL_POPUP: {
        TITLE: 'Package Info',
        DESCRIPTION:
          'Provide the dimensions of the item once it has been placed in a box (packed).',
        TITLE_2: 'Shipping service & Ship date',
        TITLE_3: 'Add Insurance (optional)',
        INSURANCE_DESCRIPTION: 'Protection sold by Cover Genius and powered by XCover.',
        TITLE_4: 'Signature (optional)',
        SIGNATURE_TEXT: 'Require a signature for delivery',
        PACKAGE_INFO_FORM: {
          PACKAGE_TYPE_PLACEHOLDER: 'Package type',
          SHIPPING_SERVICE_PLACEHOLDER: 'Shipping service',
          SHIP_DATE_PLACEHOLDER: 'Ship date',
        },
        RULES: {
          PACKAGE_REQUIRED: 'Package type is required',
          SHIPPING_REQUIRED: 'Shipping service is required',
          SHIP_DATE_REQUIRED: 'Ship date type is required',
        },
        UNUSUAL_PACKAGE: 'Unusual package',
        FINAL_ANIMATION_TEXT: 'Creating shipping label...',
        SIDE_BAR: {
          INFO: {
            NUMBER_OF_ITEMS: 'Number of items:',
          },
          SHIP_TO: {
            TITLE: 'Ship to',
          },
          TOTAL_PRICE: {
            SUBTOTAL: 'Subtotal',
            INSURANCE: 'Insurance',
            TOTAL: 'Total',
          },
        },
      },
      PURCHASED_LABEL_POPUP: {
        TITLE: 'What would you like to download?',
        EMAIL_SECTION_TITLE: 'Prefer to get them by email?',
        SEND_FILES_ON_EMAIL: 'Send files on email',
        DOWNLOAD_SHIPPING_LABEL: 'Download Shipping Label',
        DOWNLOAD_PACKING_SLIP: 'Download Packing Slip',
        SHIPPING_LABEL: 'Shipping Label',
        PACKING_SLIP: 'Packing Slip',
        STANDARD_PRINTER: '8.5 X 11 in PDF (Standard Printer)',
      },
      DIGITAL_FILES_POPUP: {
        TITLE: 'Thank you for your order!',
        DESCRIPTION: 'You can download the files below',
        SUB_TITLE: 'Prefer to get them by email?',
        BUTTON_TEXT: 'Send files on email',
      },
    },
    SPECIFIC_ORDER: {
      ORDER_ITEMS: 'Order items',
      VIEW_USER_PROFILE: 'View user profile',
      VIEW_DETAILS: 'View details',
    },

    SHARE_MESSAGE: { SELLER: 'YAY! 🎉 Just made a new sale on the Tedooo app, checkout my shop!' },
  },
  SPECIFIC_PRODUCT: {
    SELLER_BOX: {
      TITLE: 'Seller information',
    },
    SHIPPING_AND_POLICIES: {
      TITLE: 'Shipping & policies',
      READY_TO_SHIP_IN: 'Ready to ship in',
      SHIPS_FROM: 'Ships from',
      SHIPS_TO: 'Ships to',
      RETURNS_AND_EXCHANGES: 'Returns & exchanges',
      CUSTOM_AND_IMPORT_TAXES: 'custom and import taxes',
      BUYERS_RESPONSIBILITY: 'Buyers responsibility',
      BUYERS_RESPONSIBLE_DESCRIPTION:
        'Buyers are responsible for any customs and import taxes that may apply.',
    },
    PRODUCT_DETAILS: {
      NOT_SHIPPING_TO_COUNTRY: 'This item does not ship to',
      CHANGE_COUNTRY: 'Change country',
    },
    IN_STOCK: 'In stock',
    ONLY_THREE_LEFT: 'Only 3 left',
    ONLY_TWO_LEFT: 'Only 2 left',
    ONLY_ONE_LEFT: 'Only 1 left',
    OUT_OF_STOCK: 'Out of stock',
    NOTE_TO_SELLER: 'Note for the seller',
    NOTE_CHECKBOX_TEXT: 'Add a note on this item',
    ADDED_FAVORITE_MESSAGE: 'Added to favorites',
    REMOVED_FAVORITES_MESSAGE: 'Removed from favorites',
  },
  PRODUCTS_PAGE: {
    FAVORITES_INTRO_POPUP: {
      TITLE: 'Like an item? Save it!\n“Favorites” is now on Tedooo!',
      DESCRIPTION:
        'Discover the new “Favorites” feature on Tedooo, allowing you to save items you love for later!\n' +
        "As a seller, you'll gain insights into which of your products have been saved, giving you motivation to design similar items! ",
      BUTTON_TEXT: 'Amazing, thanks!',
    },
    EMPTY_STATE: {
      FAVORITES: {
        TITLE: 'Keep everything you like here',
        DESCRIPTION: 'Explore beautiful items on Tedooo',
      },
    },
    FAVORITES_TITLE: 'Favorite items',
  },
  PREMIUM: {
    UPGRADE_TO_PRO: 'Upgrade to PRO',
    PREMIUM_ACCOUNT: 'Premium Account',
    ONE_MONTH_FREE: 'Get one month free',
    RETRY_PREMIUM: 'Retry premium',
    RENEW_PRO: 'Renew PRO',
    BOOST_YOUR_SHOP_BOX_LEGACY: {
      TITLE: "Boost your shop's visibility today!",
      DESCRIPTION_1:
        'Upgrade to Tedooo Pro to access premium features that will enhance your business and',
      DESCRIPTION_2: 'drive more sales!',
      BUTTON_TEXT: 'Try one month free!',
    },
    BOOST_YOUR_SHOP_BOX: {
      TITLE: 'Join Tedooo PRO to get more sales!',
      BENEFIT_1: 'Boosted Tedooo profile and increased exposure',
      BENEFIT_2: 'Exclusive access to new features and programs',
      BENEFIT_3: 'Help crafters sell without fees by going Pro',
      BUTTON_TEXT: 'Try for FREE!',
    },
    PREMIUM_PLACEHOLDER: {
      UNLOCK_PROFILE_VIEWS_TEXT: 'Unlock premium to see your profile views!',
      JOIN_PRO_TO_SEE_MORE_INFO: 'Join Pro to see more of the user’s info',
    },
    UPGRADE_TO_PRO_POPUP: {
      DESCRIPTION:
        'Upgrade to Tedooo Pro to access premium features that will enhance your business and drive more sales!',
      BIG_TAG_TEXT: 'TRY FREE FOR ONE MONTH',
      PREMIUM_PLAN_BOX: {
        YEAR_DESCRIPTION: 'per year\npaid annually',
        MONTH_DESCRIPTION: 'per month\npaid monthly',
        POPULAR: 'POPULAR',
        NEXT_BILLING: 'Next Billing:',
        ACTIVE_UNTIL: 'Active until:',
        TRY_FOE_FREE: 'Try for free',
        RENEW_PRO: 'Renew PRO',
        MANAGE_PLAN: 'Manage plan',
      },
      PREMIUM_BENEFITS: {
        VERIFIED_BADGE: {
          TITLE: 'Verified PRO badge',
          DESCRIPTION:
            'Earn a verified Pro badge on your profile to showcase your professional status on Tedooo.',
        },
        BOOSTED_PROFILE: {
          TITLE: 'Boosted Profile',
          DESCRIPTION: 'Elevate your profile and items for a higher visibility and reach.',
        },
        VISIONS_CREDITS: {
          TITLE: '10 Vision Credits (per month)',
          DESCRIPTION: 'Use Tedooo AI to create innovative visions.',
        },
        SUPPORT_CRAFT_COMMUNITY: {
          TITLE: 'Support the Crafters Community',
          DESCRIPTION: 'Help crafters sell without fees by going Pro.',
        },
        EXCLUSIVE_EXPOSURE_TO_VISIONS_REQUESTS: {
          TITLE: 'Exclusive Exposure to Visions requests',
          DESCRIPTION: 'Only Tedooo Pro members are showcased to Visions buyer’s requests.',
        },
        EARLY_ACCESS_TO_NEW_FEATURES: {
          TITLE: 'Early Access to new features and exclusive programs',
          DESCRIPTION: 'Be the first to get access.',
        },
        PREMIUM_SUPPORT: {
          TITLE: 'Premium Support',
          DESCRIPTION: 'Fast, dedicated help.',
        },
        VIEW_INSIGHTS: {
          TITLE: 'View Insights',
          DESCRIPTION: 'See who viewed your profile.',
        },
        SEARCH_PRIORITY: {
          TITLE: 'Search Priority',
          DESCRIPTION: 'Your shop, items and posts will be on top of the search results.',
        },
      },
      PREMIUM_BENEFITS_LEGACY: {
        BOOSTED_PROFILE: 'Boosted Tedooo profile',
        PRIORITY_ON_SEARCH_RESULTS: 'Priority on search results',
        MORE_DISCOVERY_AND_VIRALITY: 'More discovery and virality for your posts and moments',
        SEE_WHO_VIEWED_YOUR_PROFILE: 'See who viewed your profile',
        BOOST_YOUR_WEBSITES_AND_SHOPS: 'Ability to list and boost your websites and shop links',
      },
    },
    SUBSCRIBE_SUCCESS_POPUP: {
      TITLE: 'You’ve upgraded to Tedooo PRO!',
      DESCRIPTION: 'Your profile is now boosted, and all features are available to you!',
      BUTTON_TEXT: 'Got it, thanks!',
    },
    TAG_TEXT: {
      CURRENT_PLAN: 'Current plan',
      SAVE_50_PERCENT: 'SAVE 50%',
    },
  },
  DISCOVER: {
    PREMIUM_BANNER: {
      TITLE: 'Would you like to showcase your items on our Discover page?',
      DESCRIPTION:
        'Upgrade to Pro and join the community of sellers who have seen a boost in sales!',
      BUTTON_TEXT: 'Try for free!',
    },
    SEARCH: {
      TITLE: 'Explore Tedooo’s unique items',
      DESCRIPTION: 'from artists and crafters small businesses!',
    },
    CATEGORIES: { MAIN_TITLE: 'Explore our categories', MOBILE_TITLE: 'Explore categories' },
  },
  REDIRECT_PAGE: {
    PAYMENT_SUCCESSFUL: 'Payment successful! You can now return to the app.',
    PAYMENT_FAILED: 'Payment failed, please try again.',
    SUCCESSFUL_CONNECT: 'You’ve connected successfully! You can now return to the app.',
    FAILED_TO_CONNECT: 'The connection failed, please try again.',
  },
  VISIONS: {
    ERROR_MESSAGE:
      'Due to high demand, we are currently experiencing delays in processing requests. Please try again later. Thank you for your patience and understanding.',
    TITLE: 'Generate your Vision',
    DESCRIPTION: 'Bring your crafty vision live',
    SMART_TAGS_TITLE: 'Suggested smart tags',
    GENERATE_TOOLTIP: 'To try this Vision, click on generate!',
    PROMPT_PLACEHOLDER:
      'A tumbler with an illustration of a Corgi dog with a mix of realism and fantasy, encased in a starry night...',
    MY_REQUESTS: {
      TITLE: 'My requests',
      DESCRIPTION: 'Visions I requested to buy',
    },
    BUYER_REQUESTS: {
      TITLE: 'Offers',
      DESCRIPTION: 'Buyers requests and offers',
    },
    AD: {
      TITLE: 'Generate your Vision from an image',
      DESCRIPTION: 'Upload an image and create a Vision from that image.',
      BUTTON_TEXT: 'Generate from an image',
    },
    FEED_AD: {
      TITLE: 'Bring your Vision to life!',
      DESCRIPTION:
        'Find a seller that will create you anything Generate an image of what you wish to create ',
      BUTTON_TEXT: 'Try it out!',
    },
    ADD_IMAGES: 'Add images',
    GUIDE_POPUP: {
      STEP1: {
        TITLE: 'INTRODUCING\nVisions',
        DESCRIPTION:
          'Visions are generated AI images, that sellers can bring to life or use as inspiration.',
      },
      STEP2: {
        TITLE: 'Generate your Vision',
        DESCRIPTION:
          "To achieve the best results, make sure to include all relevant details in your description. If your vision is based on another image, you can upload that image and specify any changes, additions, or elements you'd like generated from it.",
      },
      STEP3: {
        TITLE: 'Your Vision Results',
        DESCRIPTION:
          'You will receive four versions of your vision. You can save them, set them to public or private, or regenerate it.',
      },
      STEP4: {
        TITLE: 'Request to purchase your real-life vision',
        DESCRIPTION:
          'After selecting a version, you can save, share, or edit it. If you\'d like to purchase the item, click the "Purchase" button, and sellers will have the opportunity to offer to create the item for you.',
      },
    },
  },
  GENERATE_VISION_PAGE: {
    TITLE: 'Your Vision creation',
    DESCRIPTION:
      "Now that you've got your visions, you can choose your favorite one and request to buy it! If you don't like it, you can regenerate it and get different results. Your visions are automatically saved into your visions list.",
    CHOOSE_IMAGE_TOOLTIP: 'Choose one of the versions  you like the most',
    MAKE_VISIBLE_TITLE: 'Make this vision publicly visible',
    MAKE_VISIBLE_DESCRIPTION:
      'This vision may be displayed on the discover page and in the inspiration list',
    GET_MORE_VISIONS: 'Get more!',
    REGENERATE: 'Regenerate',
    SAVE_IMAGES: 'Save images',
    GO_TO_ALL_VISIONS: 'Go to all visions list',
    TRY_IT_YOURSELF: 'Try it yourself!',
    REQUEST_TO_BUY: 'Request to buy',
    LOADER_TITLE: 'Tip',
    LOADER_SUB_TITLE: 'Be specific about the details',
    LOADER_DESCRIPTION:
      'Try: A tumbler of a Corgi dog illustration with a mix of realism and fantasy, encased in a circular frame with cosmic motifs, suggestive of a starry night sky or galaxy.',
    BUY_CREDITS_POPUP: {
      DROPDOWN_TEXT: 'Get Visions credits',
      TITLE: 'Get more credits!',
      BENEFIT_1: 'Each credit generates 4 unique Visions from your prompt.',
      BENEFIT_2:
        'Get the rights to use and materialize any vision you generate (Note: not including copyrighted materials).',
      BENEFIT_3:
        "Create visions and gain the ability to purchase them from crafter's who will bring them into life.",
      CHOOSE: 'Choose',
    },
    BUY_CREDITS_SUCCESS_POPUP: {
      TITLE: 'Congrats! You have now',
      DESCRIPTION: 'You’ve successfully purchased',
    },
    REGENERATE_POPUP: {
      TITLE: 'Regenerate Vision?',
      DESCRIPTION: 'Regenerating the vision will utilize one credit from your remaining balance.',
      BUTTON_TEXT: 'Regenerate Vision',
    },
  },
  SPECIFIC_VISION_PAGE: {
    SAVE_IMAGE: 'Save image',
    POST_ON_FEED: 'Post on feed',
    EDIT_IMAGE: 'Edit image',
    PURCHASE_TITLE: 'Purchase your real-life vision',
    PURCHASE_DESCRIPTION:
      'At Tedooo, our talented sellers can bring your creative vision to life. Just make a request, and wait for offers to roll in!',
    REQUEST_TO_BUY: 'Request to buy',
    CREATED_BY: 'Created by',
    USER_DETAILS_DESCRIPTION: 'Like this Vision? Create a similar one by yourself in seconds!',
    CREATE_POST_REQUEST_TO_BUY:
      'Hey Tedooo Sellers! I’m looking to buy...\n' +
      '*Please comment only if you can create this item ',
    EDIT_IMAGE_TOOLTIP:
      'Would you like to make changes to this image? Generate the image and describe any modifications you desire',
    REQUEST_TO_BUY_POPUP: {
      TITLE: 'Request to buy',
      ITEM_DETAILS_TITLE: 'Item details',
      ITEM_DETAILS_DESCRIPTION: 'Provide the details of your vision to find your matching seller.',
      SELECT_CATEGORY: 'Select category',
      TIP: 'Please note that the generated item will not necessarily look exactly like the item that will be created by the seller.',
      SHIP_TO: 'Ship to',
      ADD_SHIPPING_ADDRESS: 'Add a shipping address',
      NOTE_TOP_LABEL: 'Details',
      NOTE_PLACEHOLDER:
        'Write any possible detail to your request to provide the seller all the information that they need.\n' +
        'E.g. the item, the colors, sizes etc...',
      FIELD_REQUIRED_ERROR: 'This field is required',
    },
  },
  MY_VISIONS_REQUESTS_PAGE: {
    TITLE: 'My requests',
    TIP: {
      TITLE: 'What are Visions requests?',
      DESCRIPTION:
        'After sending a request, you need to wait for the sellers to send you an offer on your vision request. After receiving, approve the one that seems like the best option for you! We will update you as soon as get offers!',
    },
    VISION_REQUEST: {
      ITEM_CATEGORY: 'Item category:',
      NO_OFFERS: 'No offers on this request yet',
      PROMPT: 'Prompt:',
      NO_OFFERS_BUTTON: 'No offers yet',
    },
  },
  SELLER_OFFERS_PAGE: {
    TITLE: 'Offers',
    DESCRIPTION:
      'Offered proposals are the offers that you submit on a Vision request that are pending for the buyer to approve. Once it’s accepted it will become an order.',
    POTENTIAL_ORDERS: 'Potential orders',
    PENDING_OFFERS: 'Pending offers',
    VISION_REQUEST: {
      SHIP_TO: 'Ship to:',
      NOTES: 'Notes:',
      DATE: 'Date:',
      CREATED_BY: 'Created by',
      SEND_AN_OFFER: 'Send an offer',
      VIEW_ORDER: 'View order',
      EDIT_OFFER: 'Edit offer',
      RENEW: 'Renew',
      ITEM_CATEGORY: 'Item category:',
      OFFER_PENDING_TAG: 'Offer sent and pending for approval',
      OFFER_APPROVED_TAG: 'Offer was approved',
      OFFER_EXPIRED_TAG: 'Offer is expired',
    },
    SEND_OFFER_POPUP: {
      TITLE: 'Send offer on Vision',
      EDIT_TITLE: 'Edit offer',
      VISION_DETAILS: 'Vision details',
      DOWNLOAD_OR_SEND: 'Download',
      SEND_OFFER_FOR_VISION: 'Send offer on vision',
      SAVE_AND_SEND: 'Save & send',
      SHIP_TO: 'Ship to:',
      ITEM: 'Item:',
      NOTES: 'Notes:',
      CREATED_BY: 'Created by:',
      DATE: 'Date:',
      TIP: '*Keep in mind that buyers have a specific idea of how they want the item to look. As a seller, try to closely match their expectations to avoid any buyer dissatisfaction.',
      ITEM_PRICE: 'Item price',
      SHIPPING_PRICE_ERROR: 'No services available for your package dimensions and weight.',
      PRICE_PLACEHOLDER: 'Price (without shipping)',
      ADDITIONAL_COMMENTS: 'Additional comments',
      ADDITIONAL_COMMENTS_TOP_LABEL: 'Additional comments',
      ADDITIONAL_COMMENTS_PLACEHOLDER:
        'Please provide any important details the buyer should be aware of, such as any limitations in creating the visions exactly as described, or any personal touches you plan to add...\n',
    },
    WITHDRAW_OFFER_POPUP: {
      TITLE: 'Withdraw request?',
      DESCRIPTION: 'The offer will be permanently deleted and no longer available',
      BUTTON_TEXT: 'Withdraw',
    },
    HIDE_POTENTIAL_OFFER_POPUP: {
      TITLE: 'Hide this Vision from the list?',
      DESCRIPTION:
        'This vision will be hidden only from your request list, this action can’t be undone.',
      BUTTON_TEXT: 'Hide and Remove',
    },
    PENDING_OFFERS_EMPTY_STATE: {
      TITLE: "You don't have any pending offers",
      DESCRIPTION: "You don't have any pending offers.",
    },
    POTENTIAL_ORDERS_EMPTY_STATE: {
      TITLE: 'No potential orders yet',
      DESCRIPTION: 'There aren’t any matching requests',
    },
  },
  SPECIFIC_VISION_REQUEST_PAGE: {
    TITLE: 'Vision offers',
    DESCRIPTION:
      "Review the offers that sellers have submitted for your Vision and select the one that best matches your preferences. Consider evaluating the offer based on the variety of items in the seller's shop to gauge their technique.",
    SORT: 'Sort',
    PROMPT: 'Prompt: ',
    BUYER_VISION_OFFER: {
      VIEW_OFFER: 'View offer',
      DECLINE_OFFER: 'Decline offer',
      DELIVERY_TIME: 'Delivery time:',
      RETURNS_AND_EXCHANGE: 'Returns & exchange:',
      NOTE: 'Note:',
      OFFER_BY: 'Offer by',
      BUSINESS_DAYS: 'business days',
      SHIPPING: 'shipping',
      BEST_MATCH: 'BEST MATCH',
    },
    DELETE_REQUEST_POPUP: {
      TITLE: 'Delete Vision and offers?',
      DESCRIPTION: 'This vision will be deleted, along with all related offers.',
      BUTTON_TEXT: 'Delete Vision',
    },
    PAY_NOW_POPUP: {
      TITLE: 'Offer from',
      ITEM: 'Item:',
      SELLER_NOTES: 'Seller’s note:',
      SHIP_TO: 'Ship to',
    },
    NEW_OFFERS_POPUP: {
      TITLE: 'You received',
      TITLE_2: 'offers!',
      DESCRIPTION_2:
        'offers for your Vision request. Accept one to enable the seller to start working on it.',
      BUTTON_TEXT: 'See all offers',
    },
    OFFER_APPROVED_POPUP: {
      TITLE: 'Congrats, your offer was accepted!',
      DESCRIPTION:
        'The offer you sent has been accepted. Start working on this vision! You can find it on the orders page.',
      BUTTON_TEXT: 'Thank you!',
    },
  },
  BASKET: {
    TITLE: 'Basket',
    REMOVE_FROM_BASKET_MESSAGE: 'Removed from Basket',
    BASKET_POPOVER_BUTTON: 'View basket & checkout',
    EMPTY_STATE: {
      TITLE: 'Your basket is empty',
      DESCRIPTION: 'The items you’ll add will appear here',
      BUTTON_TEXT: 'Start shopping!',
    },
    SHOP_BASKET: {
      SHIP_TO: 'Ship to',
      CHECKOUT_FROM_THIS_SHOP: 'Checkout from this shop',
      EDIT_ADDRESS: 'Edit',
    },
    BASKET_ITEM: {
      SAVE_FOR_LATER: 'Save for later',
      REMOVE: 'Remove',
      QTY: 'Qty:',
      ADD_A_NOTE: 'Add a note',
      NOTE: 'Note',
      ITEM_UNAVAILABLE_ERROR: 'This item is currently unavailable.',
    },
    REMOVE_SINGLE_ITEM_POPUP: {
      TITLE: 'Save this item for later?',
      DESCRIPTION: 'This item is currently unavailable. Save it for later and proceed to checkout!',
      PRIMARY_BUTTON_TEXT: 'Add to favorites & Checkout',
      SECONDARY_BUTTON_TEXT: 'Checkout and remove item',
    },
    REMOVE_MULTIPLE_ITEMS_POPUP: {
      TITLE: 'Save these items for later?',
      DESCRIPTION:
        'There are items in your basket that are currently unavailable. Save them for later and proceed to checkout!',
      PRIMARY_BUTTON_TEXT: 'Add to favorites & Checkout',
      SECONDARY_BUTTON_TEXT: 'Checkout and remove items',
    },
  },
  GO_TO_CHECKOUT_POP_UP: {
    TITLE: 'Go to checkout',
    CONTINUE_AS_A_GUEST_BUTTON: 'Continue as a guest',
    BY_CONTINUING: "By continuing, you agree to Tedooo's ",
    TERMS_OF_SERVICE: 'Terms Of Service ',
    ACKNOWLEDGE_TEDOOO: "and acknowledge Tedooo's ",
    PRIVACY_POLICY: 'Privacy Policy',
  },
};
