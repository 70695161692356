import { v4 as uuidv4 } from 'uuid';

import {
  ContactInformationTypeResponse,
  ContactOptions,
  CreateShopSteps,
  DiscountTypes,
  IContactInformationOption,
  ICreateShopCatalogItem,
  ICreateShopProduct,
  ICreateShopRequest,
  ICreateShopState,
  IImageContainer,
  IImageContainerTypes,
  ILegacyPayoutMethod,
  IProductDimensions,
  IProductDimensionsResponse,
  PaypalErrorResponse,
  ReturnPolicyType,
} from 'types';
import { CurrencyCode, mapCurrencyToSymbol } from 'constant/currencies';
import {
  getDescriptionError,
  getNameError,
  isArraysEqual,
  isEmptyArray,
  validateEmail,
  validatePhoneNumber,
} from 'utils';
import { LegacyPaymentMethods } from 'constant/paymentMethods';
import { DecodeProductCsvResponseProduct } from '../../graphql/generated/graphql';

const DEFAULT_QUANTITY = 300;

export const imageContainers: IImageContainer[] = [
  { type: IImageContainerTypes.Main, listingImage: null },
  { type: IImageContainerTypes.Side, listingImage: null },
  { type: IImageContainerTypes.Top, listingImage: null },
  { type: IImageContainerTypes.Upside, listingImage: null },
];

export const addContactDetailsToState = (
  state: ICreateShopState,
  contacts: IContactInformationOption[],
) => {
  const emailList = contacts.filter(
    (contact: IContactInformationOption) => contact.type === ContactOptions.Email,
  );
  if (isEmptyArray(emailList)) {
    state.email = '';
  } else {
    state.email = emailList[0].text;
  }

  const socialLinksList = contacts.filter(
    (contact: IContactInformationOption) => contact.type === ContactOptions.Website,
  );
  state.socialLinks = socialLinksList.map((link: IContactInformationOption) => link.text);
  if (isEmptyArray(state.socialLinks)) state.socialLinks.push('');

  const phoneList = contacts.filter(
    (contact: IContactInformationOption) => contact.type === ContactOptions.Number,
  );

  if (isEmptyArray(phoneList)) {
    state.phoneNumber = '';
  } else {
    state.phoneNumber = phoneList[0].text;
  }
};

export const parseProduct = (item: ICreateShopCatalogItem): ICreateShopProduct => {
  const filteredImages = item.images.filter((imageContainer) => imageContainer.listingImage);

  const tariff = item.tariffNumber ? item.tariffNumber : undefined;

  const dimensions = getDimensions(item.dimensions);

  const quantity = item.quantity !== null ? item.quantity : DEFAULT_QUANTITY;

  const digitalFiles = isEmptyArray(item.digitalFiles)
    ? undefined
    : item.digitalFiles.map((file) => file.key);

  const shippingInformation = item.isDigital
    ? undefined
    : {
        profileId: item.shippingProfileId || '',
        returnPolicy: item.returnPolicy || ReturnPolicyType.NoReturn,
        dimensions,
      };

  return {
    id: item.id,
    images: filteredImages.map((image) => image.listingImage || ''),
    title: item.title,
    description: item.description,
    selectedCategory: item.category,
    isDigital: item.isDigital,
    keywords: item.keywords,
    digitalFiles,
    quantity,
    price: {
      price: Math.round(item.priceAfterDiscount * 100),
      currency: mapCurrencyToSymbol[item.currency],
      currencyCode: item.currency,
    },
    originalPrice: Math.round(item.price * 100),
    tariff,
    shippingInformation,
  };
};

export const parseProducts = (catalog: ICreateShopCatalogItem[]): ICreateShopProduct[] => {
  const parsedItems: ICreateShopProduct[] = [];
  catalog.forEach((item) => {
    parsedItems.push(parseProduct(item));
  });

  return parsedItems;
};

export const parseSocialLinks = (type: ContactInformationTypeResponse, items: string[]) => {
  const allLinks = items.filter((item) => item !== '');

  return allLinks.map((text) => ({
    type: Number(type),
    text,
  }));
};

export const parseLegacyPaymentMethods = (legacyPayoutMethods: ILegacyPayoutMethod[]) => {
  return legacyPayoutMethods
    .map((method) => ({
      provider: method.type,
      link: method.link,
    }))
    .filter((method) => method.link !== '');
};

export const parseToCreateShopRequest = (
  shopName: string,
  shopDescription: string,
  location: string,
  catalog: ICreateShopCatalogItem[],
  coverPhoto: string,
  socialLinks: string[],
  phoneNumber: string,
  email: string,
  shopId: string | null,
  deletedProducts: string[],
  addedProductsIds: string[],
  currencyCode: CurrencyCode,
  legacyPayoutMethods: ILegacyPayoutMethod[],
  initialCatalogOrder: string[],
): ICreateShopRequest => {
  const products = parseProducts(catalog);

  const addedProducts = products.filter((product) =>
    addedProductsIds.find((id) => id === product.id),
  );

  const newCatalogOrder = products.map((product) => product.id);

  const newOrder = isArraysEqual(newCatalogOrder, initialCatalogOrder)
    ? undefined
    : newCatalogOrder;

  const editedProducts = {};

  products.forEach((product) => {
    if (!addedProductsIds.find((id) => id === product.id)) {
      editedProducts[product.id] = product;
    }
  });

  const parsedProducts = {
    added: addedProducts,
    deleted: deletedProducts,
    updated: editedProducts,
    newOrder,
  };

  const parsedSocialLinks = parseSocialLinks(ContactInformationTypeResponse.Website, socialLinks);

  const parsedPayoutMethods = parseLegacyPaymentMethods(legacyPayoutMethods);

  return {
    shop: {
      id: shopId,
      name: shopName,
      description: shopDescription,
      foundedCountry: location,
      coverPhoto,
      email,
      phoneNumber,
      paymentProviders: parsedPayoutMethods,
      contactInfo: parsedSocialLinks,
      companyType: 'Crafter',
      currencyCode,
    },
    products: parsedProducts,
  };
};

export const PAYMENT_METHODS_ORDER = [
  LegacyPaymentMethods.Paypal,
  LegacyPaymentMethods.Square,
  LegacyPaymentMethods.CashApp,
  LegacyPaymentMethods.AmazonPay,
  LegacyPaymentMethods.Venmo,
  LegacyPaymentMethods.BankTransfer,
  LegacyPaymentMethods.Wise,
  LegacyPaymentMethods.Sofort,
  LegacyPaymentMethods.Revolut,
  LegacyPaymentMethods.Bpay,
  LegacyPaymentMethods.Poli,
  LegacyPaymentMethods.Interac,
  LegacyPaymentMethods.Sepa,
  LegacyPaymentMethods.FasterPayments,
  LegacyPaymentMethods.Adyen,
  LegacyPaymentMethods.Skrill,
  LegacyPaymentMethods.Netteler,
  LegacyPaymentMethods.Zelle,
  LegacyPaymentMethods.PayDirekt,
  LegacyPaymentMethods.BanContact,
];

export const mapPaypalErrorCodeToAmplitudeMessage = {
  [PaypalErrorResponse.PaymentNotReceivable]: 'cannot receive payments',
  [PaypalErrorResponse.EmailNotVerified]: 'verify email',
};

export const getDimensions = (dimensions: IProductDimensions): IProductDimensionsResponse => {
  const { height, length, weightBig, width, isMetric, weightSmall } = dimensions;

  return {
    width: Math.floor(Number(width.toFixed(2)) * 100),
    height: Math.floor(Number(height.toFixed(2)) * 100),
    length: Math.floor(Number(length.toFixed(2)) * 100),
    weightBig: Math.floor(Number(weightBig.toFixed(2)) * 100),
    weightSmall: Math.floor(Number(weightSmall.toFixed(2)) * 100),
    isMetric,
  };
};

export const getDraftInitialStep = (state: ICreateShopState) => {
  const isShopNameValid = !!state.shopName && !getNameError(state.shopName);

  const isShopDescriptionValid =
    !!state.shopDescription && !getDescriptionError(state.shopDescription);

  const isPhoneNumberValid = !!state.phoneNumber && validatePhoneNumber(state.phoneNumber);

  const isEmailValid = !!state.email && validateEmail(state.email);

  if (!isShopNameValid || !isShopDescriptionValid) {
    return CreateShopSteps.Initial;
  }
  if (!isPhoneNumberValid || !isEmailValid) {
    return CreateShopSteps.ContactDetails;
  }
  if (isEmptyArray(state.catalog.items)) {
    return CreateShopSteps.AddListing;
  }
  if (isEmptyArray(state.payoutMethods) && isEmptyArray(state.legacyPayoutMethods)) {
    return CreateShopSteps.Catalog;
  }
  if (state.isPaypalEnabled || state.isStripeEnabled) {
    return CreateShopSteps.Payouts;
  }

  return CreateShopSteps.LegacyPayments;
};

export const getTotalCompletedDraftSteps = (state: ICreateShopState) => {
  let counter = 0;

  const isShopNameValid = !!state.shopName && !getNameError(state.shopName);

  const isShopDescriptionValid =
    !!state.shopDescription && !getDescriptionError(state.shopDescription);

  const isPhoneNumberValid = !!state.phoneNumber && validatePhoneNumber(state.phoneNumber);

  const isEmailValid = !!state.email && validateEmail(state.email);

  const hasConnectedPaymentMethods =
    !isEmptyArray(state.payoutMethods) || !isEmptyArray(state.legacyPayoutMethods);

  if (isShopNameValid && isShopDescriptionValid) {
    counter++;
  }
  if (state.currencyCode) {
    counter++;
  }
  if (isEmailValid && isPhoneNumberValid) {
    counter++;
  }
  if (!isEmptyArray(state.catalog.items)) {
    counter++;
  }
  if (hasConnectedPaymentMethods) {
    counter++;
  }

  return counter;
};

export const updateDraft = (draft: ICreateShopState): ICreateShopState => {
  const currentStep = getDraftInitialStep(draft);

  const shouldDisplayInFeed = draft.draftDetails ? !!draft.draftDetails?.shouldDisplayInFeed : true;

  return {
    ...draft,
    step: currentStep,
    draftDetails: {
      totalStepsCompleted: getTotalCompletedDraftSteps(draft),
      currentStep,
      shouldDisplayInFeed,
    },
  };
};

export const csvProductToCreateShopProduct = (
  csvProducts: DecodeProductCsvResponseProduct,
): ICreateShopCatalogItem | null => {
  if (
    !csvProducts.title ||
    !csvProducts.description ||
    !csvProducts.price ||
    csvProducts.images.length === 0
  ) {
    return null;
  }

  const price = csvProducts.price.price / 100;

  return {
    id: uuidv4(),
    images: csvProducts.images.map(toImageContainer),
    title: csvProducts.title,
    description: csvProducts.description,
    isDigital: false,
    quantity: csvProducts.quantity || null,
    keywords: csvProducts.tags || [],
    digitalFiles: [], // or set a default array
    category: [],
    price,
    priceAfterDiscount: price,
    saleAmount: 0,
    discountType: DiscountTypes.Fixed,
    currency: CurrencyCode[csvProducts.price.currencyCode],
    shippingProfileId: null,
    dimensions: {
      length: 0,
      width: 0,
      height: 0,
      weightBig: 0,
      weightSmall: 0,
      isMetric: false,
    },
    shouldDisplayWarning: false,
    returnPolicy: ReturnPolicyType.ReturnAndExchange30,
    tariffNumber: '',
  };
};

export const toImageContainer = (image: string) => ({
  type: IImageContainerTypes.Extra,
  listingImage: image,
});

export const filterItemsByUploadedImages = (
  productFromCsv: ICreateShopCatalogItem[],
  successImages: string[],
): ICreateShopCatalogItem[] => {
  const items = productFromCsv.map((item) => {
    const filteredImages = item.images.filter(
      (image) => image.listingImage && successImages.includes(image.listingImage),
    );

    return {
      ...item,
      images: filteredImages,
    };
  });

  return items.filter((item) => item.images.length > 0);
};

export const listContainsItemWithSameTitleAndDescription = (
  list: ICreateShopCatalogItem[],
  item: ICreateShopCatalogItem,
) => {
  return list.some((i) => i.title === item.title && i.description === item.description);
};

export const getCreateShopCatalogItemFromCsv = (
  products: DecodeProductCsvResponseProduct[],
  curItems: ICreateShopCatalogItem[],
): ICreateShopCatalogItem[] => {
  const updatedProducts = products
    .map(csvProductToCreateShopProduct)
    .filter((item) => !!item && !listContainsItemWithSameTitleAndDescription(curItems, item));

  return updatedProducts as ICreateShopCatalogItem[];
};
